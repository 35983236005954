

export const menuItems = [
    {
      title: "호텔소개",
      link : "/overview",
      submenu: [
        {
            title: "호텔개요",
            link : "/overview",
            big_title : "호텔 소개",
            text : "‘이호MH호텔 (IHO MH HOTEL)’ 낮과 밤이 아름다운 환상의 섬 제주, 그 중에서도 사진 찍기 좋은 곳으로 잘 알려진 이호테우해변의 새로운 랜드마크가 세워졌습니다.",
            top_link : "overview",
            image : "/img/호텔소개.jpg"
        },

        {
          title: "이용안내",
          link : "/guide",
          big_title : "호텔 소개",
          text : "‘이호MH호텔 (IHO MH HOTEL)’ 낮과 밤이 아름다운 환상의 섬 제주, 그 중에서도 사진 찍기 좋은 곳으로 잘 알려진 이호테우해변의 새로운 랜드마크가 세워졌습니다.",
          top_link : "guide",
          image : "/img/호텔소개.jpg"
        },
        /*{
            title: "오시는 길",
            link : "/location",
            big_title : "호텔 소개",
            text : "‘이호MH호텔 (IHO MH HOTEL)’ 낮과 밤이 아름다운 환상의 섬 제주, 그 중에서도 사진 찍기 좋은 곳으로 잘 알려진 이호테우해변의 새로운 랜드마크가 세워졌습니다.",
            top_link : "overview",
            image : "/img/호텔소개.jpg"
        },*/
      ],
    },
    {
      title: "객실소개",
      link : "/rooms/premium",
      submenu: [
        {
          title: "프리미엄 트리플",
          link : "/rooms/triple",
          big_title : "객실 소개",
          text : "25평형과 45평형으로 이루어진 이호MH호텔의 객실은 아름다운 이호테우해변과 멋진 한라산의 모습을 즐길 수 있는 전경과 풀 리모델링을 통한 객실의 고급화로 ‘누구나 원하는 최상의 휴식’을 느낄 수 있습니다.",
          top_link : "/rooms/triple",
          image : "/img/객실.jpg"
        
        },
        {
          title: "프리미엄 더블",
          link : "/rooms/premium",
          big_title : "객실 소개",
          text : "25평형과 45평형으로 이루어진 이호MH호텔의 객실은 아름다운 이호테우해변과 멋진 한라산의 모습을 즐길 수 있는 전경과 풀 리모델링을 통한 객실의 고급화로 ‘누구나 원하는 최상의 휴식’을 느낄 수 있습니다.",
          top_link : "/rooms/premium",
          image : "/img/객실.jpg"
        
        },
        {
          title: "패밀리",
          link : "/rooms/family",
          big_title : "객실 소개",
          text : "25평형과 45평형으로 이루어진 이호MH호텔의 객실은 아름다운 이호테우해변과 멋진 한라산의 모습을 즐길 수 있는 전경과 풀 리모델링을 통한 객실의 고급화로 ‘누구나 원하는 최상의 휴식’을 느낄 수 있습니다.",
          top_link : "/rooms/family",
          image : "/img/객실.jpg"
       
        },
      ],
    },
    /*{
      title: "객실예약",
      link : "/reservation",
      submenu: [
        {
          title: "객실예약",
          link : "/reservation",
        
        },
        {
          title: "예약조회",
          link : "/login",
       
        },
      ],
    },*/
    {
        title: "주변관광지",
        link : "/attractions/beach",
        submenu: [
          {
            title: "이호테우해변",
            link : "/attractions/beach",
            big_title : "주변 관광지",
            text : "다양한 볼거리와 먹거리로 눈과 입이 즐거운 제주도 관광. 제주도 관광공사가 자랑하는 이호테우해변과 그 주변 관광지를 즐겨보세요.",
            top_link : "/attractions/beach",
            image : "/img/주변관광지.jpg"

          },
          {
            title: "도두봉",
            link : "/attractions/dodubong",
            big_title : "주변 관광지",
            text : "다양한 볼거리와 먹거리로 눈과 입이 즐거운 제주도 관광. 제주도 관광공사가 자랑하는 이호테우해변과 그 주변 관광지를 즐겨보세요.",
            top_link : "/attractions/beach",

            image : "/img/주변관광지.jpg"
            

          },
          {
            title: "용두암",
            link : "/attractions/rock",
            big_title : "주변 관광지",
            text : "다양한 볼거리와 먹거리로 눈과 입이 즐거운 제주도 관광. 제주도 관광공사가 자랑하는 이호테우해변과 그 주변 관광지를 즐겨보세요.",
            top_link : "/attractions/beach",

            image : "/img/주변관광지.jpg"

          },
          {
            title: "용연계곡",
            link : "/attractions/valley",
            big_title : "주변 관광지",
            text : "다양한 볼거리와 먹거리로 눈과 입이 즐거운 제주도 관광. 제주도 관광공사가 자랑하는 이호테우해변과 그 주변 관광지를 즐겨보세요.",
            top_link : "/attractions/beach",

            image : "/img/주변관광지.jpg"

          },
        ],
      },
      {
        title: "부대시설",
        link : "/facilities/banquet",
        submenu: [
          {
            title: "MH라운지 (8월 오픈 예정)",
            //link : "/facilities/barbecue",
            big_title : "부대시설",
            text : "8월 오픈 예정",
            //top_link : "/facilities/barbecue",
            //image : "/img/바베큐.jpg"


          },
          {
            title: "연회장",
            link : "/facilities/banquet",
            big_title : "부대시설",
            text : "가족 모임, 소규모 모임 등 성공적인 행사를 위한 소규모 연회장을 둘러보세요.",
            top_link : "/facilities/barbecue",

            image : "/img/banquet3.jpg"



          },
        ],
      },
      {
        title: "공지사항",
        link : "/board",
        submenu: [
          {
            title: "notice",
          }
        ],
      }
  ];