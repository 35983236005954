

export const menuItems3 = [
    {
      title: "ホテルの紹介",
      link : "/overview",
      submenu: [
        {
            title: "ホテルの概要",
            link : "/overview",
            big_title : "ホテルの紹介",
            text : "‘IHO MH HOTEL’ 昼も夜も美しい幻想の島, 濟州の海辺その中でも写真を撮るのに良い場所としてよく知られている梨湖テウビーチに新たなランドマークが建てられました。",
            top_link : "overview",
            image : "/img/호텔소개.jpg"
        },
        /*{
            title: "交通案内",
            link : "/location",
            big_title : "ホテルの紹介",
            text : "‘IHO MH HOTEL’ 昼も夜も美しい幻想の島, 濟州の海辺その中でも写真を撮るのに良い場所としてよく知られている梨湖テウビーチに新たなランドマークが建てられました。",
            top_link : "overview",
            image : "/img/호텔소개.jpg"
        },*/
      ],
    },
    {
      title: "客室紹介",
      link : "/rooms/premium",
      submenu: [
        {
          title: "プレミアムルーム",
          link : "/rooms/premium",
          big_title : "客室紹介",
          text : "25坪型と45坪型で構成されたイホMHホテルの客室は、美しいイホテウビーチと漢拏山の姿を楽しむことができる全景とフルリニューアルにより、客室の高級化で「誰もが望む最高の休息」を感じることができます。",
          top_link : "/rooms/premium",
          image : "/img/객실.jpg"
        
        },
        {
          title: "ファミリールーム",
          link : "/rooms/family",
          big_title : "客室紹介",
          text : "25坪型と45坪型で構成されたイホMHホテルの客室は、美しいイホテウビーチと漢拏山の姿を楽しむことができる全景とフルリニューアルにより、客室の高級化で「誰もが望む最高の休息」を感じることができます。",
          top_link : "/rooms/premium",
          image : "/img/객실.jpg"
       
        },
      ],
    },
    /*{
      title: "객실예약",
      link : "/reservation",
      submenu: [
        {
          title: "객실예약",
          link : "/reservation",
        
        },
        {
          title: "예약조회",
          link : "/login",
       
        },
      ],
    },*/
    {
        title: "周辺の観光地",
        link : "/attractions/beach",
        submenu: [
          {
            title: "梨湖テウ海水浴場",
            link : "/attractions/beach",
            big_title : "周辺の観光地",
            text : "多彩な見どころとグルメで目と口が楽しい濟州島観光。 濟州が誇るイホテウビーチとその周辺の観光地をお楽しみください。",
            top_link : "/attractions/beach",
            image : "/img/주변관광지.jpg"

          },
          {
            title: "道頭峰",
            link : "/attractions/dodubong",
            big_title : "周辺の観光地",
            text : "多彩な見どころとグルメで目と口が楽しい濟州島観光。 濟州が誇るイホテウビーチとその周辺の観光地をお楽しみください。",
            top_link : "/attractions/beach",

            image : "/img/주변관광지.jpg"
            

          },
          {
            title: "龍頭岩",
            link : "/attractions/rock",
            big_title : "周辺の観光地",
            text : "多彩な見どころとグルメで目と口が楽しい濟州島観光。 濟州が誇るイホテウビーチとその周辺の観光地をお楽しみください。",
            top_link : "/attractions/beach",

            image : "/img/주변관광지.jpg"

          },
          {
            title: "龍淵",
            link : "/attractions/valley",
            big_title : "周辺の観光地",
            text : "多彩な見どころとグルメで目と口が楽しい濟州島観光。 濟州が誇るイホテウビーチとその周辺の観光地をお楽しみください。",
            top_link : "/attractions/beach",

            image : "/img/주변관광지.jpg"

          },
        ],
      },
      {
        title: "付帯施設",
        link : "/facilities/banquet",
        submenu: [
        {
            title: "MH Lounge (8月オープン予定)",
            //link : "/facilities/barbecue",
            big_title : "付帯施設",
            text : "8月オープン予定",
            //top_link : "/facilities/barbecue",
            //image : "/img/바베큐.jpg"


          },
          {
            title: "宴会場",
            link : "/facilities/banquet",
            big_title : "付帯施設",
            text : "家族の集まり、小規模な集まりなど、成功的なイベントのための小規模な宴会場と屋外バーベキューが楽しめる付帯施設を見回してください",
            top_link : "/facilities/barbecue",

            image : "/img/banquet3.jpg"



          },
        ],
      },
      {
        title: "公知事項",
        link : "/board",
        submenu: [
          {
            title: "notice",
          }
        ],
      }
  ];