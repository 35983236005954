import { HashLink as Link } from 'react-router-hash-link';
import React, { useState, useEffect, useRef } from 'react';
import dompurify from "dompurify";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Cookies from 'universal-cookie';

import Dropdown from "./Dropdown";

const cookies = new Cookies();
const MenuItems = ({ items, depthLevel }) => {
  const [korean, setKor] = useState("false");
	const { t, i18n } = useTranslation();
	const sanitizer = dompurify.sanitize;
	const language = useRef(null);
	
	useEffect(()=>{
		language.current = sessionStorage.getItem('language');
	if (language===null){
		language.current = cookies.get('language');
	}
	i18next.changeLanguage(language.current);
	if (language.current ==="kr-KR"){
		setKor(true);
	  }else {
		setKor(false);
	  }
	},[]);
  const [dropdown, setDropdown] = useState(false);
  function refreshPage(link) {
    window.location.href(link);
    alert("hh");
  }
  let ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
    
  }, [dropdown]);
  const onMouseEnter = () => {
    window.innerWidth > 590 && setDropdown(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 590 && setDropdown(false);
  };
  
 
  return (
    <a href = {items.link} className="link">
      <li
      className="mainmenu menu-items"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      
    >
      
      {items.submenu ? (
        <>
          <button
            type="button"
            aria-haspopup="menu"
            aria-expanded={dropdown ? "true" : "false"}
            onClick={() => setDropdown((prev) => !prev)}
          >
            
            <span>{items.title}{" "}</span>
          
          </button>
          <Dropdown
            depthLevel={depthLevel}
            submenus={items.submenu}
            dropdown={dropdown}
          >
          </Dropdown>
          
        </>
      ) : (
        <div style={{width:"100%", height:"100%"}}>
        <div className="leftDropdown">
          <a className="left_submenu" href={items.link}><span>{items.title}</span></a> 
      </div>

        {/*<a className="left_submenu" href={items.link}><span>{items.title}</span></a> */}

        <div className="rightDropdown"><img src={items.image} alt="menu image"></img> 
          <div className="rightDropText">
            <div>
            {items.big_title}
            </div>
            <div>
            {items.text}
            </div>
            <a className="navBtn" href={items.top_link}><button style={{width:"120px",fontSize:"12px"}}>{i18next.t('more')}</button></a>
          </div>
        
        </div>
        
      </div>
         /*<div><a href={items.link}><span>{items.title}</span></a></div>*/
      )}
      
    </li>
    
    </a>
    
  );
};

export default MenuItems;