import './css/home.css'
import './css/input.css'



import React, { useEffect, useState } from 'react';

import { Link, useLocation, useNavigate } from "react-router-dom"
//import Modal from './Modal';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const Reservation = () => {

  /* 동의하지 않음 / 동의함 버튼 색상이랑 텍스트 바꾸는 function
  const handleColor=(e)=>{
    if (e.target.style.backgroundColor==="rgb(255, 255, 255)"){
      

      e.target.style.backgroundColor="rgb(0, 0, 0)";
      e.target.style.color="#fff";
      e.target.innerHTML="동의함";

    }else{
      e.target.style.backgroundColor="#fff";
      e.target.style.color="#000";
      e.target.innerHTML="동의하지 않음";
    }
  }; */

  const [modalOpen, setModalOpen] = useState(false);
  const openModal = () => {
    setModalOpen(true);
  };
  const closeModal = () => {
    setModalOpen(false);
  };
  
  const ConditionalLink = ({ children, to, condition})=>( !!condition && to) ? <Link to={to}>{children}</Link> : <>{children}</>

  useEffect(()=> {
    const jquery = document.createElement("script");
    jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
    const iamport = document.createElement("script");
    iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.1.7.js";
    document.head.appendChild(jquery);
    document.head.appendChild(iamport);

    return ()=>{
      document.head.removeChild(jquery); document.head.removeChild(iamport);
    }
  }, []);

    const location = useLocation();
    const data = location.state;
    

    const room_id = data.room_id;
    const room_name = data.room_name;
    const numAdults = data.numAdult;
    const numKids = data.numKids;
    const sDate = data.sDate;
    const eDate = data.eDate;
    const price_desc = data.price_desc;
    const price = data.price;
    const addedPerson = data.addedPerson;
    const addPersons = data.addPersons;
    const barbecue = data.barbecue;
    const num_days = data.num_days;
    const room_type_id = data.room_type_id;

    const totalPrice = price + addedPerson + barbecue;
    const navigate = useNavigate();
    let added,barbecueadd  = false;

    if (addedPerson !=0){
      added = true;

    }

    if (barbecue !=0){
      barbecueadd = true;

    }

    const [buyerName, setBuyerName] = useState('');
    function formatDate(date) {
      var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
  
      return [year, month, day].join('');
  }
    const booking_id = formatDate(new Date()) + room_id.toString() + Math.floor(Math.random() * 100).toString();
    const buyerNameChange = event => {
      setBuyerName(event.target.value);
      
    };

    const [buyerTel, setBuyerTel] = useState('');

    const buyerTelChange = event => {
      setBuyerTel(event.target.value);
    };

    const [buyerMail, setBuyerMail] = useState('');


    const buyerMailChange = event => {
      setBuyerMail(event.target.value);
    };
    const onClickPayment =()=> {
      

      /*if (buyerName.length === 0 || buyerTel.length === 0 || buyerMail.length === 0) {
        alert("고객정보 입력 plz");
        throw new Error("Here we stop");
      }*/
      /* 동의하지 않음이 하나라도 있으면 */
      function email_check(email) {

        var reg = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      
        return reg.test(email);
      
      }

      function phone_check(phone) {  
        var regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
        return regPhone.test(phone);
      }

      if(buyerMail===""){
        alert("이메일을 입력해주세요.");
        return false;
      }else{
        if(!email_check(buyerMail)){
          alert("올바른 이메일 형식이 아닙니다.");
          return false;
        }
      }

      if(buyerTel===""){
        alert("휴대폰번호를 입력해주세요.");
        return false;
      }else{
        if(!phone_check(buyerTel)){
          alert("올바른 휴대폰번호 형식이 아닙니다.");
          return false;
        }
      }

      

      const agreeCbs = document.getElementsByClassName("agreeCb");
      Array.prototype.forEach.call(agreeCbs, function(el) {
        // Do stuff here
        if (el.checked === false){
          alert("동의하지 않으셨습니다");
          throw new Error("Here we stop");
        }
      });

      
      
       

      /* 1. 가맹점 식별하기 */
      const { IMP } = window;
      IMP.init('imp80618218');
      
      const data = {
        pg: 'html5_inicis',                           // PG사
        pay_method: 'card',                           // 결제수단
        merchant_uid: booking_id,   // 주문번호
        amount: 100,                                 // 결제금액
        name: "이호MH "+ room_name + "룸",                  // 주문명
        buyer_name: buyerName,                           // 구매자 이름
        buyer_tel: buyerTel,                     // 구매자 전화번호
        buyer_email: buyerMail               // 구매자 이메일
       
      };
  
      // 4. 결제 창 호출하기
      IMP.request_pay(data, Callback);
    }
    
    // 3. 콜백 함수 정의하기 
    const Callback = (response)=> {
      
     const {success, error_msg, imp_uid, merchant_uid, pay_method, paid_amount, status}=response;
      if (success) {
        const guest ={
          guest_name : buyerName,
          guest_phone : buyerTel,
          guest_email : buyerMail
        };
       
        fetch("http://localhost:5000/guest", {
          method : "post", // 통신방법
          headers : {
            "content-type" : "application/json",
            'Accept': 'application/json'
          },
          body : JSON.stringify(guest),
        })
        .then((res)=>res.json())
        .then((json)=>{
          console.log(json);
          
          console.log(json[0].RESULT);

          let guestID = json[0].RESULT;
          
          const booking ={
            booking_id : booking_id,
            guest_id : json[0].RESULT,
            room_id : room_id,
            date_start : sDate,
            date_end : eDate,
            num_persons : numAdults + numKids,
            total_price: totalPrice,
            num_days : num_days,
            addPersons : addPersons,
            barbecue : barbecue,
            guest_email : buyerMail
  
  
  
          };
         
          fetch("http://localhost:5000/booking", {
            method : "post", // 통신방법
            headers : {
              "content-type" : "application/json",
              'Accept': 'application/json'
            },
            body : JSON.stringify(booking),
          })
          .then((res)=>res.json())
          .then((json)=>{
            console.log(json[0].RESULT);
           




            const payments = {
              booking_id : booking_id,
              imp_uid : response.imp_uid,
              merchant_uid : response.merchant_uid,
              total_paid : response.paid_amount,
              status : response.status,
              total_price : 100

            }

            fetch("http://localhost:5000/payment", {
            method : "post", // 통신방법
            headers : {
              "content-type" : "application/json",
              'Accept': 'application/json'
            },
            body : JSON.stringify(payments),
            })
            .then((res)=>res.json())
            .then((json)=>{


              
              
            });  
            
            navigate("/confirm", { state: {booking_id: booking_id, guest_id : guestID, room_type_id : room_type_id} });



            
          });  
          
        }); 

        

        
        
      } else {
        alert(`결제 실패: ${error_msg}`);
      }
    };

  
  
  

  return (
    
    <div className="wrap" style={{flex:'1'}}>
    
    <style>{`
        .red {color: red}
        .green {color: green}
      `}</style>
      
    <ul className="stages">
    <Link to="/reservation"><li className="complete"><span>1. 객실 선택</span></li></Link>
    <Link to="/reservation/choice"><li className="complete"><span>2. 추가 옵션 선택</span></li></Link>
    <li className="check"><span>3. 정보 입력/예약</span></li>
    </ul>

  

    {/*<Modal open = {modalOpen} close={closeModal} header="">
      <div style={{backgroundColor:'#eee', width:'100%',margin:'50px auto', height:"300px"}}>
      </div>
  </Modal>*/}


      <div className="roomArea" style={{ height:'100%', border:'2px solid #ddd', margin:'0 auto',
    marginTop:'100px'}}>
       
      <div style={{height:'auto', display:'flex',marginTop:'50px', flexDirection:'column'  }}>

      <div style={{ border:"2px solid #000", width:'80%', marginLeft:'10%', height:'auto', padding:'20px', 
            marginTop:'0px',display:'block',position:'relative', fontSize:'16px' }}>
              <div className="reserveInfo">
                <div className="col1 col">
                  객실1 <br></br>
                  성인{numAdults}명 <br></br>
                  {sDate} ~ {eDate}
                </div>
                <div className="col2 col">

                {room_name}
                <br></br>
                {price_desc}
                  
                </div>
                <div className="col3 col price">
                ₩{price.toLocaleString()}
                </div>
              </div>

              { added && (
              <div className="reserveInfo" style={{borderTop:'1px solid #000'}}>
                <div className="col1 col">
                  인원 추가
                </div>
                <div className="col2 col">
                  (x{addedPerson/15000}명)
                  
                </div>
                <div className="col2 col price">
                ₩{addedPerson}
                </div>
              </div>

              )}

            { barbecueadd && (
              <div className="reserveInfo" style={{borderTop:'1px solid #000'}}>
                <div className="col1 col">
                  바베큐
                </div>
                <div className="col2 col">
                 
                  
                </div>
                <div className="col2 col price">
                ₩{barbecue}
                </div>
              </div>

              )}

              <div className="reserveInfo" style={{borderTop:'1px solid #000'}}>
                <div className="col3 col">
                  합계
                </div>
                <div className="col2 col">
                  
                </div>
                <div className="col3 col price">
                ₩{(addedPerson+price+barbecue).toLocaleString()}
                </div>
              </div>

        
       

            
        </div>




        <div style={{width:"80%", height:"80px", backgroundColor:"#F5F5F5", margin:"0px auto", marginTop:"80px",border:"1px solid #ddd",
      padding:"25px 40px", fontSize:"20px", borderTop:"3px solid #000"}}>고객정보입력 / 결제</div>
        <div style={{ border:"1px solid #ddd", width:'80%', marginLeft:'10%', height:'auto', padding:'0', 
            marginTop:'0px',position:'relative', display:'table' }}>
            
          

          <div className="inputCol">
            <dl><label>예약자 성명 <span color="red">*</span></label><input className="input" required="required" onChange={buyerNameChange} value={buyerName}></input></dl>
            <dl><label>E-mail <span color="red">*</span></label><input className="input" required="required" type="email" onChange={buyerMailChange} value={buyerMail}></input></dl>
            <dl><label>휴대폰번호 <span color="red">*</span></label><input className="input" required="required" onChange={buyerTelChange} value={buyerTel}></input></dl>

            <dl><label>추가 요청사항 </label><textarea className="input"></textarea></dl>

            
          </div>

          <div className="inputCol" style={{margin:'0'}}>
            <div style={{display:'flex', flexDirection:'row', top:'0',
          border:'1px solid #000'}}>
              <div className="payCol payCol1"><p>결제 방법</p></div>
              <div className="payCol">

              <label className="radio" style={{width:"100%", textAlign:"left", paddingLeft:"20px"}}>
                <input type="radio" name="paymentMethod" defaultChecked /><span>신용카드</span>
              </label>




              </div>
           
            </div>
            <div className="agrees" style={{marginTop:'20px'}}>
            <dl><label onClick={openModal}><p>이용약관동의</p><span color="red">*</span></label>
            <input type="checkbox" className="agreeCb" style={{backgroundColor:'rgb(255, 255, 255)', visibility:"hidden",positoin:"absolute"}} id="cb1"/><label className="agree" htmlFor="cb1"></label>

            </dl>
            <dl><label onClick={openModal}><p>개인정보 수집약관</p><span color="red">*</span></label>
            
            <input type="checkbox" className="agreeCb" style={{backgroundColor:'rgb(255, 255, 255)', visibility:"hidden",positoin:"absolute"}} id="cb2"/><label className="agree" htmlFor="cb2"></label>

            </dl>
            <dl><label onClick={openModal}><p>취소 규정</p><span color="red">*</span></label>
            
            <input type="checkbox" className="agreeCb" style={{backgroundColor:'rgb(255, 255, 255)', visibility:"hidden",positoin:"absolute"}} id="cb3"/><label className="agree" htmlFor="cb3"></label>

            </dl>
            </div>

            {/*<label for="agree_all">
            <input type="checkbox" name="agree_all" id="agree_all"/>
            <span>모두 동의합니다</span>
          </label>
          <label for="agree">
            <input type="checkbox" name="agree" value="1"/>
            <span>이용약관 동의<strong>(필수)</strong></span>
          </label>
          <label for="agree">
            <input type="checkbox" name="agree" value="2"/>
            <span>개인정보 수집, 이용 동의<strong>(필수)</strong></span>
          </label>
          <label for="agree">
            <input type="checkbox" name="agree" value="3"/>
            <span>개인정보 이용 동의<strong>(필수)</strong></span>
          </label>
          <label for="agree">
            <input type="checkbox" name="agree" value="4"/>
            <span>이벤트, 혜택정보 수신동의<strong class="select_disable">(선택)</strong></span>
  </label>*/}
            
          </div>

        
       

            
        </div>
       
        <div className="div_button">
            <button className="button" type="button" onClick={onClickPayment}>결제하기</button>
        </div>

        
      </div>

    
      </div>

    </div>

    

      
  );
};
  
export default Reservation;