import {Table, Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './css/table.css'
// useTable에다가 작성한 columns와 data를 전달한 후 아래 4개의 props를 받아온다
const Admin = () => {
  
  return (

  <div className="adminWrapper">
    <div className="adminTop" style={{width:"100%", borderBottom:"1px solid #ddd", padding:"50px 50px", textAlign:"center"}}>
      <div style={{display:"inline-block"}}><h3>객실/예약  조회</h3></div>
      <div style={{float:'right',display:"inline-block"}}><button>인쇄</button><button>엑셀저장</button></div>
    </div>
    <div style={{width:"100%", padding:"10px 0", marginTop:"10px"}}>
      <table className="searchFilters">
      <tr>
        <th>예약번호</th>
        <td>
          <input type="text"></input>
        </td>
      </tr>
      <tr>
        <th>객실상태</th>
        <td>
        <input type="checkbox" id="horns" name="horns"/>
        <label for="horns">예약완료</label>
        <input type="checkbox" id="horns" name="horns"/>
        <label for="horns">예약대기</label>
        <input type="checkbox" id="horns" name="horns"/>
        <label for="horns">예약취소</label>
        </td>
      </tr>
      <tr>
        <th>객실타입</th>
        <td>
        <input type="checkbox" id="horns" name="horns"/>
        <label for="horns">프리미엄룸</label>
        <input type="checkbox" id="horns" name="horns"/>
        <label for="horns">패밀리룸</label>

        </td>
      </tr>
      <tr>
        <th>투숙객명</th>
        <td><input type="text"></input></td>
      </tr>

      <tr>
        <th>연락처</th>
        <td>
          <input type="text"></input>
        </td>
      </tr>
      <tr>
        <th>체크인</th>
        <td><input type="text"></input></td>
      </tr>

      <tr>
        <th>체크아웃</th>
        <td>
          <input type="text"></input>
        </td>
      </tr>
      </table>

      <button className="searchBtn">조회하기</button>
    </div>
    
  <Table>
    <Thead>
      <Tr>
        <Th>예약번호</Th>
        <Th>객실</Th>
        <Th>객실수</Th>
        <Th>체크인</Th>
        <Th>일수</Th>
        <Th>체크아웃</Th>
        <Th>인원</Th>
        <Th>투숙객명</Th>
        <Th>연락처</Th>
        <Th>객실요금</Th>
        <Th>상태</Th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr>
        <Td>111254122</Td>
        <Td>프리미엄룸</Td>
        <Td>1</Td>
        <Td>2022/05/26/15:00</Td>
        <Td>2</Td>
        <Td>2022/05/28/12:00</Td>
        <Td>2</Td>
        <Td>홍길동</Td>
        <Td>010-1234-5678</Td>
        <Td>150,000</Td>
        <Td>예약완료</Td>
      </Tr>
      <Tr>
        <Td>111254122</Td>
        <Td>프리미엄룸</Td>
        <Td>1</Td>
        <Td>2022/05/26/15:00</Td>
        <Td>2</Td>
        <Td>2022/05/28/12:00</Td>
        <Td>2</Td>
        <Td>홍길동</Td>
        <Td>010-1234-5678</Td>
        <Td>150,000</Td>
        <Td>예약완료</Td>
      </Tr>
      <Tr>
        <Td>111254122</Td>
        <Td>프리미엄룸</Td>
        <Td>1</Td>
        <Td>2022/05/26/15:00</Td>
        <Td>2</Td>
        <Td>2022/05/28/12:00</Td>
        <Td>2</Td>
        <Td>홍길동</Td>
        <Td>010-1234-5678</Td>
        <Td>150,000</Td>
        <Td>예약완료</Td>
      </Tr>
      <Tr>
        <Td>111254122</Td>
        <Td>프리미엄룸</Td>
        <Td>1</Td>
        <Td>2022/05/26/15:00</Td>
        <Td>2</Td>
        <Td>2022/05/28/12:00</Td>
        <Td>2</Td>
        <Td>홍길동</Td>
        <Td>010-1234-5678</Td>
        <Td>150,000</Td>
        <Td>예약완료</Td>
      </Tr>
      <Tr>
        <Td>111254122</Td>
        <Td>프리미엄룸</Td>
        <Td>1</Td>
        <Td>2022/05/26/15:00</Td>
        <Td>2</Td>
        <Td>2022/05/28/12:00</Td>
        <Td>2</Td>
        <Td>홍길동</Td>
        <Td>010-1234-5678</Td>
        <Td>150,000</Td>
        <Td>예약완료</Td>
      </Tr>
      <Tr>
        <Td>111254122</Td>
        <Td>프리미엄룸</Td>
        <Td>1</Td>
        <Td>2022/05/26/15:00</Td>
        <Td>2</Td>
        <Td>2022/05/28/12:00</Td>
        <Td>2</Td>
        <Td>홍길동</Td>
        <Td>010-1234-5678</Td>
        <Td>150,000</Td>
        <Td>예약완료</Td>
      </Tr>
      <Tr>
        <Td>111254122</Td>
        <Td>프리미엄룸</Td>
        <Td>1</Td>
        <Td>2022/05/26/15:00</Td>
        <Td>2</Td>
        <Td>2022/05/28/12:00</Td>
        <Td>2</Td>
        <Td>홍길동</Td>
        <Td>010-1234-5678</Td>
        <Td>150,000</Td>
        <Td>예약완료</Td>
      </Tr>
    </Tbody>
  </Table>
  </div>

  );
};

export default Admin;