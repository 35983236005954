import React, { Component } from 'react'
export default class Signup extends Component {
  state = {
    testbody : "",
  }

  handleChange =(e)=>{
    this.setState({
      [e.target.name] : e.target.value,
    });
  }

  /*submitId (event) {
    //alert('A list was submitted: ' + this.state.formvalue);
    event.preventDefault();
    fetch('/idplz', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: "id",
        item: "item",
      })
    })
    .then(res => res.json())
    .then(data => console.log(data))
    .catch(err => console.log(err));
  }*/

  submitId = ()=>{
    /*const post ={
      email : this.state.email,
      name : this.state.name,
      dob : this.state.dob,
      phone : this.state.phone,
    };*/

    const post ={
      sDate : '2008-12-20',
      eDate : '2008-12-22',
      numAdults : 6,
      numKids : 2,
    };
   
    fetch("http://localhost:5000/idplz", {
      method : "post", // 통신방법
      headers : {
        "content-type" : "application/json",
      },
      body : JSON.stringify(post),
    })
    .then((res)=>res.json())
    .then((json)=>{
      this.setState({
        testbody : json.text,
      });
    });
  };

  render() {
    return (
      <div className="login" style={{marginTop:"150px", display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection:'column',
      width: '100%',
      height: '100vh'}}>
        <input onChange={this.handleChange} placeholder="이메일" name ="email"/>
        <input onChange={this.handleChange} placeholder="이름" name ="name"/>
        <input onChange={this.handleChange} placeholder="생년월일" name ="dob"/>
        <input onChange={this.handleChange} placeholder="전화번호" name ="phone"/>


        
        <button onClick = {this.submitId}>Submit</button>
        <h1>{this.state.testbody}</h1>
      </div>
    )
  }
}