import './css/home.css'
import './css/choice.css'


import React, { useState,useEffect, useRef } from 'react';
import { Link,useLocation } from "react-router-dom"
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const Confirm = () => {
    const location = useLocation();
    const data = location.state;
    const [booking, setBooking] = useState([]);

    const bookingInfo = useRef(null);
    const roomInfo = useRef(null);
    const guestInfo = useRef(null);

    const booking_id = data.booking_id;

    const room_type_id = data.room_type_id;

    const guest_id = data.guest_id;

    console.log(".....");
    console.log(booking_id);
    const post ={
      booking_id : booking_id,
      guest_id : guest_id,
      room_type_id : room_type_id
    };
    useEffect(() => {
    

    fetch("http://localhost:5000/getbooking", {
      method : "post", // 통신방법
        headers : {
          "content-type" : "application/json",
          'Accept': 'application/json'
        },
      body : JSON.stringify(post),
    })
    .then((res)=>res.json())
    .then((json)=>{
      
      
      setBooking(json);

      

      const info =json[0][0];
      const info2 =json[1][0];
      const info3 =json[2][0];
      console.log(info.booking_id);

      bookingInfo.current = info;
      roomInfo.current = info2;
      guestInfo.current = info3;
      console.log("uhh-----" + bookingInfo.current.booking_id);
      
      

              
    });        
  }, []);

    
  if (bookingInfo.current === null) {
    return <>Still loading...</>;

  }

  

  return (
    <div className="wrap" style={{flex:'1', marginBottom:'500px'}}>   

  
    <div style={{height:'70px',  width:'60%',margin:'180px auto 20px auto', textAlign:'center',
fontSize:'30px', lineHeight:'62px'}}>
        예약이 완료되었습니다.
    </div>

    <div className="roomArea" style={{ height:'100%', border:'2px solid #ddd', margin:'0 auto',
    marginTop:'100px',position:'relative'}}>
       
      <div style={{height:'auto', display:'flex',marginTop:'50px'  }}>
        <div style={{ border:"2px solid #000", width:'80%', marginLeft:'10%', height:'auto', padding:'20px', 
            marginTop:'0px',display:'block',position:'relative' }}>
              <table className="confirmTable">

                <tr>
                  <th>
                    예약번호:{bookingInfo.current.booking_id}
                  </th>
                  <td></td>
                </tr>

                <tr colSpan="3">
                  <th>
                    객실: {roomInfo.current.room_name}
                  </th>
                  <td>
                  <Link to="/rooms">
                    <button style={{ borderRadius:'17px', marginLeft:'10px',backgroundColor:"#998465",
                  border:"none",padding:"5px 10px",color:"white", fontSize:"15px" }}>객실 상세 안내</button>
                  </Link>
                  </td>
                </tr>

                <tr>
                  <th>
                    성명: {guestInfo.current.guest_name}
                  </th>
                  <td></td>
                </tr>
                

                <tr>
                  <th>
                    이메일: {guestInfo.current.guest_email}
                  </th>
                  <td></td>

                  <th>
                    연락처: {guestInfo.current.guest_phone}
                  </th>
                  <td></td>
                </tr>

                <tr>
                  <th>
                    체크인: {bookingInfo.current.date_start}
                  </th>
                  <td></td>

                  <th>
                    체크아웃: {bookingInfo.current.date_end}
                  </th>
                  <td></td>
                </tr>


                <tr>
                  <th>
                    추가 요청사항:
                  </th>
                  <td></td>
                </tr>

                

                
              </table>
        </div>

      </div>
        
      <div style={{ border:"2px solid #000", width:'80%', marginLeft:'10%', height:'auto', padding:'20px', 
            marginTop:'50px',display:'block',position:'relative'}}>
              <div className="reserveInfo">
                <div className="col1 col">
                  객실1 <br></br>
                  인원 {bookingInfo.current.num_persons} 명 <br></br>
                  {bookingInfo.current.date_start} ~ {bookingInfo.current.date_end}
                </div>
                <div className="col2 col">

                {bookingInfo.current.date_end}
                <br></br>
                베드 옵션: 더블 x 2
                <br></br>
                (이용 가능 여부는 달라질 수 있음)
                <br></br>
                Bed & Breakfast
                <br></br>
                조식 포함: 다채조식
                  
                </div>
                <div className="col3 col price">
                </div>
              </div>


              <div className="reserveInfo" style={{borderTop:'1px solid #000'}}>
                <div className="col1 col">
                  추가 옵션
                </div>
                <div className="col2 col">
                  인원 추가 (x2명)
                  
                </div>
                <div className="col2 col price">
                ₩20,000
                </div>
              </div>

              <div className="reserveInfo" style={{borderTop:'1px solid #000'}}>
                <div className="col3 col">
                  합계
                </div>
                <div className="col2 col">
                  
                </div>
                <div className="col3 col price">
                </div>
              </div>

        
       

            
        </div>


            
        
        
      

      
 
        <div className="div_button">
        <button style={{ backgroundColor:"#fff",  width:"150px",margin:"20px auto"
      ,padding:"10px 20px", marginTop:'50px', fontSize:'16px', display:'block'}} onClick={() => { 
        let proceed = window.confirm("예약을 취소하시겠습니까?");
        if (proceed) {

          const today = new Date();

          const sdate = new Date(bookingInfo.current.date_start);

          const daydiff = Math.floor((sdate - today) / (1000*60*60*24));

          const booking_amount = bookingInfo.current.total_price;

          var cancel_request_amount = 0;

          if (daydiff > 5){
            cancel_request_amount = booking_amount * 1;
          }else if (daydiff === 5){
            cancel_request_amount = booking_amount * 0.8;
          }else if (daydiff === 4){
            cancel_request_amount = booking_amount * 0.6;
          }else if (daydiff === 3){
            cancel_request_amount = booking_amount * 0.4;
          }else if (daydiff === 2){
            cancel_request_amount = booking_amount * 0.2;
          }else{
            return alert("예약 1일전, 당일 취소는 불가합니다.");
          }

          const bookingid = {
            booking_id: bookingInfo.current.booking_id,
          }
          fetch("http://localhost:5000/getuid", {
            method : "post", // 통신방법
            headers : {
              "content-type" : "application/json",
              'Accept': 'application/json'
            },
            body : JSON.stringify(bookingid),
          })
          .then((res)=>res.json())
          .then((json)=>{
            console.log(JSON.stringify(json));
            axios({
              url: "http://localhost:5000/cancel", // 예: http://www.myservice.com/payments/cancel
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              data: { 
                booking_id : bookingInfo.current.booking_id,
                imp_uid: json[0].RESULT, // 주문번호 - 필요없는것같은데??? imp_uid만 쓰잖아.nod
                cancel_request_amount: cancel_request_amount, // 환불금액
                reason: "테스트 결제 환불" // 환불사유
              }
            }).then(response => { // 환불 성공시 로직 
              alert("환불 성공");

            }).catch(error => { // 환불 실패시 로직 
              alert("환불 실패 : ", error);
            });
            
          })



          
        } else {
          //don't proceed
        }
        }}>예약취소</button>
        </div>

      </div>
        
    </div>



      
  );
};
  
export default Confirm;