import './css/board_view.css';
import React, { useRef, useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import downarrow from './img/arrow_down.png';
import uparrow from './img/arrow_up.png';
import dompurify from "dompurify";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const View = () => {
  const [korean, setKor] = useState("false");
	const { t, i18n } = useTranslation();
	const sanitizer = dompurify.sanitize;
	const language = useRef(null);
	
	useEffect(()=>{
		language.current = sessionStorage.getItem('language');
	if (language===null){
		language.current = cookies.get('language');
	}
	i18next.changeLanguage(language.current);
	if (language.current ==="kr-KR"){
		setKor(true);
	  }else {
		setKor(false);
	  }
	},[]);
    const [ data, setData ] = useState({});
    //const [token, setToken] = useState(cookies.get("access-token"));
    const { no } = useParams();
    const [prev, setPrev] = useState("");
    const [next, setNext] = useState("");
    const navigate = useNavigate();
    //const [ isloggedIn, setIsLogin ] = useState(false);
    const board_id = data.board_id;
    let isloggedIn = sessionStorage.getItem('isloggedIn');
    if (isloggedIn===null){
      console.log(isloggedIn);
      isloggedIn = cookies.get('isloggedIn');
    }
    function updateBoard(){
      navigate('/update/'+board_id,  {
        state: {
          board_id : board_id,
          content : data.content,
          subject: data.subject
        }
      });
    }

    function deleteBoard(){
      const post = {
        board_id : board_id
      }
      if (window.confirm("해당 게시물을 삭제하시겠습니까? 삭제된 데이터는 복구할 수 없습니다.")){
        fetch("http://222.101.0.9:5000/api/boarddelete", {
          method : "post", // 통신방법
          headers : {
            "content-type" : "application/json",
            'Accept': 'application/json'
          },
          body : JSON.stringify(post),
        })
        .then((res)=>res.json())
        .then((json)=>{
          setData(json[0]);
          console.log(data);
          alert("게시물이 삭제되었습니다.");
          navigate("/board");
        });
      }else{

      }
    }

    useEffect(() => {
      
        const post = {
            no : no
        }
        fetch("http://222.101.0.9:5000/api/getpost", {
            method : "post", // 통신방법
            headers : {
              "content-type" : "application/json",
              'Accept': 'application/json'
            },
            body : JSON.stringify(post),
          })
          .then((res)=>res.json())
          .then((json)=>{
            setData(json[0]);
            console.log(data);
         
          });
          
    }, []);    

   
    if (data === null) {
      return <>Still loading...</>;
  
    }
    
    return (
      
        <div className="wrapper">

        <div className="">

        <h2 className = "headerNotice">{i18next.t('notice')}</h2>
<div className="bbs-view">
    <div className="top">
		<strong className="tit">{data.subject}</strong>
		<div className="etc clearfix">
			{ korean && <span>관리자</span> }
      
      <span>{data.board_date}</span>
		</div>
	</div>
     
        <div className="cont" style={{textAlign:"center"}} dangerouslySetInnerHTML={ {__html: data.content} }>
   
          
        </div>
        { isloggedIn && (
        <div className="bbs-bot clearfix">
        
        

            <div className="prev_next clearfix">
                <button className="btn-st2" onClick={updateBoard}>수정</button>		
                
                <button className="btn-st2" onClick={deleteBoard}>삭제</button>		
            </div>
            
        
            <div className="r"> <a href="/board" className="btn-st2">목록</a>	</div>
        </div>
        )}

      { !isloggedIn && (
        <div className="bbs-bot clearfix">
        
        
            <div className="center"> <a href="/board" className="btn-st2">{i18next.t('list')}</a>	</div>
        </div>
      )}
          
        
</div>

<div class="bbsbotm_tbl">
	<div class="tbl_basic">
		<table>
			<colgroup>
				<col class="tit"></col>
				<col></col>
				<col class="day hide-m"></col>
			</colgroup>
		<tbody>
		{data.PREV_SN &&
    <tr>
			<td><span class="i-aft i_arr_t1">{i18next.t('prev')}</span><img className="arrowIcon" src={uparrow} alt="icon"></img></td>
			<td class="subject"><span class="i-bat2 ty1 mr20">NOTICE</span>
			<a href={"/postView/"+ data.PREV_N} class="t_dot">{data.PREV_SN}</a>
			</td>
			<td class="hide-m">{data.PREV_D.substring(0,10)}</td>  
		</tr>
    }
    {data.NEXT_SN &&
		<tr> 
			<td><span class="i-aft i_arr_b1">{i18next.t('next')}</span><img className="arrowIcon" src={downarrow} alt="icon"></img></td>
			<td class="subject"><span class="i-bat2 ty1 mr20">NOTICE</span>
			<a href={"/postView/"+ data.NEXT_N} class="t_dot">{data.NEXT_SN}</a>
			</td>      
			<td class="hide-m">{data.NEXT_D.substring(0,10)}</td>
		</tr>
    }
	</tbody>
</table>
</div> 
</div>


</div>
</div>
    );
  };
  
  export default View;