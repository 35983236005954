import jejuisland from './img/hoteloverview.jpg'
import './css/overview.css'


import hotel1 from './img/hotel1.jpg'
import hotel2 from './img/hotel2.jpg'
import hotel3 from './img/hotel3.jpg'
import hotel4 from './img/hotel4.jpg'
import TabNavItem from "../components/TabNavItem";
import TabContent from "../components/TabContent";
import '../components/Tabs2.css';
import React, { useState, useEffect, useRef } from 'react';
import dompurify from "dompurify";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const { kakao } = window;


const Overview = () => {

  const { t, i18n } = useTranslation();
  const sanitizer = dompurify.sanitize;
  const language = useRef(null);
  
  useEffect(()=>{
    language.current = sessionStorage.getItem('language');
  if (language===null){
    language.current = cookies.get('language');
  }
  i18next.changeLanguage(language.current);
  },[]);

  const [activeTab, setActiveTab] = useState("tab1");

    
    return (
        <div className="wrap">
     
        <h2 className = "headerTxt">객실 이용안내</h2>
        <div className="guide">
      


        <div className="roomNotice">
<h2 class="roomHeader">{i18next.t('guideline')}</h2>
	<div className="">
	<h3>입실 안내 Check-in</h3>
	<table>
		<tr>
			<th>
				구분
			</th>
			<th>
				입실시간
			</th>
		</tr>
		<tr>
			<td>
				성수기, 주말, 연휴
			</td>
			<td>15:00 ~ 22:00</td>
		</tr>

		<tr>
			<td>
				비수기, 주중
			</td>
			<td>14:00 ~ 22:00</td>
		</tr>
	</table>

  <p>예약번호와 회원카드를 지참하시고 등록카드를 작성하신 뒤 프런트 데스크에서 객실 배정을 마친 후 입실하시면 됩니다.</p>
  <p>회원의 권리를 보장하기 위하여 회원 본인 확인 후 회원 본인이 아닐 때에는 회원초청 요금이 적용됩니다.</p>
  <p>규정 숙박정원 초과 시 미리 말씀하여 주시면 추가요금을 할인 받으실 수 있습니다.</p>
  <p>22시 이후 입실하실 경우 사전에 프런트로 연락 주시기 바랍니다.</p>
  <p>상기 입실 시간은 사업장에 따라 상이 할 수 있습니다.</p>


	<h3 style={{marginTop:"40px"}}>퇴실 안내 Check-out</h3>
	<table>
		<tr>
			<th>
				구분
			</th>
			<th>
				입실시간
			</th>
		</tr>
		<tr>
			<td>
      성수기, 주말, 연휴
			</td>
			<td> 11:00</td>
		</tr>

		<tr>
			<td>
				비수기, 주중
			</td>
			<td> 12:00</td>
		</tr>
	</table>
  <p>개인회원 및 법인 정회원을 대상으로 고객 여러분의 편의를 위해 자율퇴실 점검을 실시합니다.</p>
  <p>퇴실 전 객실을 점검하여 주시고 입실 시 받으신 점검증을 체크하시어 퇴실시 프런트에 제출하여 주시면 됩니다.</p>
  <p>Late check-out(늦은 퇴실)의 경우 별도의 요금이 부과됩니다. (30분당 금액/VAT 포함)</p>
  <p>(패밀리, 스위트: 회원 6,600원, 비회원 13,200원 / 노블리안: 회원 13,200원, 비회원 26,400원)</p>
  <p>※ 오후 3시 이후 퇴실 시 1박 요금 적용</p>
  <p>체크인 때 규정된 숙박정원이 초과되었으나 추가요금을 내지 않으시고 퇴실 점검 때 확인될 경우, 할인된 추가요금이 아닌 정상요금이 부과됩니다.</p>
  <p>퇴실 전 다음 고객을 위하여 주방 청소 및 쓰레기 분리수거는 꼭 부탁 드립니다.</p>
  <p>상기 퇴실 시간은 사업장에 따라 상이할 수 있습니다.</p>
  <p>퇴실정산은 A동 프런트에서 통합 운영합니다.</p>

	<h3>객실 정원 안내 Max, Pax</h3>

  <table>
		<tr>
			<th>
				구분
			</th>
			<th>
				정원
			</th>
		</tr>
		<tr>
			<td>
          패밀리형
			</td>
			<td> 4인</td>
		</tr>

		<tr>
			<td>
				스위트형
			</td>
			<td> 5인</td>
		</tr>


    <tr>
			<td>
				골드스위트형
			</td>
			<td> 7인</td>
		</tr>
	</table>



	<h3>추가인원 Extra Person</h3>

  <table>
		<tr>
			<th rowSpan={2}>
				구분
			</th>
			<th colSpan={2}>
				정원 추가요금(1인 1박당)
			</th>
      <th rowSpan={2}>최대 추가 인원</th>

		</tr>

    <tr>
     
      <th>자진신고</th>
      <th>미신고 적발시</th>
    </tr>
		<tr>
			<td>
          패밀리형
			</td>
			<td rowSpan={2}> 5,500원</td>
      <td rowSpan={2}> 11,000원</td>
      <td> 2인</td>
		</tr>

		<tr>
			<td>
				스위트형
			</td>
      <td> 3인</td>
		</tr>


	</table>

  <p>침구류 추가 요청 시, 1박당 11,000원의 추가비용이 발생합니다. (VAT포함)</p>
  <p>침구 추가는 최대 추가인원 수를 초과할 수 없습니다.</p>
  <p>8세 미만의 추가 인원은 추가 요금에서 제외됩니다.</p>
  <p>수건은 1박 투숙 시 객실 정원수만큼 제공되며, 추가 요청 시 1장당 500원의 환경단체 기부금이 발생합니다.</p>

  <h3>객실 정비안내 Cleaning Service</h3>
  <p>리조트 객실 3박 이상 투숙시 무료 재실정비가 1회 진행됩니다. (사진 요청 필수)</p>
  <p>3박 미만 리조트 객실에 대하여 재실정비는 운영하지 않습니다.</p>

  <h3>객실 습득물 문의 Lost&amp;Found</h3>
  <p>이용 후 객실에 물건을 놓고 가는 경우가 종종 발생하고 있습니다.</p>
  <p>습득물은 소노벨 제주 A동(LOBBY) 객실영업팀에서 보관하며 고객 요청 시 숙박여부 및 발생 물품 등 간단한 확인을 통하여 일치할 경우 택배(착불)로 발송하여 드리고 있습니다.
    아울러 객실에 두고 가시는 물건이 없는지 잘 살펴보시는 습관도 중요하리라 봅니다.
  </p>
  <p>보다 신속한 처리를 위하여 객실번호, 날짜 등을 확인하신 후 전화 주시면 편리하게 서비스를 받으실 수 있습니다.</p>
  <p>음식물의 경우 당일 폐기이므로 당일 이후에는 보관하지 않는 점 양해 부탁 드립니다.</p>
  <p>기타 분실물에 대해서 별도 연락이 없으면 1개월 후 폐기합니다.</p>
  <p>문의전화 : 1588-4888 / 구내전화 : 105</p>

  <h3>사용 수칙 Special Information</h3>
  <p>객실에 애완동물이나 위험물 반입은 일체 금지되어 있습니다.</p>
  <p>고객님의 안전과 재산보호를 위해 객실 내에서는 버너 사용/불판/전기그릴 등 화기용품 사용이 금지되어 있습니다.</p>
  <p>귀중품은 반드시 프런트 데스크에 보관하여 주시기 바랍니다. 보관되지 않은 귀중품 분실시 당사에서는 책임지지 않음을 양지하여 주시기 바랍니다.</p>
  <p>기타 자세한 사용 수칙은 객실 내 비치되어 있는 부대업장 안내 브로슈어를 참조하여 주시기 바랍니다.</p>
  <p>방역에 만전을 가하고 있으나 지리적 특성상 작은 곤충 및 벌레를 100% 차단하기 불가능함을 양해 바랍니다.</p>
  <p>(객실 내부 벌레 출현으로 인한 보상은 불가능합니다.)</p>

  <h3>미성년자 입실안내 Minors Check-in</h3>
  <p>청소년 보호법에 의거하여 보호자 미 동반 투숙 시 입실이 불가할 수 있으니 양지해 주시기 바랍니다.</p>
  <p>미성년자(만 19세미만)의 경우 부모의 동의 또는 동행 시에만 숙박이 가능합니다. (혼숙 입실불가)</p>
  <p>불가피하게 숙박이 필요한 경우 아래 미성년자 숙박동의서를 작성하신 후 안내되어 있는 서류와 함께 제출하여야 합니다.</p>
	</div>






</div>

        </div>

      </div>
   

       
     

    

      
  );
};
  
export default Overview;
