import React, { useRef, useEffect, useState } from "react";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import { Link, useNavigate } from 'react-router-dom';
import './css/write.css';
import axios from "axios";
import moment from 'moment';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default function TextEditor() {
  // Editor DOM
  const editorRef = useRef();
  const navigate = useNavigate();

  const [value, setValue] = useState("");
  const [subject, setSubject] = useState("");
  const [fileArray, setArray] = useState([]);


  let isloggedIn = sessionStorage.getItem('isloggedIn');
  if (isloggedIn===null){
    isloggedIn = cookies.get('isloggedIn');
    console.log("isloggedIn" , isloggedIn);
    if (isloggedIn===undefined || isloggedIn === null){
      return (null);
    }
  }

  const subjectChange = event => {
    setSubject(event.target.value);
  };
  let files = [];

  const deletePicButton = () => {
    axios.delete('http://222.101.0.9:5000/api/deletepic', {
      data: { // 서버에서 req.body.{} 로 확인할 수 있다.
        file_name: files
      },
    }).then(() => this.setState({ status: 'Delete successful' }));
  };
    // 등록 버튼 핸들러
  const handleRegisterButton = () => {
    console.log(editorRef.current?.getInstance().getHTML());
    if(subject===""){
      alert("제목을 입력해주세요.");
      return false;
    }else if(editorRef.current?.getInstance().getHTML()==='<p><br class="ProseMirror-trailingBreak"></p>'){
      alert("내용을 입력해주세요.");
      return false;
      
    }
    var arrayLength = fileArray.length;
    var extractStr = editorRef.current?.getInstance().getMarkdown();
    for (var i = 0; i < arrayLength; i++) {

      let result = extractStr.match(fileArray[i]);
        console.log("result: " + result);
        if (result == null){
          var filename = fileArray[i].substring(fileArray[i].lastIndexOf('/')+1);

          axios.delete('http://222.101.0.9:5000/api/deletepic', {
            data: { // 서버에서 req.body.{} 로 확인할 수 있다.
              file_name: filename
            },
          }).then(() => this.setState({ status: 'Delete successful' }));
        }
        //Do something
    }

    // 입력창에 입력한 내용을 HTML 태그 형태로 취득
    const date_time = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    const board = {
      content : editorRef.current?.getInstance().getHTML(),
      date_time : date_time,
      subject : subject
    }
    const fetch = async() => {
      await axios
      .post("http://222.101.0.9:5000/api/boardinsert", board, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
      .then(({data}) => {
        console.log("...."+ data);
        navigate("/board");
        
         
      });
    }
    fetch();
  };

    
    
    /*fetch("http://localhost:5000/boardinsert", {
      method : "post", // 통신방법
      headers : {
      "content-type" : "application/json",
      'Accept': 'application/json'
      },
      body : JSON.stringify(board),
    })
    .then((res)=>res.json())
    .then((json)=>{
      console.log(json);
      
    });  */
    

  

  return (
    <div className="write_wrapper">
      <form id='contact-form' noValidate>
        <div className='formRow'>
          <div className='col col_title'>
            <label for="type" className="form_tit"><span className="point_txt">* 제목</span></label>
            <input type='text' name='name' className='formInput' placeholder='제목을 입력하세요.' onChange={subjectChange} value={subject}></input>
          </div>
        </div>
        <div className='formRow'>
        <label for="type" className="form_content"><span className="point_txt">* 내용</span> </label>
          <div className='col'>
          <Editor
          ref={editorRef}
          initialValue=" "
          previewStyle="vertical"
          height="300px"
          onChange={setValue}
          initialEditType="wysiwyg"
          toolbarItems={[
            // 툴바 옵션 설정
            ['heading', 'bold', 'italic', 'strike'],
            ['hr', 'quote'],
            ['ul', 'ol', 'task', 'indent', 'outdent'],
            ['table', 'image', 'link'],
            ['code', 'codeblock']
          ]}
          
          // hooks 에서 addImageBlobHook 를 주물러 주면 된다.
          hooks={{
            addImageBlobHook: async (blob, callback) => {
              
              console.log(blob.size); 
              if (blob.size>5000000){
                alert("업로드에 실패했습니다. 이미지당 5MB까지 업로드할 수 있습니다.");
                return; 
              }
              // File {name: '카레유.png', ... }
              const formData = new FormData();
              formData.append('img', blob);
              // 1. 첨부된 이미지 파일을 서버로 전송후, 이미지 경로 url을 받아온다.
              // const imgUrl = await .... 서버 전송 / 경로 수신 코드 ...
              const result = await axios.post('http://222.101.0.9:5000/api/img', formData);
              console.log('성공 시, 백엔드가 보내주는 데이터', result.data.url);
              const IMG_URL = result.data.url;
              // 2. 첨부된 이미지를 화면에 표시(경로는 임의로 넣었다.)
              callback(IMG_URL, 'test');
              files.push(IMG_URL);
              //setArray(files);
              setArray(prevArray => [...prevArray, IMG_URL])

              console.log(fileArray);
              var arrayLength = fileArray.length;
              console.log(arrayLength);
              return false;
            }
          }}
          ></Editor>
          </div>
        </div>
        {/*<div style={{padding:"20px 30px", border:"2px solid #000", width:"150px", textAlign:"center", display:"inline-block", cursor:"pointer"}} onClick={deletePicButton}>이미지 삭제 TEST 버튼</div>*/}
                  
      </form>
      <div style={{marginTop:"40px"}}>
          <Link to="/board" id="toList"> <div className="btn_list">목록으로</div></Link>
          <div className="btn_write" onClick={handleRegisterButton}>글 작성</div>
        </div>   
    </div>
  );
}
