import './css/login.css'
import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom"
import axios from 'axios';
import Cookies from 'universal-cookie';
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const cookies = new Cookies();


const AdminLogin = () => {
    const [user_id, setUserId] = useState('');
    const navigate = useNavigate();
    const userIdChange = event => {
        setUserId(event.target.value);
      
    };

    const [password, setPassword] = useState('');

    const passwordChange = event => {
        setPassword(event.target.value);
    };
    useEffect(() => {
      let isloggedIn = sessionStorage.getItem('isloggedIn');
      if (isloggedIn===null){
        console.log(isloggedIn);
        isloggedIn = cookies.get('isloggedIn');
        if (isloggedIn==="true"){
          //console.log("loggedin...???");
          navigate("/board");
        }
      }
      if (isloggedIn==="true"){
        //console.log("loggedin...???");
        navigate("/board");
      }
      
    }, []);
    
    async function onLogin(e) {
      e.preventDefault();
        const data ={
            user_id : user_id,
            password : password
          }
        if (user_id.length ===0){
          alert("아이디를 입력해주세요");
          return;
        }
        if (password.length ===0){
          alert("비밀번호를 입력해주세요");
          return;
        }
        {/*axios.post('http://222.101.0.9:5000/api/auth/login', data).then(response => {
          console.log("로그인됐니...????");
//        axios.post('http://localhost:5000/api/auth/login', data).then(response => {
          const { isloggedIn } = response.data;

            
            sessionStorage.setItem('isloggedIn', isloggedIn);
            
            cookies.set('isloggedIn', isloggedIn, { path: '/', maxAge: 3600} );

            console.log("로그인됐니...", cookies.get('isloggedIn'));
            navigate("/board", {replace: true});

    
        }).catch(error => {
          console.log(error);
          alert("아이디 또는 비밀번호가 일치하지 않습니다.")
            // ... 에러 처리
        });*/}
        
        await fetch("http://222.101.0.9:5000/api/login", {
              method : "post", // 통신방법
              headers : {
                "content-type" : "application/json",
                'Accept': 'application/json'
              },
              body : JSON.stringify(data),
            })
            .then((res)=>res.json())
            .then((json)=>{
              var isloggedIn;
              
              if (json.result==="ok"){
                isloggedIn= true;
                console.log("로그인 성공");
                sessionStorage.setItem('isloggedIn', isloggedIn);
            
                cookies.set('isloggedIn', isloggedIn, { path: '/', maxAge: 3600} );

                navigate("/board", {replace: true});
              }else{
                console.log("error");
                alert("아이디 또는 비밀번호가 일치하지 않습니다.")
              }
              
            
            
              
            }).catch(()=>{
            
            })  
        /*axios.post('http://222.101.0.9:5000/auth/login', data).then(response => {
            const { accessToken } = response.data;
            console.log(accessToken);
            // API 요청하는 콜마다 헤더에 accessToken 담아 보내도록 설정
            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
            cookies.set('access-token', accessToken);
            // accessToken을 localStorage, cookie 등에 저장하지 않는다!
    
        }).catch(error => {
            // ... 에러 처리
        });*/

    }
    /*function handleClick(e) {

           
        const data ={
          user_id : user_id,
          password : password
        }
        if (user_id.length === 0 || password.length === 0) {
            throw new Error("Here we stop");
        }

        fetch("http://222.101.0.9:5000/auth/login", {
            method : "post", // 통신방법
            headers : {
              "content-type" : "application/json",
              'Accept': 'application/json'
            },
            body : JSON.stringify(data),
          })
          .then((res)=>res.json())
          .then((json)=>{
            //console.log(JSON.stringify(json));
            console.log(json[0].result);
            const accessToken = json[0].accessToken;
            console.log("..."+accessToken);
           
            //
          }).catch(()=>{
            
          })
          
        }*/
        
          
        

        
    
  

    return (
        <div className="wrap">
         
            
            <div className="login">
            <h2 className = "headerTxt">관리자 로그인</h2>
        
              <div className="loginForm">

              <div className="user_id">
              <input text="user_id" id="user_id" placeholder="아이디를 입력해주세요"  onChange={userIdChange} value={user_id}/><br/>
              </div>
              <div className="password">
              <input text="password" id="password" type="password" placeholder="비밀번호를 입력해주세요"  onChange={passwordChange} value={password}/><br/>
              </div>
              <div className="div_button">
              <div className="btn-st2"onClick={onLogin}>LOGIN</div>
              </div>
              </div>
              
             

            </div>
            
        </div>

    

      
  );
};
  
export default AdminLogin;