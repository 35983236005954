import './css/home.css'
import './css/reservation.css'
import './css/daterangepicker.css'

import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, {useState, useEffect, useRef } from 'react';
 import moment from 'moment';

/*import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';*/

import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';


import { Link, useLocation } from "react-router-dom"
import Axios from "axios";

const Reservation = () => {
  useEffect(()=>{
    Axios.get('/api/customers').then((response)=>{
      console.log(response.data)
    })
  }, [])

  //let textInput = React.createRef();

  
  /*const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);


  const [startDate, setStartDate] = useState(new Date("2014/02/08"));
  const [endDate, setEndDate] = useState(new Date("2014/02/10"));*/
  
  const [counterA, setCounterA] = useState(1);
  const [counterB, setCounterB] = useState(2);
  const [counterC, setCounterC] = useState(0);

  const [dateChange, setDateChange] = useState(false);
  const today = new Date();

  
  const location = useLocation();
  const dates = location.state;
  /*const [sDate, setsDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [eDate, seteDate] = useState(moment(today.setDate(today.getDate() + 1)).format("YYYY-MM-DD"));*/

  const sDate = useRef(moment(new Date()).format("YYYY-MM-DD"));
  const eDate = useRef(moment(today.setDate(today.getDate() + 1)).format("YYYY-MM-DD"));
  const post2 ={
    sDate : sDate,
    eDate : eDate
  }
  useEffect(() => {
    if (dates){
      /*setsDate(moment(new Date(dates.sDate)).format("YYYY-MM-DD"));
        seteDate(moment(new Date(dates.eDate)).format("YYYY-MM-DD"));
        */
        sDate.current = moment(new Date(dates.sDate)).format("YYYY-MM-DD");
        eDate.current = moment(new Date(dates.eDate)).format("YYYY-MM-DD");
        const defaultReserve = document.getElementsByClassName("rs-picker-daterange");
        defaultReserve[0].value = {
          startDate : sDate,
          endDate : eDate
        };
    }
  }, []);
  useEffect(() => {
    // code to run after render goes here
    
    if (dates){

      console.log(sDate);
      fetch("http://localhost:5000/price", {
            method : "post", // 통신방법
            headers : {
              "content-type" : "application/json",
              'Accept': 'application/json'
            },
            body : JSON.stringify(post2),
          })
          .then((res2)=>res2.json())
          .then((json2)=>{
            
            
            const post ={
              sDate : sDate.current,
              eDate : eDate.current,
              numAdults : counterB,
              numKids : counterC,
              price_sep  : json2[0].RESULT
            };
           
            fetch("http://localhost:5000/idplz", {
              method : "post", // 통신방법
              headers : {
                "content-type" : "application/json",
                'Accept': 'application/json'
              },
              body : JSON.stringify(post),
            })
            .then((res)=>res.json())
            .then((json)=>{
              /*this.setState({
                testbody : json.text,
        
              });*/
              
              setData(json);
              console.log(json);
              
            });        
            
          });
    }
  }, []);

  

  

  const onChange = (item) => {

    const s= item.value[0];
    const e = item.value[1];
    /*setsDate(moment(s).format("YYYY-MM-DD"));
    seteDate(moment(e).format("YYYY-MM-DD"));*/
    sDate.current = moment(s).format("YYYY-MM-DD");
    eDate.current = moment(e).format("YYYY-MM-DD");
    setDateChange(!dateChange);
    console.log(sDate.current, eDate.current); 
    // should print something like { name: "startDate", value: "the date object" }
  }


  const [data, setData] = useState([]);
  const [price_sep, setPrice] = useState("");

  

  
  function handleClick(e) {

    const defaultReserve = document.getElementsByClassName("defaultReserve");
    defaultReserve[0].style.display = "none";
   
    console.log(sDate, eDate, counterA, counterB, counterC);

    
    /*if (sDate.length === 0 || eDate.length === 0) {
      const today =moment(new Date()).format("YYYY-MM-DD")
      setsDate(today);
      console.log(sDate);
      const date = new Date();
      const tomorrow = moment(date.setDate(date.getDate() + 1)).format("YYYY-MM-DD");
      seteDate(tomorrow);
    }*/

    
    console.log("...."+ sDate);

        fetch("http://localhost:5000/price", {
            method : "post", // 통신방법
            headers : {
              "content-type" : "application/json",
              'Accept': 'application/json'
            },
            body : JSON.stringify(post2),
          })
          .then((res2)=>res2.json())
          .then((json2)=>{
            
            
            const post ={
              sDate : sDate.current,
              eDate : eDate.current,
              numAdults : counterB,
              numKids : counterC,
              price_sep  : json2[0].RESULT
            };
           
            fetch("http://localhost:5000/idplz", {
              method : "post", // 통신방법
              headers : {
                "content-type" : "application/json",
                'Accept': 'application/json'
              },
              body : JSON.stringify(post),
            })
            .then((res)=>res.json())
            .then((json)=>{
              /*this.setState({
                testbody : json.text,
        
              });*/
              
              setData(json);
              console.log(json);
              
            });        
            
          });

          
    


    
  }




  /*const location = useLocation();
  const dates = location.state;

  setsDate(dates.sDate);
  seteDate(dates.eDate);*/
 

  

    const DisplayData=data.map(
        (info)=>{

       

          
          
          let psDate = moment(info.start_date).format('YYYY-MM-DD');
          let peDate = moment(info.end_date).format('YYYY-MM-DD');

          let price = 0;

          //let days = findDayDifference("2022-07-16", "2002-07-21");
          function parseDate(str) {
            var mdy = str.split('-');
            return new Date(mdy[0], mdy[1]-1, mdy[2]);
          }
        
          function datediff(first, second) {
              // Take the difference between the dates and divide by milliseconds per day.
              // Round to nearest whole number to deal with DST.
              return Math.round((second-first)/(1000*60*60*24));
          }
       
          const days = datediff(parseDate(sDate.current), parseDate(eDate.current));
          console.log("hey....." + sDate.current); console.log("ddong....." + eDate.current);
          let price_desc = "";
         
          if (sDate > psDate && sDate < peDate && eDate > psDate && eDate < peDate) { //모두 성수기 날짜일 때
            price = info.amount * days;
            price_desc = info.amount.toLocaleString() + "/" + days +"박";
          } else if (sDate < psDate && sDate <peDate && eDate >psDate && eDate <peDate){

            let day = datediff(parseDate(psDate), parseDate(eDate));
            price = info.amount * day;
            let day2 = datediff(parseDate(sDate), parseDate(psDate))
            price += info.original_amount * day2;

            price_desc = sDate + "~" + psDate + ": " + info.original_amount.toLocaleString() + "*"  + day2 + "박\n" + psDate + "~" + eDate + ": " + info.amount.toLocaleString() + "*" + day +"박";

          } else if (sDate > psDate && sDate <peDate && eDate >psDate && eDate > peDate){
            let day = datediff(parseDate(sDate), parseDate(peDate));
            price = info.amount * day;
            let day2 = datediff(parseDate(peDate), parseDate(eDate))
            price += info.original_amount * day2;

            price_desc = sDate + "~" + peDate + ": " + info.amount.toLocaleString() + "*" + day +"박\n " + peDate + "~" + eDate + ": " + info.original_amount.toLocaleString() + "*"  + day2 + "박";

          } else {
            price = info.amount * days;
            price_desc = info.amount.toLocaleString() + "/" + days +"박";
          }
          

          const data1 = {
            room_id: info.room_id,
            room_name: info.room_name,
            room_type_id : info.room_type_id,
            numAdult: counterB,
            numKid: counterC,
            sDate : sDate.current,
            eDate : eDate.current,
            price_desc : price_desc,
            price : price,
            num_days : days,
            bathroom : info.bathroom,
            bedroom : info.bedroom,
            living_room : info.living_room,
            private_bar :info.private_bar,
            etc_amenity : info.etc_amenity        
          
          };
          console.log("giral noddd " + counterB);
          console.log("giral no " + price_desc);

            return(
                

                <div className="reserve_wrap">
                <div className="roomGallery">
                    <h3 style={{ fontSize: '40px', 
                    textAlign:'center',position:'relative',lineHeight:'300px' }}>객실 대표 이미지</h3>
                </div>

                <div className="roomDetail">


                <p style={{ fontSize: '20px', 
                    position:'relative',width:"100%"}}>{info.room_name}</p>


                    <Link to="/rooms"><button style={{ borderRadius:'17px', marginTop:'10px',backgroundColor:"#998465",
                  border:"none",padding:"5px 10px",color:"white" }}>객실 상세 안내</button></Link>
                    <dl className="infos"> 
                        {/*<dt>객실 뷰</dt><dd>오션뷰</dd>*/}
                        <dt>객실 크기</dt><dd>56.88m2</dd>
                        <p>{data.room_number}</p>
                        <dt>기준 인원</dt><dd>{info.room_people}</dd>
                    
                    </dl>
                    <dl className="roomPay">
                        <dd>
                            <strong>
                            ₩ {price.toLocaleString()} ~ /{days}박
                            </strong>
                        </dd>
                        <p>1박, 1객실, 세금 및 봉사료 별도</p>

                    </dl>
                      
                    {/*<Link to={{pathname: `/reservation/choice/${id}`, state: { data } }}>*/}  
                    <Link to="/reservation/choice" state={ data1 }>
                      <button className="selBtn">선택하기</button>
                    </Link>
                    
                </div>
                </div>
            )
        }
    )
  

  const { beforeToday } = DateRangePicker;

  /*
  let [count, setCount] =useState(1);
  
  function incrementCount(){
    count+=1;
    setCount(count);
  }

  function decrementCount(){
    count-=1;
    if (count < 0){
      count=0;
      setCount(0);
      return
    }
    setCount(count);
  }*/

  const date = new Date();

  date.setDate(date.getDate() + 1);
  return (
    <div className="wrap" style={{flex:'1'}}>

      
    <ul className="stages">
    <li className="check"><span>1. 객실 선택</span></li>
    <li className="complete"><span>2. 추가 옵션 선택</span></li>
    <li className="complete"><span>3. 정보 입력/예약</span></li>
    </ul>
    
      <div className="calendar_wrapper" style={{top:'50px'}}>
        <div className="searchOptions">
          <div className="box1">

          <div className="selCntText">체크인    체크아웃</div>
          
          
          <DateRangePicker appearance="default" placeholder="Check in - Check out" value={[new Date(sDate.current), new Date(eDate.current)]} format='yyyy-MM-dd' block onChange={(date) =>{ onChange({ value: date })}} disabledDate={beforeToday()}
          locale={{
            sunday: "일",
            monday: "월",
            tuesday: "화",
            wednesday: "수",
            thursday: "목",
            friday: "금",
            saturday: "토",
            ok: "확인",
            today: "Today",
            January: "1월",
            yesterday: "Yesterday",
            last7Days: "Last 7 days",
            formattedDayPattern: 'yyyy년 M월 dd'
          }}/>

          {console.log("siba...." + sDate.current)}

          {/*
          
          <div className="chkInOut"><FontAwesomeIcon icon={faCalendarAlt} />Check in - <FontAwesomeIcon icon={faCalendarAlt} />Check out</div>
          
          <DateRangePicker plaintext initialSettings={{ drops : 'down', autoApply:true, showDropdowns: true,
          startDate:sDate,endDate:eDate,
          minDate:sDate,
          locale : {daysOfWeek: ["일","월","화","수","목","금","토"],
                  monthNames: ["1월","2월","3월","4월","5월","6월","7월","8월","9월","10월","11월","12월"],
                format:"YYYY/MM/DD",applyLabel:"확인", separator:"   -   " }
          }}>
            
            <input type="text" className="calendar_input" id="selectedDate"  name="selectedDate" ref={inputRef}/>
            
            
        </DateRangePicker>*/}
          


          

        
          
          </div>

          <div className="box2">
            <dl>
            <div className="selCntText">객실</div>
            <div className="roomCnt">
            <button onClick={() => { setCounterA(counterA - 1);}} disabled={counterA < 1}>-</button>
              <span>{counterA}</span>
            <button onClick={() => { setCounterA(counterA + 1);}} disabled={counterA > 1}>+</button>

            </div>
            

            </dl>
            <dl>
            <div className="selCntText">성인</div>
            <div className="">
            <button onClick={() => { setCounterB(counterB - 1);}} disabled={counterB < 2}>-</button>
              <span>{counterB}</span>
            <button onClick={() => { setCounterB(counterB + 1);}} disabled={counterB > 9}>+</button>
            </div>
            

            </dl>
            <dl>
            <div className="selCntText">어린이</div>
            <div className="">
            <button onClick={() => { setCounterC(counterC - 1);}} disabled={counterC < 1}>-</button>
              <span>{counterC}</span>
            <button onClick={() => { setCounterC(counterC + 1);}} disabled={counterC > 3}>+</button>
            </div>
            


            </dl>

         
          </div>
        </div>

          <button className="reserve_btn" onClick={handleClick}>검색</button>
          
      </div>

      

      <div className="roomArea" style={{ height:'100%', border:'2px solid #ddd',  margin:'0 auto',
    marginTop:'100px'}}>
        
        <div>{DisplayData}</div>

        <div className="defaultReserve" style={{textAlign:"center", padding:"200px 0"}}>예약을 원하시는 날짜와 인원을 선택해주세요.
        </div>


        {/*
        <div className="reserve_wrap">
        <div className="roomGallery">
            <h3 style={{ fontSize: '40px', 
            textAlign:'center',position:'relative',lineHeight:'300px' }}>객실 대표 이미지</h3>
        </div>
        
        <div className="roomDetail">


        <p style={{ fontSize: '20px', 
            position:'relative',width:"100%"}}>프리미엄룸</p>


            <Link to="/rooms"><button style={{ borderRadius:'17px', marginTop:'10px',backgroundColor:"#998465",
          border:"none",padding:"5px 10px",color:"white" }}>객실 상세 안내</button></Link>
            <dl className="infos"> 
                <dt>객실 뷰</dt><dd>오션뷰</dd>
                <dt>객실 크기</dt><dd>56.88m2</dd>
                <dt>기준 인원</dt><dd>6</dd>
            
            </dl>
            <dl className="roomPay">
                <dd>
                    <strong>
                    ₩ 150,000 ~ /1박
                    </strong>
                </dd>
                <p>1박, 1객실, 세금 및 봉사료 별도</p>
            </dl>
            
            <Link to="/reservation/choice"><button className="selBtn">선택하기</button></Link>
            
        </div>
      </div>*/}



    
      </div>
    </div>

    

      
  );
};
  
export default Reservation;