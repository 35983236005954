import TabNavItem from "../components/TabNavItem";
import TabContent from "../components/TabContent";
import '../components/Tabs.css';
import './css/attraction.css';

import attraction1 from './img/ihobeach1.jpg'
import dodubong1 from './img/dodubong1.jpg'
import dodubong2 from './img/dodubong2.jpg'
import dodubong3 from './img/dodubong3.jpg'
import dodubong4 from './img/dodubong4.jpg'
import dodubong5 from './img/dodubong5.jpg'


import valley from './img/valley.jpg'
import rock from './img/rock.jpg'

import beach1 from './img/beach1.png'
import beach2 from './img/beach2.png'
import beach3 from './img/beach3.png'
import beach4 from './img/beach4.png'
import beach5 from './img/beach5.png'
import beach6 from './img/beach6.png'

import marker from './img/marker.png'


import { Link, useParams } from "react-router-dom"
import React, { useState, useEffect, useRef } from 'react';
import dompurify from "dompurify";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const { kakao } = window;
  
const Attractions = () => {
  const [korean, setKor] = useState("false");
	const { t, i18n } = useTranslation();
	const sanitizer = dompurify.sanitize;
	const language = useRef(null);
	
	useEffect(()=>{
		language.current = sessionStorage.getItem('language');
	if (language===null){
		language.current = cookies.get('language');
	}
	i18next.changeLanguage(language.current);
	if (language.current ==="kr-KR"){
		setKor(true);
	  }else {
		setKor(false);
	  }
	},[]);

  const { index } = useParams();
  console.log(index);
  
  
    
  const [activeTab, setActiveTab] = useState(index);
  if (index === null){
    setActiveTab("beach");
    index="beach";
  }

  const headers = Array.from(document.getElementsByClassName(index));
        headers.map(element=>{
            element.style.background = "#e03267";
        })
  useEffect(()=>{
    var container;
    var options;
    var markerPosition;
    if (index === "beach"){
      container = document.getElementById('map');
      options = {
        center: new kakao.maps.LatLng(33.49887473648531, 126.45421165227772),
        level: 4
      };
      markerPosition  = new kakao.maps.LatLng(33.49887473648531, 126.45421165227772);
    }else if (index === "dodubong"){
      container = document.getElementById('map2');
      options = {
        center: new kakao.maps.LatLng(33.50798814633863, 126.4683474323059),
        level: 6
      };
      markerPosition  = new kakao.maps.LatLng(33.50798814633863, 126.4683474323059);
    }else if (index === "rock"){
      container = document.getElementById('map3');
      
      options = {
        center: new kakao.maps.LatLng(33.516126420336434, 126.51196240210844),
        level: 7
      };
      
      markerPosition  = new kakao.maps.LatLng(33.516126420336434, 126.51196240210844);
    }else {
      container = document.getElementById('map4');
      options = {
        center: new kakao.maps.LatLng(33.512764140434115, 126.51442180816117),
        level: 7
      };
      markerPosition  = new kakao.maps.LatLng(33.512764140434115, 126.51442180816117);

    }
  
  
   

    var map = new kakao.maps.Map(container, options);
    var marker = new kakao.maps.Marker({
      position: markerPosition
    });
    const imgSrc = require('./img/marker.png')

    var imageSrc = imgSrc, // 마커이미지의 주소입니다    
    imageSize = new kakao.maps.Size(45, 45); // 마커이미지의 크기입니다

    var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);
    var markerPosition2  = new kakao.maps.LatLng(33.49696129519766, 126.44865210992928);
    var marker2 = new kakao.maps.Marker({
      position: markerPosition2,
      image: markerImage, 
      text: '텍스트를 표시할 수 있어요!'
    });
    /*var infowindow = new kakao.maps.InfoWindow({
      content: '<div style="font-size:12px;background:none">이호MH호텔</div>',
    });

    infowindow.open(map, marker2);*/


   
  marker.setMap(map);
  marker2.setMap(map);
  }, [])

  return (
    <div className="wrap">
      <style>
      {`
        .Tabs ul.nav {
        width: 70%;
        max-width:unset;
        }
        .Tabs ul.nav a  {
          width: 23%;
          list-style: none;
          text-decoration:none;
          text-align: center;
          cursor: pointer;
          height:60px;
          display: flex;
          justify-content: center;
          align-items: center;
          color:black;
          transition: all 0.7s;
          
          
        }
.Tabs ul.nav li {  
  padding:0 1em;
  display: table-cell;
  vertical-align: middle;
  text-align:center;  
  
}
 
   
        .Tabs ul.nav a:first-of-type  {
          width: 31%;
          
          
        }
        @media (max-width: 1190px){
          .Tabs ul.nav a  {
            font-size:14px;
          }

        }
        @media (max-width: 700px){
          .Tabs ul.nav a  {
            font-size:12px;
          }
          .Tabs ul.nav {
            width: 100%;
            max-width:unset;
            }

        }
      `}
    </style>
    <h2 className = "headerTxt">ATTRACTIONS</h2>

      <div className="Tabs">
        <ul className="nav">
          <a className="beach" href="/attractions/beach"><TabNavItem title={i18next.t('tour1')} id="beach" activeTab={activeTab} /></a>
          <a className="dodubong" href ="/attractions/dodubong"><TabNavItem title={i18next.t('tour2')} id="dodubong" activeTab={activeTab} /></a>
          <a className="rock" href ="/attractions/rock"><TabNavItem title={i18next.t('tour3')} id="rock" activeTab={activeTab} /></a>
          <a className="valley" href ="/attractions/valley"><TabNavItem title={i18next.t('tour4')} id="valley" activeTab={activeTab} /></a>
        </ul>

          <div className="outlet">
              <TabContent id="beach" activeTab={activeTab}>
              <div className="attraction">

              <img src={attraction1} alt="이호테우해변"></img>
              </div>
              <div className="sub-info-tit">
                <div class="et_pb_text_title">{i18next.t('tour1')}</div>
              </div>
  <div className="tour_desc">
   <div>
   <span dangerouslySetInnerHTML={{ __html: sanitizer(t("desc1")) }}></span>

   </div>
  </div>

    <div class="gallery-wrapper">
    
      <div className="gallery_row">
        <div class="gallery-item-wrapper">
          <img src={beach1} alt="이호테우해변"/>
        </div>
      
    
        <div class="gallery-item-wrapper" data-animation-role="image">

        <img src={beach2} alt="이호테우해변"/>
        </div>

    
        <div class="gallery-item-wrapper" data-animation-role="image">
            
        <img src={beach3} alt="이호테우해변"/>

        </div>
      </div>
      <div className="gallery_row">
        <div class="gallery-item-wrapper" data-animation-role="image">

        <img src={beach4} alt="이호테우해변"/>

        </div>
      
    
        <div class="gallery-item-wrapper" data-animation-role="image">
        <img src={beach5} alt="이호테우해변"/>
        </div>
      
    
        <div class="gallery-item-wrapper" data-animation-role="image">
        <img src={beach6} alt="이호테우해변"/>

        </div>
      </div>
    
    </div>
              

              <div className="location">
              <div id="map"></div>
              
              <div style={{height:"50px", borderTop:"1px solid #5c5c5c", marginTop:"0", fontSize:"20px"}}>
              <button className="kakaoLogo">Kakao maps</button>
                <a href="http://kko.to/qpc-nxVmD" target="_blank" rel="noopener noreferrer"><button className="findWay">{i18next.t('location1')}</button></a>              

              </div>
              </div>
                 
      </TabContent>
      <TabContent id="dodubong" activeTab={activeTab}>
        <div className="attraction">
        <img src={dodubong1} alt="도두봉 키세스존"></img>
        <img src={dodubong2} alt="도두봉 키세스존" style={{marginTop:"2%"}}></img>
        <img src={dodubong3} alt="무지개 해안도로" style={{marginTop:"2%"}}></img>
        </div>
        <div className="sub-info-tit">
          <div class="et_pb_text_title">{i18next.t('tour2')}</div>
        </div>
              

            
  <div className="tour_desc">
  <div>
   <span dangerouslySetInnerHTML={{ __html: sanitizer(t("desc2")) }}></span>

   </div>
  </div>




              <div className="location">
              <div id="map2"></div>
              
              <div style={{height:"50px", borderTop:"1px solid #5c5c5c", marginTop:"0", fontSize:"20px"}}>
              <button className="kakaoLogo">Kakao maps</button>
                <a href="http://kko.to/7DIHfDjfW" target="_blank" rel="noopener noreferrer"><button className="findWay">{i18next.t('location1')}</button></a>              
              </div>
              </div>
              
    </TabContent>
    <TabContent id="rock" activeTab={activeTab}>


      <div className="attraction">
      <img src={rock} alt="용두암"></img>

      </div>
      <div className="sub-info-tit">
          <div class="et_pb_text_title">{i18next.t('tour3')}</div>
        </div>
        <div className="tour_desc">
        <div>
   <span dangerouslySetInnerHTML={{ __html: sanitizer(t("desc3")) }}></span>

   </div>

        </div>


              <div className="location">
              <div id="map3"></div>
              
              <div style={{height:"50px", borderTop:"1px solid #5c5c5c", marginTop:"0", fontSize:"20px"}}>
              <button className="kakaoLogo">Kakao maps</button>
                <a href="http://kko.to/pV2mEYBWQ" target="_blank" rel="noopener noreferrer"><button className="findWay">{i18next.t('location1')}</button></a>              
                </div>
              </div>

              
    </TabContent>
    <TabContent id="valley" activeTab={activeTab}>
      <div className="attraction">
      <img src={valley} alt="용연계곡"></img>

      </div>
      <div className="sub-info-tit">
          <div class="et_pb_text_title">{i18next.t('tour4')}</div>
        </div>
              <div className="tour_desc">
              <div>
   <span dangerouslySetInnerHTML={{ __html: sanitizer(t("desc4")) }}></span>

   </div>

          

              </div>


              <div className="location">
              <div id="map4"></div>
              
              <div style={{height:"50px", borderTop:"1px solid #5c5c5c", marginTop:"0", fontSize:"20px"}}>
              <button className="kakaoLogo">Kakao maps</button>
                <a href="http://kko.to/uXGOIvSXS" target="_blank" rel="noopener noreferrer">
                <button className="findWay">{i18next.t('location1')}</button>
                </a>
              </div>
              </div>

              
              </TabContent>
          
          </div>
      </div>

      </div>

    

      
  );
};
  
export default Attractions;