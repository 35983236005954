import { menuItems } from "./menus.js";
import { menuItems2 } from "./menus-en.js";
import { menuItems3 } from "./menus-jp.js";

import MenuItems from "./MenuItems";
import { MDBNavbar, MDBContainer, MDBCol, MDBRow,MDBIcon,MDBListGroupItem,MDBListGroup,MDBRipple,MDBDropdownMenu, MDBDropdown,MDBNavbarNav,MDBNavbarLink,MDBNavbarItem,MDBDropdownToggle } 
    from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css'

import './Navbar.css';

//import './Navbar.css';
import React,{useState, useEffect, useRef} from 'react';
import * as FaIcons from "react-icons/fa";
import { useNavigate } from "react-router-dom"
import { HashLink as Link } from 'react-router-hash-link';

import hotellogo from "./hotellogo.png";
import logo1 from "./logo1.png";
import logo2 from "./logo2.png";

import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Modal from './Modal.js';

import Cookies from 'universal-cookie';
import dompurify from "dompurify";
const cookies = new Cookies();

const Navbar = () => {
  const [sidebar,setSidebar]= useState(false);
  const [menus, setNavbar] =useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [show, setShow] = useState(false);
  const { t, i18n } = useTranslation();
  const [selectedLang, setLanguage] = useState();
  const [korean, setKor] = useState(false);
  const [mobile, isMobile] = useState(false);

  const [isShow, setIsShow] = useState(true);
  const sanitizer = dompurify.sanitize;
  const language = useRef(null);
  const navigate = useNavigate();

 

  const updateScroll = () => {
    setScrollPosition(window.scrollY || document.documentElement.scrollTop);
  }
  useEffect(()=>{
    language.current = sessionStorage.getItem('language');
    if (language.current===undefined || language.current===null){
      language.current = cookies.get('language');
      if (language.current===undefined || language.current===null){
        language.current ="kr-KR";
      }
    }
    console.log(language.current);
    
    i18next.changeLanguage(language.current);
    setLanguage(language.current);


    if (language.current ==="kr-KR"){
      setKor(true);
    }else {
      setKor(false);
    }
    if (language.current ==="jp-JP"){
      document.body.style.fontFamily="KosugiMaru-Regular"
    }else {
    }
    

  },[]);

  window.addEventListener('scroll', updateScroll);
  const showSidebar = ()=>{
    setSidebar(!sidebar);
    isMobile(!mobile);

    
      if (!sidebar){
        document.body.style.overflow="hidden";
        document.html.style.overflow="hidden";
        
      } else{
        document.body.style.overflow="revert";
        document.html.style.overflow="revert";
        
      }
  }
  const changeBackground = ()=>{
    if (window.scrollY > 100){
      setNavbar(true);
      setShow(true);
    }else{
      setNavbar(false);
      setShow(false);
    }
  }
    let isloggedIn = sessionStorage.getItem('isloggedIn');
    if (isloggedIn===null){
      isloggedIn = cookies.get('isloggedIn');
    }
    

    window.addEventListener('scroll', changeBackground);

    const logOut = () => {
      if (window.confirm("로그아웃 하시겠습니까?")){
        sessionStorage.clear();
        cookies.remove('isloggedIn');
        navigate('/'); // 메인 페이지로 이동
      }else{

      }
    };

    const onClickBtn = () => {
      setIsShow(false);
      if (isShow===true){
      const elements = Array.from(document.getElementsByClassName("main_visual"));
        elements.map(element=>{
            element.style.marginTop = "165px";
        })
        const headers = Array.from(document.getElementsByClassName("headerTxt"));
        headers.map(element=>{
            element.style.marginTop = "220px";
        })
      }else{
        const elements = Array.from(document.getElementsByClassName("main_visual"));
        elements.map(element=>{
            element.style.marginTop = "205px";
        })
        const headers = Array.from(document.getElementsByClassName("headerTxt"));
        headers.map(element=>{
            element.style.marginTop = "260px";
        })
      }
    };

    const onChangeHandler=(e)=>{
      var selected = e.currentTarget.value;
        sessionStorage.setItem('language', selected);
        cookies.set('language', selected, { path: '/', maxAge: 3600} );
        i18next.changeLanguage(selected);
 
        sessionStorage.setItem('language', selected);
        cookies.set('language', selected, { path: '/', maxAge: 3600} );
        i18next.changeLanguage(selected);
      
      window.location.reload();

    }
  
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
      setModalOpen(true);
    };
    const closeModal = () => {
      setModalOpen(false);
    };
  return (
    

    <nav>
      
      <div className="mobileNav-bar">
    
        <div className={scrollPosition < 90 ? "mobileNav" : "change_mobileNav"}>
          
        <a href="/" className="mobile_logo">
          { korean && <img src={hotellogo} alt="hotel logo" className="korLogo"></img> }
          { !korean && <img src={logo1} alt="hotel logo" className="engLogo"></img> }
        </a>
         
          <Link to="#" className='menu-bars'>
            {/*<FaIcons.FaBars onClick={showSidebar}/>*/}
            <svg onClick={showSidebar} clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" 
            stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="m13 16.745c0-.414-.336-.75-.75-.75h-9.5c-.414 0-.75.336-.75.75s.336.75.75.75h9.5c.414 0 .75-.336.75-.75zm9-5c0-.414-.336-.75-.75-.75h-18.5c-.414 0-.75.336-.75.75s.336.75.75.75h18.5c.414 0 .75-.336.75-.75zm-4-5c0-.414-.336-.75-.75-.75h-14.5c-.414 0-.75.336-.75.75s.336.75.75.75h14.5c.414 0 .75-.336.75-.75z" fill-rule="nonzero"/>
            </svg> 
          </Link>
          { isloggedIn && <div className="mobile_signout"><FontAwesomeIcon icon={faSignOut}  onClick={logOut}/></div>}
          
        </div>

        <div className={sidebar ? 'nav-menu active' :'nav-menu'}>
          
          <div className='menu-wrapper'>
            
            <ul>
           
          


            <li className ="nav-text"><span>{i18next.t('menu1')}</span>
            
            <ul className="sub-menu-mobile">
              <li>
                <a href="/overview">
                <span>{i18next.t('menu1-1')}</span>
                </a>
              </li>
           
              {/*<li>
                <a href="/location">
                <span>{i18next.t('menu1-2')}</span>
                </a>
  </li>*/}

            </ul>
            
            </li>
            

            <li className ="nav-text"><span>{i18next.t('menu2')}</span>
            <ul className="sub-menu-mobile">
                <li>
                  <a href="/rooms/premium">
                  <span>{i18next.t('menu2-1')}</span>
                  </a>
                </li>
                <li>
                  <a href="/rooms/family">
                  <span>{i18next.t('menu2-2')}</span>
                  </a>
                </li>

                <li>
                  <a href="/rooms/triple">
                  <span>{i18next.t('menu2-3')}</span>
                  </a>
                </li>
               
            </ul>
            
            </li>

            

            <li className ="nav-text"><span>{i18next.t('menu3')}</span>
            <ul className="sub-menu-mobile">
              <li>
                <a href="/attractions/beach">
                  <span>{i18next.t('menu3-1')}</span>
                </a>
              </li>
              <li>
                <a href="/attractions/dodubong">
                  <span>{i18next.t('menu3-2')}</span>
                </a>
              </li>
              <li>
                <a href="/attractions/rock">
                  <span>{i18next.t('menu3-3')}</span>
                </a>
              </li>
              <li>
                <a href="/attractions/valley">
                  <span>{i18next.t('menu3-4')}</span>
                </a>
              </li>
               
                
            </ul>
            
            </li>
            
            <li className ="nav-text"><span>{i18next.t('menu4')}</span>
            <ul className="sub-menu-mobile">
                <li>
                  <a href="/facilities/barbecue">
                  <span>{i18next.t('menu4-1')}</span>
                  </a>
                </li>
                <li>
                  <a href="/facilities/banquet">
                  <span>{i18next.t('menu4-2')}</span>
                  </a>
                </li>
            </ul>
            </li>
            <li className ="nav-text"><a href="/board"><span>{i18next.t('notice')}</span></a>
          
            </li>

            <div className="mobile_lang" style={{ width:"100%",
            position:"absolute", bottom:"90px"}}>
            <div className="mobile_sel"><select title="언어 선택" class="LanguageToggle-select ty-c1" value={selectedLang} onChange={onChangeHandler}>
              <option data-lang-code="kr" value="kr-KR">한국어</option>
              <option data-lang-code="en" value="en-US">English</option>
              <option data-lang-code="jp" value="jp-JP">日本語</option>
            </select>
            </div>
          </div>
          
          </ul>
            

        </div>
        </div>
      
      
      
      </div>


   
        {isShow &&<div className={show ? 'fade-out-box' : 'headerTop'}>
          <div className="headerTopContent">
            <p onClick={openModal}>{i18next.t('covid19')}</p> <div className="closeBtn" onClick={onClickBtn}> 닫기 X </div>
          </div>
        </div>}
        <Modal open={modalOpen} close={closeModal} header={i18next.t('covid19')}>
        <span dangerouslySetInnerHTML={{ __html: sanitizer(t("covid19_text")) }}></span>
      </Modal>
        <div className={show ? 'fade-out-box' : 'topNav'}>
          
          <div style={{display:"flex", justifyContent:"center"}}>
            <a href="/" style={{color:"black", textDecoration:"none", width:"300px", margin:"0 auto", border:"2px black"}}>
              <div className="hotelLogo">
                { korean && <img src={hotellogo} alt="hotel logo" className="korLogo"></img> }
                { !korean && <img src={logo1} alt="hotel logo" className="engLogo"></img> }
              </div>
            </a>
          </div>
          <div className="member_menu">
          { isloggedIn && <a href="#" className="" onClick={logOut}>로그아웃</a> }
          {/*<a href="/board" >{i18next.t('notice')}</a>*/}
					<a href="http://www.mhhealthcare.co.kr/" className="" target="_blank" rel="noreferrer">{i18next.t('mhhealthcare')}</a>
          <select title="언어 선택" class="LanguageToggle-select ty-c1" value={selectedLang} onChange={onChangeHandler}>
            <option data-lang-code="kr" value="kr-KR">한국어</option>
            <option data-lang-code="en" value="en-US">English</option>
            <option data-lang-code="jp" value="jp-JP">日本語</option>
            
          </select>
					</div>

        </div>
        {language.current ==="kr-KR" && (
          <ul className={menus ? 'menus active' : 'menus'}>
          {menuItems.map((menu, index) => {
            const depthLevel = 0;
            return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
          })}
          </ul>
          )
        }
        {language.current ==="en-US" && (
          <ul className={menus ? 'menus active' : 'menus'}>
          {menuItems2.map((menu, index) => {
            const depthLevel = 0;
            return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
          })}
          </ul>
          )
        }
        {language.current ==="jp-JP" && (
          <ul className={menus ? 'menus active' : 'menus'}>
          {menuItems3.map((menu, index) => {
            const depthLevel = 0;
            return <MenuItems items={menu} key={index} depthLevel={depthLevel} />;
          })}
          </ul>
          )
        }
    </nav>
  );
};

export default Navbar;