import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Reservation from './pages/Reservation';
import Choice from './pages/Choice';
import Input from './pages/Input';
import Confirm from './pages/Confirm';
import Login from './pages/Login';
import Check from './pages/Check';
import Signup from './pages/Signup';

import Admin from './pages/Admin';
import AdminLogin from './pages/AdminLogin';


import Test from './pages/Rooms2';
import Guide from './pages/Guide';

import Overview from './pages/Overview';
import Rooms2 from './pages/Rooms2';
import Rooms3 from './pages/Rooms3';
import Rooms from './pages/Rooms';

import Facilities from './pages/Facilities';
import Attractions from './pages/Attractions';
import Location from './pages/Location';

import Board from './pages/Board';
import Write from './pages/Write2';
import View from './pages/View';
import Update from './pages/Update';

import Header from './components/Navbar';
import Footer from './components/Footer';

import './App.css';
import { useEffect } from 'react';
const App = () => {

  
  /*const callApi = async () =>  {
    axios.get("/api").then((res)=> console.log(res.data.test));
  }

  useEffect(()=>{
    callApi();
  }, []);*/

  
  return (
    
    
    <Router>
      <Header/>
      <Routes>
        <Route path="/" element = {<Home/>} />
        <Route path="/reservation" element = {<Reservation/>} />
        <Route path="/reservation/choice" element = {<Choice/>} />
        <Route path="/reservation/input" element = {<Input/>} />
        <Route path="/confirm" element = {<Confirm/>} />
        
        
        <Route path="/check" element = {<Check/>} />
        <Route path="/test" element = {<Test/>} />
        <Route path="/overview" element = {<Overview/>} />
        <Route path="/guide" element = {<Guide/>} />

        <Route path="/rooms/triple" element = {<Rooms/>} />

        <Route path="/rooms/premium" element = {<Rooms2/>} />
        <Route path="/rooms/family" element = {<Rooms3/>} />

        <Route path="/facilities/:index" element = {<Facilities/>} />
        <Route path="/attractions/:index" element = {<Attractions/>} />
        <Route path="/location" element = {<Location/>} />

        <Route path="/admin" element = {<Admin/>} />
        <Route path="/login" element = {<AdminLogin/>} />

        <Route path="/board" element = {<Board/>} />
        <Route path="/postView/:no" element = {<View/>} />
        <Route path="/write" element = {<Write/>} />
        <Route path="/update/:id" element = {<Update/>} />

        <Route path="/signup" element = {<Signup/>} />

      </Routes>
      <Footer/>
    </Router>
  
  );
};

export default App;
