
import './css/rooms.css';
import './css/roominfo.css';

import mhhotel1 from '././img/premium/mhhotel1.jpg'
import mhhotel2 from '././img/premium/mhhotel2.jpg'
import mhhotel3 from '././img/premium/mhhotel3.jpg'
import mhhotel4 from '././img/premium/mhhotel4.jpg'
import mhhotel5 from '././img/premium/mhhotel5.jpg'
import mhhotel6 from '././img/premium/mhhotel6.jpg'
import mhhotel7 from '././img/premium/mhhotel7.jpg'

import ondolroom from '././img/premium/ondolroom.jpg'

import bedroom1 from '././img/premium/amenities/bedroom1.jpg'
import bedroom2 from '././img/premium/amenities/bedroom2.jpg'
import bedroom3 from '././img/premium/amenities/bedroom3.jpg'

import livingroom1 from '././img/premium/amenities/livingroom1.jpg'
import livingroom2 from '././img/premium/amenities/livingroom2.jpg'

import kitchen1 from '././img/premium/amenities/kitchen1.jpg'
import kitchen2 from '././img/premium/amenities/kitchen2.jpg'
import kitchen3 from '././img/premium/amenities/kitchen3.jpg'
import kitchen4 from '././img/premium/amenities/kitchen4.jpg'
import kitchen5 from '././img/premium/amenities/kitchen5.jpg'

import TabNavItem from "../components/TabNavItem";
import TabContent from "../components/TabContent";
import '../components/Tabs2.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ImageGallery from 'react-image-gallery';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import React, { useState, useEffect, useRef } from 'react';
import dompurify from "dompurify";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();


const Rooms2 = () => {
	const [activeTab, setActiveTab] = useState("premium");
	const [activeAmenity, setAmenity] = useState("tab1");

	const [korean, setKor] = useState("false");
	const { t, i18n } = useTranslation();
	const sanitizer = dompurify.sanitize;
	const language = useRef(null);
	
	useEffect(()=>{
		language.current = sessionStorage.getItem('language');

	if (language.current===undefined || language.current===null){
		language.current = cookies.get('language');
		if (language.current===undefined || language.current===null){
		  language.current ="kr-KR";
		}
	}
	console.log(language.current);
	i18next.changeLanguage(language.current);
	if (language.current ==="kr-KR"){
		setKor(true);
		
	  }else {
		setKor(false);
	  }
	},[]);
	const images = [
        {
          original: mhhotel1,
          thumbnail: mhhotel1,
        },
        {
          original: mhhotel2,
          thumbnail: mhhotel2,
        },
        {
          original: mhhotel3,
          thumbnail: mhhotel3,
        },
		{
			original: mhhotel4,
			thumbnail: mhhotel4,
		},
		{
			original: mhhotel5,
			thumbnail: mhhotel5,
		},
		{
			original: mhhotel6,
			thumbnail: mhhotel6,
		},
		{
			original: mhhotel7,
			thumbnail: mhhotel7,
		},
    ];


	const bedroom = [
        {
          original: bedroom1,
          thumbnail: bedroom1,
        },
        {
          original: bedroom2,
          thumbnail: bedroom2,
        },
        {
          original: bedroom3,
          thumbnail: bedroom3,
        }
    ];

	const livingRoom = [
        {
          original: livingroom1,
          thumbnail: livingroom1,
        },
        {
          original: livingroom2,
          thumbnail: livingroom2,
        }
    ];


	const kitchen = [
        {
          original: kitchen1,
          thumbnail: kitchen1,
        },
        {
          original: kitchen2,
          thumbnail: kitchen2,
        },
        {
          original: kitchen3,
          thumbnail: kitchen3,
        },
        {
          original: kitchen4,
          thumbnail: kitchen4,
        },
        {
          original: kitchen5,
          thumbnail: kitchen5,
        }
    ];


	function reveal() {
		var reveals = document.querySelectorAll(".pp-icon-list-item");
		for (var i = 0; i < reveals.length; i++) {
		  var windowHeight = window.innerHeight;
		  var elementTop = reveals[i].getBoundingClientRect().top;
		  var elementVisible = 200;
		  if (elementTop < windowHeight - elementVisible) {
			reveals[i].classList.add("active");
		  } else {
			reveals[i].classList.remove("active");
		  }
		}
	}
	window.addEventListener("scroll", reveal);
	
	// To check the scroll position on page load
	reveal();
	
    return (
        <div className="wrap">
        <style>
	{`
	.Tabs2{
		display:none;
	}
	  .swiper {
		width: 100%;
		height: 100%;
	  }
	  
	  .swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
	  
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	  }
	  
	  .swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	  }
	  .sub-list-wrap{
		width:50%;
		margin:400px auto;
		padding:20px 50px;
	  }
	  .swiper{display:none;}
	  
	  @media (max-width: 1450px) { 
		.swiper{display:none;}
	
	  }
	  .Tabs2 ul.nav li {
		width: 25%;
	  }
	  .Tabs2{
		width:100%;
		margin-top:0px;
	  }
	.Tabs {
		width: 100% !important;
		max-width: unset !important;
		height: auto !important;
		margin: 50px auto 0px auto!important;
		padding: 0 !important;
		
		
	}
	.TabContent {
		font-size: unset;
		
	}
	.Tabs ul.nav li  {
		width: 100%;
		list-style: none;
		text-align: center;
		cursor: pointer;
		transition: all 0.7s;
		
	  }
	  .Tabs ul.nav a  {
		width: 33.3333%;
		list-style: none;
		text-decoration:none;
		text-align: center;
		cursor: pointer;
		color:black;
		font-family: 'SpoqaHanSansNeo-Regular';

		transition: all 0.7s;
	  }
	  .Tabs2{
		width:95%;
		margin:0 auto;
	  }
	  .Tabs2 ul.nav li{
		width:20%;
		
	  }
	  .Tabs2 ul.nav{
		margin: 0;
		max-width:unset;
	  }
	  .Tabs2 ul.nav li.active {
		background: #fff;
		border-top: 1px solid black;
		color: #000;
	}
	.Tabs2 ul.nav li.active {
		border-color: #373737;
		border-bottom-color: #fff;
		border-left:1px solid black;
		z-index: 1;
	}
	.Tabs2 ul.nav li {
		font-size: 10px;
		padding:0;
		border-left:none;
	}

	@media (max-width: 860px) {
		.Tabs2{
			display:block;
		}
	}

	`}
  </style>

  <h2 className = "headerTxt">PREMIUM ROOM</h2>

  <div className="Tabs">
  <ul className="nav" style={{maxWidth:"none"}}>
        <a href="/rooms/premium"><TabNavItem title="PREMIUM DOUBLE" id="premium" activeTab={activeTab} setActiveTab={setActiveTab}/></a>
        <a href="/rooms/triple"><TabNavItem title="PREMIUM TRIPLE" id="triple" activeTab={activeTab} setActiveTab={setActiveTab}/></a>

        <a href="/rooms/family"><TabNavItem title="FAMILY" id="banquet" activeTab={activeTab} setActiveTab={setActiveTab}/></a>
    </ul>

    
                    
	<TabContent id="premium" activeTab={activeTab}>

	<div className="outlet" style={{marginTop:"50px"}}>
		<Swiper
        slidesPerView={3}
        spaceBetween={5}
		autoplay={{
			delay: 2500,
			disableOnInteraction: false,
		  }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
		navigation={true}
		loop={true}
        className="mySwiper"
      >
        <SwiperSlide><img src={mhhotel1} alt=""/></SwiperSlide>
        <SwiperSlide><img src={mhhotel2} alt=""/></SwiperSlide>
		<SwiperSlide><img src={mhhotel3} alt=""/></SwiperSlide>
        <SwiperSlide><img src={mhhotel4} alt=""/></SwiperSlide>
		<SwiperSlide><img src={mhhotel5} alt=""/></SwiperSlide>
      </Swiper>

	  <ImageGallery items={images} showBullets={true} showThumbnails={false} showPlayButton={false}/>

    <div className="roomInfoWrap">

	<h2 className="roomHeader">{i18next.t('roomOverview')}</h2>




	<div className="roomInfo">

<ul>
	<li>
		<dl>
			<dt>{i18next.t('bedType')}</dt>
			<dd>{i18next.t('premium1')}</dd>
		</dl>
	</li>
	<li>
		<dl>
			<dt>{i18next.t('roomStruct')}</dt>
			<dd>{i18next.t('premium2')}</dd>
		</dl>
	</li>
	<li>
		<dl>
			<dt>{i18next.t('roomSize')}</dt>
			<dd>{korean &&<span>25평 (82.6m<sup>2</sup>)</span>}
			{!korean &&<span>82.6m<sup>2</sup></span>}
			
			</dd>
		</dl>
	</li>
	<li>
		<dl>
			<dt>{i18next.t('occupancy')}</dt>
			<dd>{i18next.t('premium3')}</dd>
		</dl>
	</li>
	<li>
		<dl>
			<dt>{i18next.t('view')}</dt>
			<dd>{i18next.t('view2')}</dd>
		</dl>
	</li>
	<li>
		<dl>
			<dt>{i18next.t('checkin')}</dt>
			<dd>15:00 / 11:00</dd>
		</dl>
	</li>
</ul>
<div class="cl"></div>
</div>

<h2 class="roomHeader">{i18next.t('amenities')}</h2>
<div className="Tabs2">
    <ul className="nav">
        <TabNavItem title="BEDROOM" id="tab1" activeTab={activeAmenity} setActiveTab={setAmenity}/>
        <TabNavItem title="LIVING ROOM" id="tab2" activeTab={activeAmenity} setActiveTab={setAmenity}/>
        <TabNavItem title="PRIVATE BAR" id="tab3" activeTab={activeAmenity} setActiveTab={setAmenity}/>
        <TabNavItem title="BATHROOM" id="tab4" activeTab={activeAmenity} setActiveTab={setAmenity}/>
		<TabNavItem title="ONDOL" id="tab5" activeTab={activeAmenity} setActiveTab={setAmenity}/>
     </ul>
 
    <div className="outlet">
        <TabContent id="tab1" activeTab={activeAmenity}>
        	<div className="cm-tab-con roomAmenity" id="tabContent01" >
								
			<ul>
			<li>{i18next.t('pBedroom1')}</li>
			<li>{i18next.t('pBedroom2')}</li>
			<li>{i18next.t('pBedroom3')}</li>
		
			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>
        <TabContent id="tab2" activeTab={activeAmenity}>

            <div className="cm-tab-con roomAmenity" id="tabContent02" >
								
			<ul>
			<li>{i18next.t('pLiving1')}</li>
			<li>{i18next.t('pLiving2')}</li>
			<li>{i18next.t('pLiving3')}</li>
			
		
			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>

        <TabContent id="tab3" activeTab={activeAmenity}>
            <div className="cm-tab-con roomAmenity" id="tabContent03" >
								
			<ul>
			<li>{i18next.t('pKitchen1')}</li>
			<li>{i18next.t('pKitchen2')}</li>
			<li>{i18next.t('pKitchen3')}</li>
			<li>{i18next.t('pKitchen4')}</li>
			<li>{i18next.t('pKitchen5')}</li>
			
			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>
		<TabContent id="tab4" activeTab={activeAmenity}>
            <div className="cm-tab-con roomAmenity" id="tabContent04" >
								
			<ul>
			<li>{i18next.t('pBathroom1')}</li>
			<li>{i18next.t('pBathroom2')}</li>
			<li>{i18next.t('pBathroom3')}</li>
			<li>{i18next.t('pBathroom4')}</li>
			<li>{i18next.t('pBathroom5')}</li>
			<li>{i18next.t('pBathroom6')}</li>
			<li>{i18next.t('pBathroom7')}</li>

			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>

		<TabContent id="tab5" activeTab={activeAmenity}>
            <div className="cm-tab-con roomAmenity" id="tabContent05" >
								
			<ul>
			<li>{i18next.t('pOndol1')}</li>
			

			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>
    </div>
</div>
<div className="amenities">
	<div class="amenitiesList">
	<div className="amenitiesImg">
		{/*<img src={mhhotel3} alt="room1"></img>*/}
		<ImageGallery className="amenitiesGallery" items={bedroom} autoPlay={true} showBullets={true} showThumbnails={false} showPlayButton={false}/>
	
	</div>

	<div class="fl-col">
	<div class="fl-col-content fl-node-content">
	<div class="fl-module fl-module-heading">
	<div class="fl-module-content fl-node-content">
		<h3 class="fl-heading">
		<span class="fl-heading-text">Bedroom</span>
	</h3>
	</div>
	</div>
	<div class="fl-module fl-module-pp-iconlist fl-node-5d55d24e45afd list-text animate active" data-node="5d55d24e45afd">
	<div class="fl-module-content fl-node-content">
		<div class="pp-icon-list pp-user-agent-chrome">
			<ul class="pp-icon-list-items pp-list-type-icon">
	
		
			<li class="pp-icon-list-item pp-icon-list-item-0">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pBedroom1')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-1">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pBedroom2')}</span>
			</li>

		
	

		
			<li class="pp-icon-list-item pp-icon-list-item-3">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pBedroom3')}</span>
			</li>


		
			</ul>
		</div>
	</div>
	</div>

	</div>
	</div>

	</div>   

	<div class="amenitiesList">
	<div className="amenitiesImg">
		{/*<img src={mhhotel1} alt="room3"></img>*/}
		<ImageGallery className="amenitiesGallery" items={livingRoom} autoPlay={true} showBullets={true} showThumbnails={false} showPlayButton={false}/>

	</div>
	<div class="fl-col">
	<div class="fl-col-content fl-node-content">
	<div class="fl-module fl-module-heading">
	<div class="fl-module-content fl-node-content">
		<h3 class="fl-heading">
		<span class="fl-heading-text">Living Room</span>
	</h3>
	</div>
	</div>
	<div class="fl-module fl-module-pp-iconlist fl-node-5d55d24e45afd list-text animate active" data-node="5d55d24e45afd">
	<div class="fl-module-content fl-node-content">
		<div class="pp-icon-list pp-user-agent-chrome">
			<ul class="pp-icon-list-items pp-list-type-icon">
	
		
			<li class="pp-icon-list-item pp-icon-list-item-0 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pLiving1')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-1 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pLiving2')}</span>
			</li>
			<li class="pp-icon-list-item pp-icon-list-item-2 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pLiving3')}</span>
			</li>
		
		
			</ul>
		</div>
	</div>
	</div>

	</div>
	</div>	
	
	</div>  

	<div class="amenitiesList">
	<div className="amenitiesImg">
		{/*<img src={mhhotel4} alt="room1"></img>*/}
		<ImageGallery className="amenitiesGallery" items={kitchen} autoPlay={true} showBullets={true} showThumbnails={false} showPlayButton={false}/>

	</div>

	<div class="fl-col">
	<div class="fl-col-content fl-node-content">
	<div class="fl-module fl-module-heading">
	<div class="fl-module-content fl-node-content">
		<h3 class="fl-heading">
		<span class="fl-heading-text">Private Bar</span>
	</h3>
	</div>
	</div>
	<div class="fl-module fl-module-pp-iconlist fl-node-5d55d24e45afd list-text animate active" data-node="5d55d24e45afd">
	<div class="fl-module-content fl-node-content">
		<div class="pp-icon-list pp-user-agent-chrome">
			<ul class="pp-icon-list-items pp-list-type-icon">
		
			<li class="pp-icon-list-item pp-icon-list-item-0 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pKitchen1')}</span>
			</li>
			<li class="pp-icon-list-item pp-icon-list-item-1 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pKitchen2')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-1 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pKitchen3')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-2 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pKitchen4')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pKitchen5')}</span>
			</li>

			



		
			</ul>
		</div>
	</div>
	</div>

	</div>
	</div>
	</div>   

	<div class="amenitiesList">
	<div className="amenitiesImg"><img src={mhhotel5} alt="room1" className="noSlideImg"></img></div>

	<div class="fl-col">
	<div class="fl-col-content fl-node-content">
	<div class="fl-module fl-module-heading">
	<div class="fl-module-content fl-node-content">
		<h3 class="fl-heading">
		<span class="fl-heading-text">Bathroom</span>
	</h3>
	</div>
	</div>
	<div class="fl-module fl-module-pp-iconlist fl-node-5d55d24e45afd list-text animate active" data-node="5d55d24e45afd">
	<div class="fl-module-content fl-node-content">
		<div class="pp-icon-list pp-user-agent-chrome">
			<ul class="pp-icon-list-items pp-list-type-icon">
	
			<li class="pp-icon-list-item pp-icon-list-item-0 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pBathroom1')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-1 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pBathroom2')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-2 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pBathroom3')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pBathroom4')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pBathroom5')}</span>
			</li>
			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pBathroom6')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pBathroom7')}</span>
			</li>


		
			</ul>
		</div>
	</div>
	</div>

	</div>
	</div>
	</div>  

	<div class="amenitiesList">
	<div className="amenitiesImg"><img src={ondolroom} alt="room1" className="noSlideImg"></img></div>

	<div class="fl-col">
	<div class="fl-col-content fl-node-content">
	<div class="fl-module fl-module-heading">
	<div class="fl-module-content fl-node-content">
		<h3 class="fl-heading">
		<span class="fl-heading-text">Ondol Room</span>
	</h3>
	</div>
	</div>
	<div class="fl-module fl-module-pp-iconlist fl-node-5d55d24e45afd list-text animate active" data-node="5d55d24e45afd">
	<div class="fl-module-content fl-node-content">
		<div class="pp-icon-list pp-user-agent-chrome">
			<ul class="pp-icon-list-items pp-list-type-icon">
	
			<li class="pp-icon-list-item pp-icon-list-item-0 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('pOndol1')}</span>
			</li>


		

		
			</ul>
		</div>
	</div>
	</div>

	</div>
	</div>
	</div>  

</div>

{/*<div className="Tabs2">
    <ul className="nav">
        <TabNavItem title="BEDROOM" id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabNavItem title="LIVING ROOM" id="tab2" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabNavItem title="PRIVATE BAR" id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
        <TabNavItem title="BATHROOM" id="tab4" activeTab={activeTab} setActiveTab={setActiveTab}/>
     </ul>
 
    <div className="outlet">
        <TabContent id="tab1" activeTab={activeTab}>
        	<div className="cm-tab-con roomAmenity" id="tabContent01" >
								
			<ul>
			<li>씰리프리미엄침대 (1,600mm x 2,000mm )</li>
			<li>3단 붙박이장</li>
			<li>협탁</li>
			<li>취침등</li>
		
			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>
        <TabContent id="tab2" activeTab={activeTab}>

            <div className="cm-tab-con roomAmenity" id="tabContent02" >
								
			<ul>
			<li>스마트TV (55인치)</li>
			<li>개별 냉∙난방에어컨</li>
			<li>거실장</li>
			<li>거울(벽걸이)</li>
			<li>구두주걱</li>
			<li>멀티충전기</li>
		
			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>

        <TabContent id="tab3" activeTab={activeTab}>
            <div className="cm-tab-con roomAmenity" id="tabContent03" >
								
			<ul>
			<li>아일랜드 식탁(4인용)</li>
			<li>냉장고 모던 레트로코스텔</li>
			<li>전자레인지 레트로오델로</li>
			<li>살균소독기</li>
			<li>전기주전자</li>
			<li>싱크대</li>
			<li>소주잔</li>
			<li>맥주잔</li>
			<li>생수</li>
			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>
		<TabContent id="tab4" activeTab={activeTab}>
            <div className="cm-tab-con roomAmenity" id="tabContent04" >
								
			<ul>
			<li>페이스타월</li>
			<li>샴푸&린스</li>
			<li>바디워시</li>
			<li>물비누</li>
			<li>브러쉬</li>
			<li>헤어드라이어</li>
			<li>원격조정비데일체형 변기</li>

			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>
    </div>
</div>*/}

<div className="roomNotice">
<h2 class="roomHeader">{i18next.t('guideline')}</h2>
	<div className="">
	<h3>입실 안내 Check-in</h3>
	<table>
		<tr>
			<th>
				구분
			</th>
			<th>
				입실시간
			</th>
		</tr>
		<tr>
			<td>
				성수기
			</td>
			<td>16:00 ~ 22:00</td>
		</tr>

		<tr>
			<td>
				평일/주말/연휴
			</td>
			<td>16:00 ~ 22:00</td>
		</tr>
	</table>

	<h3>퇴실 안내 Check-out</h3>
	<table>
		<tr>
			<th>
				구분
			</th>
			<th>
				입실시간
			</th>
		</tr>
		<tr>
			<td>
				성수기
			</td>
			<td>~ 11:00</td>
		</tr>

		<tr>
			<td>
				평일/주말/연휴
			</td>
			<td>~ 11:00</td>
		</tr>
	</table>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline1")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline2")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline3")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline4")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline5")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline6")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline7")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline8")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline9")) }}></p>

	</div>


	<h2 class="roomHeader">{i18next.t('cancellation')}</h2>

	<table>
		<tr>
			<th>
				체크인 기준
			</th>
			<th>
				환불수수료
			</th>
		</tr>
		<tr>
			<td>
				6일전
			</td>
			<td>100% 환불</td>
		</tr>

		<tr>
			<td>
				5일전
			</td>
			<td>80% 환불</td>
		</tr>

		<tr>
			<td>
				4일전
			</td>
			<td>60% 환불</td>
		</tr>

		<tr>
			<td>
				3일전
			</td>
			<td>40% 환불</td>
		</tr>

		<tr>
			<td>
				2일전
			</td>
			<td>20% 환불</td>
		</tr>

		<tr>
			<td>
				1일전, 당일
			</td>
			<td>환불불가</td>
		</tr>




	</table>

	<p>객실이용 : </p>
	<p>케이블TV- 163개 채널</p>
	<p>유무선 인터넷(wi-fi)</p>

</div>

</div>			


			
		
              
        </div>
		</TabContent>
	</div>
</div>

    

      
  );
};
  
export default Rooms2;