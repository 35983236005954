


import family1 from '././img/family/family1.jpg'
import family2 from '././img/family/family2.jpg'
import family3 from '././img/family/family3.jpg'
import family4 from '././img/family/family4.jpg'
import family5 from '././img/family/family5.jpg'
import family6 from '././img/family/family6.jpg'
import family7 from '././img/family/family7.jpg'
import family8 from '././img/family/family8.jpg'

import room2 from '././img/family/room2.jpg'
import room4 from '././img/family/room4.jpg'

import bedroom1 from '././img/family/amenities/bedroom1.jpg'
import bedroom2 from '././img/family/amenities/bedroom2.jpg'

import livingroom1 from '././img/family/amenities/livingroom1.jpg'
import livingroom2 from '././img/family/amenities/livingroom2.jpg'
import livingroom3 from '././img/family/amenities/livingroom3.jpg'

import kitchen1 from '././img/family/amenities/kitchen1.jpg'
import kitchen2 from '././img/family/amenities/kitchen2.jpg'
import kitchen3 from '././img/family/amenities/kitchen3.jpg'
import kitchen4 from '././img/family/amenities/kitchen4.jpg'

import ondol1 from '././img/family/amenities/ondol1.jpg'
import ondol2 from '././img/family/amenities/ondol2.jpg'

import TabNavItem from "../components/TabNavItem";
import TabContent from "../components/TabContent";
import '../components/Tabs2.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import ImageGallery from 'react-image-gallery';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper";
import React, { useState, useEffect, useRef } from 'react';
import dompurify from "dompurify";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();



const Rooms3 = () => {
	const [activeTab, setActiveTab] = useState("family");
	const [activeAmenity, setAmenity] = useState("tab1");

	const [korean, setKor] = useState("false");
	const { t, i18n } = useTranslation();
	const sanitizer = dompurify.sanitize;
	const language = useRef(null);
	
	useEffect(()=>{
		language.current = sessionStorage.getItem('language');
		if (language.current===undefined || language.current===null){
			language.current = cookies.get('language');
			if (language.current===undefined || language.current===null){
			  language.current ="kr-KR";
			}
		}
	i18next.changeLanguage(language.current);
	if (language.current ==="kr-KR"){
		setKor(true);
	  }else {
		setKor(false);
	  }
	},[]);

	const images = [
        {
          original: family1,
          thumbnail: family1,
        },
        {
          original: family2,
          thumbnail: family2,
        },
        {
          original: family3,
          thumbnail: family3,
        },
		{
			original: family4,
			thumbnail: family4,
		},
		{
			original: family5,
			thumbnail: family5,
		},
		{
			original: family6,
			thumbnail: family6,
		},
		{
			original: family7,
			thumbnail: family7,
		},
		{
			original: family8,
			thumbnail: family8,
		}
	
    ];

	const bedroom = [
        {
          original: bedroom1,
          thumbnail: bedroom1,
        },
        {
          original: bedroom2,
          thumbnail: bedroom2,
        }
	
    ];

	const livingRoom = [
        {
          original: livingroom1,
          thumbnail: livingroom1,
        },
        {
          original: livingroom2,
          thumbnail: livingroom2,
        },
		{
		  original: livingroom3,
		  thumbnail: livingroom3,
		}
	
    ];
	const kitchen = [
        {
          original: kitchen1,
          thumbnail: kitchen1,
        },
        {
          original: kitchen2,
          thumbnail: kitchen2,
        },
		{
		  original: kitchen3,
		  thumbnail: kitchen3,
		},
		{
		  original: kitchen4,
		  thumbnail: kitchen4,
		}
	
    ];

	const ondol = [
        {
          original: ondol1,
          thumbnail: ondol1,
        },
        {
          original: ondol2,
          thumbnail: ondol2,
        }
	
    ];
	function reveal() {
		var reveals = document.querySelectorAll(".pp-icon-list-item");
		for (var i = 0; i < reveals.length; i++) {
		  var windowHeight = window.innerHeight;
		  var elementTop = reveals[i].getBoundingClientRect().top;
		  var elementVisible = 200;
		  if (elementTop < windowHeight - elementVisible) {
			reveals[i].classList.add("active");
		  } else {
			reveals[i].classList.remove("active");
		  }
		}
	}
	window.addEventListener("scroll", reveal);
	
	// To check the scroll position on page load
	reveal();

	
    return (
        <div className="wrap">
    <style>
	{`
	.Tabs2{
		display:none;
	}
	  .swiper {
		width: 100%;
		height: 100%;
	  }
	  
	  .swiper-slide {
		text-align: center;
		font-size: 18px;
		background: #fff;
	  
		/* Center slide text vertically */
		display: -webkit-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		-webkit-align-items: center;
		align-items: center;
	  }
	  
	  .swiper-slide img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	  }
	  .sub-list-wrap{
		width:50%;
		margin:400px auto;
		padding:20px 50px;
	  }
	  .swiper{display:none;}
	  
	  @media (max-width: 1450px) { 
		.swiper{display:none;}
	
	  }
	  .Tabs2 ul.nav li {
		width: 25%;
	  }
	  .Tabs2{
		width:100%;
		margin-top:0px;
	  }
	  .Tabs {
		width: 100% !important;
		max-width: unset !important;
		height: auto !important;
		margin: 50px auto 0px auto!important;
		padding: 0 !important;
		
		
	}
	.TabContent {
		font-size: unset;
		
	}
	.Tabs ul.nav li  {
		width: 100%;
		list-style: none;
		text-align: center;
		cursor: pointer;
		transition: all 0.7s;
		
	  }
	  .Tabs ul.nav a  {
		width: 33.3333%;
		list-style: none;
		text-decoration:none;
		text-align: center;
		cursor: pointer;
		color:black;
		transition: all 0.7s;
		font-family: 'SpoqaHanSansNeo-Regular';

	  }
	  .Tabs2{
		width:95%;
		margin:0 auto;
	  }
	  .Tabs2 ul.nav li{
		width:20%;
	  }
	  .Tabs2 ul.nav{
		margin: 0;
		max-width:unset;
	  }
	  .Tabs2 ul.nav li.active {
		background: #fff;
		border-top: 1px solid black;
		color: #000;
	}
	.Tabs2 ul.nav li.active {
		border-color: #373737;
		border-bottom-color: #fff;
		border-left:1px solid black;
		z-index: 1;
	}
	.Tabs2 ul.nav li {
		font-size: 11px;
		padding:0;
		border-left:none;
	}
	@media (max-width: 860px) {
		.Tabs2{
			display:block;
		}
	}
	`}
  </style>
  <h2 className = "headerTxt">FAMILY ROOM</h2>
  <div className="Tabs">
  <ul className="nav" style={{maxWidth:"none"}}>
        <a href="/rooms/premium"><TabNavItem title="PREMIUM DOUBLE" id="premium" activeTab={activeTab} setActiveTab={setActiveTab}/></a>
        <a href="/rooms/triple"><TabNavItem title="PREMIUM TRIPLE" id="triple" activeTab={activeTab} setActiveTab={setActiveTab}/></a>

        <a href="/rooms/family"><TabNavItem title="FAMILY" id="banquet" activeTab={activeTab} setActiveTab={setActiveTab}/></a>
    </ul>

	<TabContent id="family" activeTab={activeTab}>
	<div className="outlet" style={{marginTop:"50px"}}>
                    



	<ImageGallery items={images} showBullets={true} showThumbnails={false} showPlayButton={false}/>

    <div className="roomInfoWrap">

	<h2 className="roomHeader">{i18next.t('roomOverview')}</h2>
	<div className="roomInfo">

<ul>
	<li>
		<dl>
			<dt>{i18next.t('bedType')}</dt>
			<dd>{i18next.t('family1')}</dd>
		</dl>
	</li>
	<li>
		<dl>
			<dt>{i18next.t('roomStruct')}</dt>
			<dd>{i18next.t('family2')}</dd>
		</dl>
	</li>
	<li>
		<dl>
			<dt>{i18next.t('roomSize')}</dt>
			<dd>{korean &&<span>45평 (148.7m<sup>2</sup>)</span>}
			{!korean &&<span>148.7m<sup>2</sup></span>}
			</dd>
		</dl>
	</li>
	<li>
		<dl>
			<dt>{i18next.t('occupancy')}</dt>
			<dd>{i18next.t('family3')}</dd>
		</dl>
	</li>
	<li>
		<dl>
			<dt>{i18next.t('view')}</dt>
			<dd>{i18next.t('view2')}</dd>
		</dl>
	</li>
	<li>
		<dl>
			<dt>{i18next.t('checkin')}</dt>
			<dd>15:00 / 11:00</dd>
		</dl>
	</li>
</ul>
<div class="cl"></div>
</div>

<h2 class="roomHeader">{i18next.t('amenities')}</h2>
<div className="Tabs2">
    <ul className="nav">
        <TabNavItem title="BEDROOM" id="tab1" activeTab={activeAmenity} setActiveTab={setAmenity}/>
        <TabNavItem title="LIVING ROOM" id="tab2" activeTab={activeAmenity} setActiveTab={setAmenity}/>
        <TabNavItem title="PRIVATE BAR" id="tab3" activeTab={activeAmenity} setActiveTab={setAmenity}/>
        <TabNavItem title="BATHROOM" id="tab4" activeTab={activeAmenity} setActiveTab={setAmenity}/>
        <TabNavItem title="ONDOL" id="tab5" activeTab={activeAmenity} setActiveTab={setAmenity}/>
     </ul>
 
    <div className="outlet">
        <TabContent id="tab1" activeTab={activeAmenity}>
        	<div className="cm-tab-con roomAmenity" id="tabContent01" >
								
			<ul>
			<h4>Double</h4>
			<li dangerouslySetInnerHTML={{ __html: sanitizer(t("fBedroom1")) }}></li>
			<li dangerouslySetInnerHTML={{ __html: sanitizer(t("fBedroom2")) }}></li>
			<li dangerouslySetInnerHTML={{ __html: sanitizer(t("fBedroom3")) }}></li>
			
			<li dangerouslySetInnerHTML={{ __html: sanitizer(t("fBedroom4")) }}></li>
			<li dangerouslySetInnerHTML={{ __html: sanitizer(t("fBedroom5")) }}></li>
		
			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>
        <TabContent id="tab2" activeTab={activeAmenity}>

            <div className="cm-tab-con roomAmenity" id="tabContent02" >
								
			<ul>
			<li>{i18next.t('fLiving1')}</li>
			<li>{i18next.t('fLiving2')}</li>
			<li>{i18next.t('fLiving3')}</li>
			<li>{i18next.t('fLiving4')}</li>
			<li>{i18next.t('fLiving5')}</li>
			

		
			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>

        <TabContent id="tab3" activeTab={activeAmenity}>
            <div className="cm-tab-con roomAmenity" id="tabContent03" >
								
			<ul>
			<li>{i18next.t('fKitchen1')}</li>
			<li>{i18next.t('fKitchen2')}</li>
			<li>{i18next.t('fKitchen3')}</li>
			<li>{i18next.t('fKitchen4')}</li>
			<li>{i18next.t('fKitchen5')}</li>
			<li>{i18next.t('fKitchen6')}</li>
			<li>{i18next.t('fKitchen7')}</li>
			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>
		<TabContent id="tab4" activeTab={activeAmenity}>
            <div className="cm-tab-con roomAmenity" id="tabContent04" >
								
			<ul>
			<li>{i18next.t('fBathroom1')}</li>
			<li>{i18next.t('fBathroom2')}</li>
			<li>{i18next.t('fBathroom3')}</li>
			<li>{i18next.t('fBathroom4')}</li>
			<li>{i18next.t('fBathroom5')}</li>
			<li>{i18next.t('fBathroom6')}</li>
			<li>{i18next.t('fBathroom7')}</li>


			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>
		<TabContent id="tab5" activeTab={activeAmenity}>
            <div className="cm-tab-con roomAmenity" id="tabContent05" >
								
			<ul>
			<li>{i18next.t('fOndol1')}</li>
			<li>{i18next.t('fOndol2')}</li>
			<li>{i18next.t('fOndol3')}</li>
		

			</ul>
			<div class="cl"></div>
			</div>

        </TabContent>
    </div>
</div>

<div className="amenities">
	<div class="amenitiesList">
	<div className="amenitiesImg">
		{/*<img src={family4} alt="room1"></img>*/}
		<ImageGallery items={bedroom} showBullets={true} autoPlay={true} showThumbnails={false} showPlayButton={false}/>

	</div>
	<div className="bd-wrap">
	<div class="bd-col familyBedroom">
	<div class="bd-content fl-node-content">
	<div class="fl-module fl-module-heading">
	<div class="fl-module-content fl-node-content">
		<h3 class="fl-heading">
		<span class="fl-heading-text">Bedroom</span>
	</h3>
	</div>
	</div>
	<div class="fl-module fl-module-pp-iconlist fl-node-5d55d24e45afd list-text animate active" data-node="5d55d24e45afd">
	<div class="fl-module-content fl-node-content">
		<div class="pp-icon-list pp-user-agent-chrome">
			<ul class="pp-icon-list-items pp-list-type-icon">
	
		
			<li class="pp-icon-list-item pp-icon-list-item-0">
				<span class="pp-list-item-icon "></span>
				<span  class="pp-list-item-text" dangerouslySetInnerHTML={{ __html: sanitizer(t("fBedroom1")) }}></span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-1">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBedroom2')}</span>
			</li>
			<li class="pp-icon-list-item pp-icon-list-item-1">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBedroom3')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-1">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBedroom4')}</span>
			</li>
		
			<li class="pp-icon-list-item pp-icon-list-item-1">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBedroom5')}</span>
			</li>
			{/*<li class="pp-icon-list-item pp-icon-list-item-3">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBedroom3')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-4">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBedroom4')}</span>
			</li>*/}

		
			</ul>
		</div>
	</div>
	</div>

	</div>
	</div>



	</div>

	</div>   

	<div class="amenitiesList">
	<div className="amenitiesImg">
		{/*<img src={room2} alt="room3"></img>*/}
		<ImageGallery items={livingRoom} showBullets={true} autoPlay={true} showThumbnails={false} showPlayButton={false}/>

		
	</div>
	<div class="fl-col">
	<div class="fl-col-content fl-node-content">
	<div class="fl-module fl-module-heading">
	<div class="fl-module-content fl-node-content">
		<h3 class="fl-heading">
		<span class="fl-heading-text">Living Room</span>
	</h3>
	</div>
	</div>
	<div class="fl-module fl-module-pp-iconlist fl-node-5d55d24e45afd list-text animate active" data-node="5d55d24e45afd">
	<div class="fl-module-content fl-node-content">
		<div class="pp-icon-list pp-user-agent-chrome">
			<ul class="pp-icon-list-items pp-list-type-icon">
	
		
			<li class="pp-icon-list-item pp-icon-list-item-0 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving1')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-1 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving2')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-2 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving3')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving4')}</span>
			</li>
			<li class="pp-icon-list-item pp-icon-list-item-4 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving5')}</span>
			</li>
	
			<li class="pp-icon-list-item pp-icon-list-item-4 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving6')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-4 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving7')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-4 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving8')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-4 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving9')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-4 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving10')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-4 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving11')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-4 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fLiving12')}</span>
			</li>
			{/*<li class="pp-icon-list-item pp-icon-list-item-6 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">멀티충전기</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-7 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">구두주걱</span>
			</li>*/}

		
			</ul>
		</div>
	</div>
	</div>

	</div>
	</div>	
	
	</div>  

	<div class="amenitiesList">
	<div className="amenitiesImg">
		{/*<img src={family6} alt="room1"></img>*/}
		<ImageGallery items={kitchen} showBullets={true} autoPlay={true} showThumbnails={false} showPlayButton={false}/>

	</div>

	<div class="fl-col">
	<div class="fl-col-content fl-node-content">
	<div class="fl-module fl-module-heading">
	<div class="fl-module-content fl-node-content">
		<h3 class="fl-heading">
		<span class="fl-heading-text">Private Bar</span>
	</h3>
	</div>
	</div>
	<div class="fl-module fl-module-pp-iconlist fl-node-5d55d24e45afd list-text animate active" data-node="5d55d24e45afd">
	<div class="fl-module-content fl-node-content">
		<div class="pp-icon-list pp-user-agent-chrome">
			<ul class="pp-icon-list-items pp-list-type-icon">
			<li class="pp-icon-list-item pp-icon-list-item-0 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fKitchen1')}</span>
			</li>
			<li class="pp-icon-list-item pp-icon-list-item-1 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fKitchen2')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-1 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fKitchen3')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-2 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fKitchen4')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fKitchen5')}</span>
			</li>

			
			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fKitchen6')}</span>
			</li>
			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fKitchen7')}</span>
			</li>
			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fKitchen8')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fKitchen9')}</span>
			</li>


		
			</ul>
		</div>
	</div>
	</div>

	</div>
	</div>
	</div>   

	<div class="amenitiesList">
	<div className="amenitiesImg"><img src={family7} alt="room1" className="noSlideImg"></img></div>

	<div class="fl-col">
	<div class="fl-col-content fl-node-content">
	<div class="fl-module fl-module-heading">
	<div class="fl-module-content fl-node-content">
		<h3 class="fl-heading">
		<span class="fl-heading-text">Bathroom</span>
	</h3>
	</div>
	</div>
	<div class="fl-module fl-module-pp-iconlist fl-node-5d55d24e45afd list-text animate active" data-node="5d55d24e45afd">
	<div class="fl-module-content fl-node-content">
		<div class="pp-icon-list pp-user-agent-chrome">
			<ul class="pp-icon-list-items pp-list-type-icon">
	
			<li class="pp-icon-list-item pp-icon-list-item-0 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBathroom1')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-1 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBathroom2')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-2 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBathroom3')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBathroom4')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBathroom5')}</span>
			</li>
			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBathroom6')}</span>
			</li>
			<li class="pp-icon-list-item pp-icon-list-item-3 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fBathroom7')}</span>
			</li>

		


		
			</ul>
		</div>
	</div>
	</div>

	</div>
	</div>
	</div>  
	<div class="amenitiesList">
	<div className="amenitiesImg">
		{/*<img src={room4} alt="room1"></img>*/}
		<ImageGallery items={ondol} showBullets={true} autoPlay={true} showThumbnails={false} showPlayButton={false}/>

	</div>

	<div class="fl-col">
	<div class="fl-col-content fl-node-content">
	<div class="fl-module fl-module-heading">
	<div class="fl-module-content fl-node-content">
		<h3 class="fl-heading">
		<span class="fl-heading-text">Ondol Room</span>
	</h3>
	</div>
	</div>
	<div class="fl-module fl-module-pp-iconlist fl-node-5d55d24e45afd list-text animate active" data-node="5d55d24e45afd">
	<div class="fl-module-content fl-node-content">
		<div class="pp-icon-list pp-user-agent-chrome">
			<ul class="pp-icon-list-items pp-list-type-icon">
	
			<li class="pp-icon-list-item pp-icon-list-item-0 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fOndol1')}</span>
			</li>

		
			<li class="pp-icon-list-item pp-icon-list-item-1 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fOndol2')}</span>
			</li>

			<li class="pp-icon-list-item pp-icon-list-item-1 reveal">
				<span class="pp-list-item-icon "></span>
				<span class="pp-list-item-text">{i18next.t('fOndol3')}</span>
			</li>
		

		
			</ul>
		</div>
	</div>
	</div>

	</div>
	</div>
	</div>  

</div>



<div className="roomNotice">
<h2 class="roomHeader">{i18next.t('guideline')}</h2>
	<div className="">
	<h3>입실 안내 Check-in</h3>
	<table>
		<tr>
			<th>
				구분
			</th>
			<th>
				입실시간
			</th>
		</tr>
		<tr>
			<td>
				성수기
			</td>
			<td>16:00 ~ 22:00</td>
		</tr>

		<tr>
			<td>
				평일/주말/연휴
			</td>
			<td>16:00 ~ 22:00</td>
		</tr>
	</table>

	<h3>퇴실 안내 Check-out</h3>
	<table>
		<tr>
			<th>
				구분
			</th>
			<th>
				입실시간
			</th>
		</tr>
		<tr>
			<td>
				성수기
			</td>
			<td>~ 11:00</td>
		</tr>

		<tr>
			<td>
				평일/주말/연휴
			</td>
			<td>~ 11:00</td>
		</tr>
	</table>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline1")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline2")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline3")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline4")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline5")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline6")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline7")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline8")) }}></p>
	<p dangerouslySetInnerHTML={{ __html: sanitizer(t("guideline9")) }}></p>

	</div>


	<h2 class="roomHeader">{i18next.t('cancellation')}</h2>

	<table>
		<tr>
			<th>
				체크인 기준
			</th>
			<th>
				환불수수료
			</th>
		</tr>
		<tr>
			<td>
				6일전
			</td>
			<td>100% 환불</td>
		</tr>

		<tr>
			<td>
				5일전
			</td>
			<td>80% 환불</td>
		</tr>

		<tr>
			<td>
				4일전
			</td>
			<td>60% 환불</td>
		</tr>

		<tr>
			<td>
				3일전
			</td>
			<td>40% 환불</td>
		</tr>

		<tr>
			<td>
				2일전
			</td>
			<td>20% 환불</td>
		</tr>

		<tr>
			<td>
				1일전, 당일
			</td>
			<td>환불불가</td>
		</tr>




	</table>

	<p>객실이용 : </p>
	<p>케이블TV- 163개 채널</p>
	<p>유무선 인터넷(wi-fi)</p>

</div>

</div>			


			
		
              
        </div>
    </TabContent>
	</div>
</div>

    

      
  );
};
  
export default Rooms3;