import React, { useState }  from "react";
import styled from "styled-components";

const PageUl = styled.ul`
  float: left;
  list-style: none;
  text-align: center;
  border-radius: 3px;
  color: black;
 

`;

const PageLi = styled.li`
  display: inline-block;
  border:1px solid #ddd;
  font-size: 15px;
  font-weight: 300;
  padding: 12px;
  text-align:center;
  
  &:hover {
    cursor: pointer;
    color: white;
    background-color: #263a6c;
  }
  &:focus::after {
    color: white;
    background-color: #263a6c;
  }
`;



const Pagination = ({ postsPerPage, totalPosts,page, paginate }) => {
  const pageNumbers = [];
  if (page <= 3){
    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
      pageNumbers.push(i);
      if (i === 5){
        break;
      }
    }
  }

  else if (page > 3 && page+2 <= Math.ceil(totalPosts / postsPerPage)){

    if (Math.ceil(totalPosts / postsPerPage)>5){
      for (let i = page-2; i <= page+2; i++) {
        pageNumbers.push(i);
      }
    }else if (Math.ceil(totalPosts / postsPerPage)<5){
      for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
      }
    }

    
    
  }
  else if (page+2 > Math.ceil(totalPosts / postsPerPage)){
    const diff = (page+2) - Math.ceil(totalPosts / postsPerPage);
    for (let i = page-2-diff; i <= page+2-diff; i++) {
      pageNumbers.push(i);
    }
    
  }
  /*for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }*/
  const next = parseInt(page)+1;
  const prev = parseInt(page)-1;
  const numPages = Math.ceil(totalPosts / postsPerPage);
  return (
    <div>
        <PageUl className="pagination">
        <PageLi onClick={() => paginate(1)} disabled={page === 1}>
        <span>처음</span>
        </PageLi>

        <PageLi onClick={() => {if (page>1) paginate(page-1)}} disabled={page === numPages}>
        <span>이전</span>
        </PageLi>

          {pageNumbers.map((number) => (
            <PageLi key={number} onClick={() => paginate(number)} className={`page-link ${page === number ? "active" : ""}`}>
                {number}
            </PageLi>
          ))}
        <PageLi onClick={() => {if (page<numPages) paginate(page+1)}} disabled={page === numPages}>
              다음
        </PageLi>
    
        <PageLi onClick={() => paginate(numPages)} disabled={page === numPages}>
          끝
        </PageLi>
        </PageUl>
    </div>
  );
};

export default Pagination;