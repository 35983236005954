import i18n from "i18next"
import {initReactI18next} from "react-i18next";

import translationEn from './translation.en'
import translationKr from './translation.kr'
import translationJp from './translation.jp'

import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";

const resource =  {
    en: {
        translation: translationEn
    },
    kr: {
        translation: translationKr
    },
    jp: {
        translation: translationJp
    }
};

i18n
    .use(initReactI18next)  // passes i18n down to react-i18next
    .init({
        resources: resource,
        lng: "kr",
        fallbackLng: 'kr',
        // ns: ['translation'],
        // defaultNS: "translation",
        debug: true,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;