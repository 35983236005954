import TabNavItem from "../components/TabNavItem";
import TabContent from "../components/TabContent";
import '../components/Tabs2.css';
import './css/location.css';
import React, { useState, useEffect, useRef } from 'react';
import dompurify from "dompurify";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const { kakao } = window;
const Location = () => {
  const { t, i18n } = useTranslation();
  const sanitizer = dompurify.sanitize;
  const language = useRef(null);
  
  useEffect(()=>{
    language.current = sessionStorage.getItem('language');
  if (language===null){
    language.current = cookies.get('language');
  }
  i18next.changeLanguage(language.current);
  },[]);
  
  const [activeTab, setActiveTab] = useState("tab1");
  useEffect(()=>{
    var container = document.getElementById('map');
    var options = {
      center: new kakao.maps.LatLng(33.49696129519766, 126.44865210992928),
      level: 3
    };

    var map = new kakao.maps.Map(container, options);
    var markerPosition  = new kakao.maps.LatLng(33.49696129519766, 126.44865210992928);
    var marker = new kakao.maps.Marker({
      position: markerPosition
  });
  marker.setMap(map);

    }, [])

    return (
        <div className="wrap">
        <style>
        <style>
        {`
          .sub-list-wrap {
            width:100%;
            margin:50px auto;
          }
          #map{
            height:700px;
          }
          .location{
            width:60%;
            min-width:800px;
            margin:50px auto 0 auto;
            
          }
          .summary{
            margin-top:0;
            min-width:800px;
            width:60%;
          }
          .location p{
            font-size:50px;
            line-height:600px;
          }
          .Tabs2{
            margin-bottom:0;
          }
          .Tabs2 ul.nav{
            min-width:unset;
          }
          .Tabs2 ul.nav li{
            width:50%;
          }
          @media (max-width: 1600px){
          .summary {
              width: 40% !important;
              margin: auto;
       
          }
        }
          @media (max-width: 960px) {
            .summary {
              width: 100% !important;
              margin: auto;
       
            }
            .location{
                width:95%;
                min-width:unset;
            }
            #map{
              height:300px;
            }
            .Tabs2{
              background:#fcfcfc;
              margin-top:30px;
             
            }
            .location p{
                line-height:300px;
            }
            .summary{
              min-width:unset;
              width:100%;
              padding:0 10px;
            }
            .sub-list-wrap {
              width:100%;
              margin:30px auto 0 auto;
            }
          
            
          }
        `}
      </style>
        </style>
        <h2 className = "headerTxt">LOCATION</h2>
        <div className="location">
        <div id="map"></div>
        
        <div style={{height:"50px", borderTop:"1px solid #5c5c5c", marginTop:"0"}}>
          <button className="kakaoLogo">Kakao maps</button>
          <a href="http://kko.to/zFjy_LlUR" target="_blank" rel="noopener noreferrer">
            <button className="findWay">{i18next.t('location1')}</button>
          </a>
        </div>
        </div>
        
        <div className="summary" style={{textAlign:'left'}}>

              <div className = "sub-list-wrap">
                <div className="sub-info-list">
                    <div className="sub-info-bx">
                        <div className="sub-info-tit">
                            <h4><img src="http://www.changwonhotel.co.kr/images/icon/ic_facility.png" alt=""/> {i18next.t('location2')} </h4>
                        </div>
                        <div className="sub-info">
                        <p dangerouslySetInnerHTML={{ __html: sanitizer(t("location3")) }}></p>
                            <p>{i18next.t('location4')}</p>
                            <p>{i18next.t('location5')}</p>
                        </div>
                    </div>
                
                </div>
                </div>

        <div className="Tabs2">
            <ul className="nav">
                <TabNavItem title={i18next.t('location6')} id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                <TabNavItem title={i18next.t('location7')} id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
            </ul>
 
            <div className="outlet">
                <TabContent id="tab1" activeTab={activeTab}>

                <div className="cm-tab-con" id="tabContent01" >
								
								<div className="location-bx">
									<p>
                  {i18next.t('location8')}
                  </p>
									<div className="location-sub-bx">
										<p>- {i18next.t('location9')}</p>
									</div>
								</div>

					
							  </div>

                </TabContent>
                <TabContent id="tab2" activeTab={activeTab}>

                <div className="cm-tab-con" id="tabContent02">
								
								<div className="location-bx">
									<p>
                  {i18next.t('location10')}
                  </p>
									<div className="location-sub-bx">
										<p>- 소요시간 : 약 12분</p>
									</div>
								</div>

							</div>

                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>

                <div className="cm-tab-con" id="tabContent03" style={{display: 'block'}}>
								<div className="location-bx">
									<p>{i18next.t('location10')}
                  </p>
									<div className="location-sub-bx">
										<p>- {i18next.t('location11')}</p>
									</div>
								</div>

								


							</div>

                </TabContent>
            </div>
        </div>


        </div>

        

        </div>

    

      
  );
};
  
export default Location;