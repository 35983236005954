import './css/home.css'
import './css/main.css'
import view from './img/view.png';

import mainimg from './img/mainimg.png';
import main2 from './img/main2.jpg';
import main3 from './img/main3.png'
import main4 from './img/main4.jpg';
import main4_mobile from './img/ihobeach1.jpg'


import airplane from './img/airplane.jpg';

import 이호mh호텔 from './img/ihomhhotel.png'
import mhhotel1 from './img/table.png'
import 이호해변 from './img/beach.png'

import ex1 from './img/호텔서비스.jpg'
import ex2 from './img/레스토랑.jpg'
import ex3 from './img/골프패키지.jpg'

import downArrow from './img/down-arrow.png'


import ImageGallery from 'react-image-gallery';

import { fadown } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


/*
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateRangePicker } from 'rsuite';

import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays } from 'date-fns';

import { useState } from 'react';
import { DatePicker } from 'react-datepicker';*/
import React, { useState, useEffect, useRef } from 'react';
import DateRangePicker from 'rsuite/DateRangePicker';
import 'rsuite/dist/rsuite.min.css';import { Link, useNavigate } from "react-router-dom";
import moment from 'moment';
import dompurify from "dompurify";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";


import Carousel, {CarouselItem} from "../components/Carousel";

import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Home = () => {
  //PC버전 메인 최상단 이미지 갤러리 이미지 리스트
  const images = [
    {
      original: mainimg,
      thumbnail: mainimg,
    },
    {
      original: main2,
      thumbnail: main2,
    },
    {
      original: main3,
      thumbnail: main3,
    },
    {
      original: main4,
      thumbnail: main4,
    }
  ];
  //모바일버전 메인 최상단 이미지 갤러리 이미지 리스트
  const images_mobile = [
    {
      original: mainimg,
      thumbnail: mainimg,
    },
    {
      original: main2,
      thumbnail: main2,
    },
    {
      original: main3,
      thumbnail: main3,
    },
    {
      original: main4_mobile,
      thumbnail: main4_mobile,
    }
  ];


  const { t, i18n } = useTranslation();
  const sanitizer = dompurify.sanitize;
  const language = useRef(null);
  
  useEffect(()=>{
    language.current = sessionStorage.getItem('language');
  if (language===null){
    language.current = cookies.get('language');
  }
  i18next.changeLanguage(language.current);
  },[]);
  /*const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 1),
      key: 'selection'
    }
  ]);
  

  const [startDate, setStartDate] = useState(new Date("2014/02/08"));
  const [endDate, setEndDate] = useState(new Date("2014/02/10"));*/
  
  let [count, setCount] =useState(1);
  const navigate = useNavigate();
  function incrementCount(){
    count+=1;
    setCount(count);
  }

  function decrementCount(){
    count-=1;
    if (count < 0){
      count=0;
      setCount(0);
      return
    }
    setCount(count);
  }


  const [counterA, setCounterA] = useState(1);
  const [counterB, setCounterB] = useState(2);
  const [counterC, setCounterC] = useState(0);
  const today = new Date();
  const [sDate, setsDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [eDate, seteDate] = useState(moment(today.setDate(today.getDate() + 1)).format("YYYY-MM-DD"));
  const onChange = (item) => {

    const s= item.value[0];
    const e = item.value[1];

    setsDate(moment(s).format("YYYY-MM-DD"));
    seteDate(moment(e).format("YYYY-MM-DD"));
    console.log(sDate, eDate); 
  }


  const { beforeToday } = DateRangePicker;
  
  function handleClick(e) {

    navigate("/reservation", { state: {sDate : sDate, eDate : eDate} });
  }

  function reveal() {
    var fades = document.querySelectorAll(".on-scroll");
    var titles = document.querySelectorAll(".title-wrap");
    var reveals = document.querySelectorAll(".reveal");
    for (var i = 0; i < fades.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = fades[i].getBoundingClientRect().top;
      var elementVisible = 150;
      if (elementTop < windowHeight - elementVisible) {
        fades[i].classList.add("visible");
      } else {
        fades[i].classList.remove("visible");
      }
    }
    for (var j = 0; j < titles.length; j++) {
      var windowH = window.innerHeight;
      var elementT = titles[j].getBoundingClientRect().top;
      var elementV= 150;
      if (elementT < windowH - elementV) {
        titles[j].classList.add("visible");
      } else {
        titles[j].classList.remove("visible");
      }
    }
    for (var k = 0; k < reveals.length; k++) {
      var wH = window.innerHeight;
      var eT = reveals[k].getBoundingClientRect().top;
      var eV= 150;
      if (eT < wH - eV) {
        reveals[k].classList.add("active");
      } else {
        reveals[k].classList.remove("active");
      }
    }
  }
  window.addEventListener("scroll", reveal);

  reveal();
  
  return (
    <div className="wrap" style={{flex:'1'}}>
      <style>
        {`
        .mobile_event{
          display:none;
        }
        .event-wrap{
          display:flex;
        }
        /* animation styles */
        @keyframes fadeIn {
          0% { opacity: 0; }
          20% { opacity: 0; }
          60% { opacity: 1; }
          100% { opacity: 1; }
        }
        
        .fadeInClass {
          animation-name: fadeIn;
          animation-duration: 3s;
          animation-direction: alternate;
          animation-timing-function: linear;
        }
          
         
          .event div img{
            width:100%;
          }
          
          
       
          
          .box1, .box2{
            border:none;
            color:white;

          }
          .selCntText{
            color:black;
          }
          .chkInOut{
            color:black;
          }
          .selCnt{
            background-color:#ab9b84;
            color:white;

          }
         
          .reserve_wrapper{
            margin-top:-5px;
          }
          .calendar_wrapper{
            background-color:#ab9b84;
            color:white;
          }
          .calendar_input{
            background-color:#ab9b84;
            color:white;
            
          }
      
          

.mainText {
  width:1000px;
  margin:0 auto;
  padding-top:250px;
}
.mainText h1 {
  font-family: "Montserrat Medium";
  text-align: center;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

.mainText span {
  display: inline-block;
  opacity: 0;
  text-shadow: 2px 2px 4px gray;
  color:#fff;
  font-family: 'HallymGothic-Regular';
  margin-left:20px;
  filter: blur(4px);
}

.mainText span:nth-child(1) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(2) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(3) {
  animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(4) {
  animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(5) {
  animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(6) {
  animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(7) {
  animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(8) {
  animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(9) {
  animation: fade-in 0.8s 2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(10) {
  animation: fade-in 0.8s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(11) {
  animation: fade-in 0.8s 2.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(12) {
  animation: fade-in 0.8s 2.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(13) {
  animation: fade-in 0.8s 2.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(14) {
  animation: fade-in 0.8s 3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(15) {
  animation: fade-in 0.8s 3.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(16) {
  animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(17) {
  animation: fade-in 0.8s 2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

.mainText span:nth-child(18) {
  animation: fade-in 0.8s 2.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

@media (max-width: 960px) {
  .mobile_event{
    display:block;
    background-color:#fff2e8;
    padding:30px 0 10px 0;
  }
  .mobile_event .eventTitle{
    text-align:center;
  }
  .event-wrap{
    display:none;
  }
  .event{
    display:flex;
    flex-direction:column;
    width:80%;
    position:relative;
    margin:0 auto;
    
  }
  .eventTitle {
    min-width: unset;
    width: 80%;
  }
  .event-wrap{
    background:none;
    height:auto;
  }
  .event .event-item{
    width:100%;
    margin: 20px auto;
    display:block;
    box-shadow:none;
    min-width:unset;
    border:1px solid #878f99;
    

 
  }
  .calendar_wrapper{
    margin-top:-50px;
  }
  .mainText{
    width:auto;
    margin-left:0;
  }
  .mainText span {
  
    font-family: 'HallymGothic-Regular';
    margin-left:10px;
    
  }
  #main-content {

    margin-top:0;
  }
  #main-content .thumbnail.visible {
    top:-45px;
  }
  #main-content .thumbnail.visible {
    transition: transform 3s ease;
    transform: translate3d(0px, 45px, 0px);
    width: 100%;
  }
}
.swiper-slide{
  padding-bottom:60px;
}
.swiper-slide .event-item{
  width:80%;
  height:auto;
  margin:0 auto;
  padding-bottom:5px;
}
.swiper-slide img {
  width: 100% !important;
  height: auto !important;
}
.swiper-pagination-bullet-active{
  background:#e03267;
}
.swiper-pagination-bullet{
  width:12px;
  height:12px;
}
.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 6px;
}
.lastSection .swiper-button-next{
  right:10% !important;
}
.lastSection .swiper-button-prev{
  left:70% !important;
}
.lastSection .swiper-button-next, .swiper-button-prev {
top:90% !important;


}
`}
  </style>
    <div className="main_visual fadeInClass">
    <div className="main_pc">
      <ImageGallery items={images} showBullets={false} autoPlay={true} showThumbnails={false} showPlayButton={false}/>
    </div>
    <div className="main_mobile">
      <ImageGallery items={images_mobile} showBullets={false} autoPlay={true} showThumbnails={false} showPlayButton={false}/>
    </div>
    {/*<div className="main_visual fadeInClass" style={{ backgroundImage: `url(${jejuisland})` ,
      backgroundSize:"cover", backgroundPosition:"center center" }}>*/}



      {/*<div className="mainText">
      {language.current !== "en-US" && (
      <h1>
      <span>제주도</span>
      <span>이호</span>
      <span>MH</span>
      <span>호텔</span><br></br>
      <span>한라산과</span>
      <span>이호</span>
      <span>해변을</span>
      <span>한눈에</span>
      <span>담다</span>
      
      </h1>
      )}

    {language.current === "en-US" && (
      <h1>
      <span>JEJU</span>
      <span>IHO</span>
      <span>MH</span>
      <span>HOTEL</span><br></br>
      <span>offers</span>
      <span>Mt. Halla</span>
      <span>and</span>
      <span>Iho beach</span>
      <span>at one glance</span>
      
      </h1>
    )}
    </div>*/}
{/*
달력
<div className="reserve_wrapper" style={{backgroundColor:"#ab9b84", height:"auto"}}>
<div className="calendar_wrapper">
        <div className="searchOptions">
          <div className="box1">
          <div className="selCntText">체크인    체크아웃</div>
          <DateRangePicker appearance="default" placeholder="Check in - Check out" defaultValue={[new Date(sDate), new Date(eDate)]} format='yyyy-MM-dd' block onChange={(date) => onChange({ name: "startDate", value: date })} disabledDate={beforeToday()}
          locale={{
            sunday: "일",
            monday: "월",
            tuesday: "화",
            wednesday: "수",
            thursday: "목",
            friday: "금",
            saturday: "토",
            ok: "확인",
            today: "Today",
            January: "1월",
            yesterday: "Yesterday",
            last7Days: "Last 7 days",
            formattedDayPattern: 'yyyy년 M월 dd'
          }}/>



          

        
          
          </div>

          <div className="box2">
            <dl>
            <div className="selCntText">객실</div>
            <div className="roomCnt">
            <button onClick={() => { setCounterA(counterA - 1);}} disabled={counterA < 1}>-</button>
              <span>{counterA}</span>
            <button onClick={() => { setCounterA(counterA + 1);}} disabled={counterA > 1}>+</button>

            </div>
            

            </dl>
            <dl>
            <div className="selCntText">성인</div>
            <div className="">
            <button onClick={() => { setCounterB(counterB - 1);}} disabled={counterB < 2}>-</button>
              <span>{counterB}</span>
            <button onClick={() => { setCounterB(counterB + 1);}} disabled={counterB > 9}>+</button>
            </div>
            

            </dl>
            <dl>
            <div className="selCntText">어린이</div>
            <div className="">
            <button onClick={() => { setCounterC(counterC - 1);}} disabled={counterC < 1}>-</button>
              <span>{counterC}</span>
            <button onClick={() => { setCounterC(counterC + 1);}} disabled={counterC > 3}>+</button>
            </div>
            


            </dl>

         
          </div>
        </div>

          <button className="reserve_btn" onClick={handleClick}>검색</button>
          
      </div>
      </div>*/}

     
<section id="section05" class="demo">
  <a href="#section06"><span></span></a>
</section>
</div>
    

  <div className="roomArea">
    <div className="mobile_event">
      <h3 className="eventTitle">SPECIAL OFFERS</h3>
      <Swiper
        slidesPerView={1}
        spaceBetween={5}
		autoplay={{
			delay: 2500,
			disableOnInteraction: false,
		  }}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
		navigation={true}
		loop={true}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className="event-item">
        <div className="event-img"><img src ={ex1} alt="example1"></img></div>
        <div className="event-title">AUTUMN HOLIDAY</div>
        <div className="event-text">
          <span dangerouslySetInnerHTML={{ __html: sanitizer(t("package1")) }}></span>
				</div>
        <div className="event_go">
        <a href="http://test.ihomhhotel.com/postView/1" className="btn-go bg-gold2" target="_self">
        <span>+</span>
        <span>READ MORE</span>
				</a>
        </div>
      </div>

        </SwiperSlide>
        <SwiperSlide>
        <div className="event-item"><img src ={ex2} alt="example1"></img>
        <div className="event-title">RESTAURANT OPEN</div>
        <div className="event-text">

          <span dangerouslySetInnerHTML={{ __html: sanitizer(t("package2")) }}></span>
				</div>
        <div className="event_go">
        <a href="http://test.ihomhhotel.com/postView/2" className="btn-go bg-gold2" target="_self">
        <span>+</span>
        <span>READ MORE</span>
				</a>
        </div>
        
      </div>

        </SwiperSlide>
		<SwiperSlide>
    <div className="event-item"><img src ={ex3} alt="example1"></img>
        <div className="event-title">GOLF PACKAGE</div>
        <div className="event-text">

  <span dangerouslySetInnerHTML={{ __html: sanitizer(t("package3")) }}></span>
				</div>
        <div className="event_go">
        <a href="http://test.ihomhhotel.com/postView/3" className="btn-go bg-gold2" target="_self">
        <span>+</span>
        <span>READ MORE</span>
				</a>
        </div>
        
      </div>
    </SwiperSlide>
      
      </Swiper>
    </div>
      <div className="event-wrap">
      <div className="eventwrap">
      <h3 className="eventTitle">SPECIAL OFFERS</h3>
      <div className="event">
      
      <div className="event-item">
        <div className="event-img"><img src ={ex1} alt="example1"></img></div>
        <div className="event-title">AUTUMN HOLIDAY</div>
        <div className="event-text">
          <span dangerouslySetInnerHTML={{ __html: sanitizer(t("package1")) }}></span>
				</div>
        <div className="event_go">
        <a href="http://test.ihomhhotel.com/postView/1" className="btn-go bg-gold2" target="_self">
        <span>+</span>
        <span>READ MORE</span>
				</a>
        </div>
      </div>
      <div className="event-item"><img src ={ex2} alt="example1"></img>
        <div className="event-title">RESTAURANT OPEN</div>
        <div className="event-text">

          <span dangerouslySetInnerHTML={{ __html: sanitizer(t("package2")) }}></span>
				</div>
        <div className="event_go">
        <a href="http://test.ihomhhotel.com/postView/2" className="btn-go bg-gold2" target="_self">
        <span>+</span>
        <span>READ MORE</span>
				</a>
        </div>
        
      </div>
      <div className="event-item"><img src ={ex3} alt="example1"></img>
        <div className="event-title">GOLF PACKAGE</div>
        <div className="event-text">

  <span dangerouslySetInnerHTML={{ __html: sanitizer(t("package3")) }}></span>
				</div>
        <div className="event_go">
        <a href="http://test.ihomhhotel.com/postView/3" className="btn-go bg-gold2" target="_self">
        <span>+</span>
        <span>READ MORE</span>
				</a>
        </div>
        
      </div>

      </div>
      </div>
      </div>
      
      <div id="main-content"> 
      <div className="thumbnail on-scroll" style={{backgroundImage: `url(${airplane})`}}>
        </div> 
        <div className="page-content on-scroll"> 
        <div className="title-wrap"> 
        <h1 className="on-scroll">JEJU IHO MH HOTEL</h1> 
        </div> 
        <div className="on-scroll"> 
        <p dangerouslySetInnerHTML={{ __html: sanitizer(t("overview")) }}>
        </p>
          
        </div> 
        </div> 
        </div>
      <div className="lastSection">
      <Carousel>
        <CarouselItem>

        <div className="lastRow">
        <div className="rowImg">
        <img src={이호mh호텔} alt="" style={{objectPosition:"center bottom"}}/>

        </div>
        <h4 className="header-title">About MH HOTEL</h4>
        <h4 className="second-title">{i18next.t('title1')}</h4>
        <div className="description">
          <p className="description-text" dangerouslySetInnerHTML={{ __html: sanitizer(t("content1")) }}>
          </p>
        </div>
        <div className="actions">
        <a href="/overview">
          <img src={view} alt="view more"></img>
          </a>
        </div>
      </div>


        </CarouselItem>
        <CarouselItem>


        <div className="lastRow">
        <div className="rowImg">
        <img src={mhhotel1} alt="" style={{objectPosition:"center bottom"}}/>

        </div>
        <h4 className="header-title">Rooms</h4>
        <h4 className="second-title">{i18next.t('title2')}</h4>
        <div className="description">
          <p className="description-text" dangerouslySetInnerHTML={{ __html: sanitizer(t("content2")) }}>
          </p>
        </div>
        <div className="actions">
        <a href="/rooms/premium">
          <img src={view} alt="view more"></img>
          </a>
        </div>
      </div>

        </CarouselItem>
        <CarouselItem>

          
        <div className="lastRow">
        <div className="rowImg">
        <img src={이호해변} alt="" style={{objectPosition:"center bottom"}}/>

        </div>
        <h4 className="header-title">Tour</h4>
        <h4 className="second-title">{i18next.t('title3')}</h4>
        <div className="description">
          <p className="description-text" dangerouslySetInnerHTML={{ __html: sanitizer(t("content3")) }}>
          </p>
        </div>
        <div className="actions">
        <a href="/attractions/beach">
          <img src={view} alt="view more"></img>
          </a>
        </div>
      </div>
        </CarouselItem>
      </Carousel>



      </div>

      <div className="page-wrapper">
        <div className="page-component">
                    <header className="section-header">
                    </header>
                    <div className="facility-hotels-container">
                        <article className="hotel-content-card wow reveal" data-type="oval" data-wow-duration="1s" data-wow-offset="10">
                            <div className="card-image">
                                <div className="image">
                                  <img src={이호mh호텔} alt="" style={{objectPosition:"center bottom"}}/>
                                    
                                </div>
                            </div>

                            <div className="card-wrapper-right"></div>
                            <div className="card-body">
                              {/*<div class="small-headline">
                                <div class="small-headline__copy show-object"  >
                                {t("title1")}                                        
                                </div>
    </div>*/}
                                <div className="header">
                                  <h4 className="">{i18next.t('title1')}</h4>
                                  <h4 className="header-title">About MH HOTEL</h4>
                                </div>
                                <div className="description">
                                    <p className="description-text" dangerouslySetInnerHTML={{ __html: sanitizer(t("content1")) }}>
                                    </p>
                                </div>
                                <div className="actions">
                                    <a href="/overview" className="more-link">
                                        <span className="text">view more <strong>></strong> </span>
                                        <i className="icon-arrow"></i>
                                    </a>
                                </div>
                 
                            </div>
                        </article>
                        <article className="hotel-content-card wow reveal hotel-content-card2" data-type="semicircle" data-wow-duration="1s" data-wow-offset="10" data-wow-delay=".15s">
                            
                            
                          <div className="card-wrapper-left"></div>
                            <div className="card-body" >
                            {/*<div class="small-headline">
                                <div class="small-headline__copy show-object">
                                {t("title2")}                                      
                                </div>
  </div>*/}
                                <div className="header">
                                    <h4 className="">{i18next.t('title2')}</h4>
                                    <h4 className="header-title">Rooms</h4>
                                </div>
                                
                                <div className="description">
                                <p className="description-text" dangerouslySetInnerHTML={{ __html: sanitizer(t("content2")) }}>
                                    </p>
                                </div>
                                
                                <div className="actions">
                                    <a href="/rooms/premium" className="more-link">
                                    <span className="text">view more <strong>></strong> </span>
                                        <i className="icon-arrow"></i>
                                    </a>
                                </div>

                                
                                
                            </div>
                            

                            <div className="card-image">
                            
                                <div className="image">
                                  <img src={mhhotel1} alt="" style={{objectPosition:"center center"}}/>
                                    
                                </div>
                                
                            </div>


                        </article>
                        <article className="hotel-content-card wow reveal" data-type="oval" data-wow-duration="1s" data-wow-offset="10">
                            <div className="card-image">
                                <div className="image">
                                  <img src={이호해변} alt=""/>
                                  
                                </div>
                            </div>
                            <div className="card-wrapper-right"></div>
                            <div className="card-body">
                            {/*<div class="small-headline">
                                <div class="small-headline__copy show-object">
                                {t("title3")}                                            
                                </div>
</div>*/}
                                <div className="header">
                                  <h4 className="">{i18next.t('title3')}</h4>
                                  <h4 className="header-title">Tour</h4>
                                </div>
                                <div className="description">
                                <p className="description-text" dangerouslySetInnerHTML={{ __html: sanitizer(t("content3")) }}>
                                    </p>
                                </div>
                                <div className="actions">
                                    <Link to="/attractions/beach" className="more-link">
                                    view more <strong>></strong>
   
                                    </Link>
                                </div>
                            </div>
                        </article>
                    </div>
                </div>
              </div>
    
      </div>
    </div>

    

      
  );
};
  
export default Home;