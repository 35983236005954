import "./Footer.css"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import hotellogo from "./hotellogo.png";
import mobilelogo from "./호텔로고.png";
import logo1 from "./logo1.png";
import logo2 from "./logo2.png";
import dompurify from "dompurify";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import React,{useState, useEffect, useRef} from 'react';

import Cookies from 'universal-cookie';
const cookies = new Cookies();


const Footer = () => {
  const openEmail = () => {
    window.open('mailto:ihomhhotel@naver.com?subject=&body=%20goes%20here')

  }

  const { t, i18n } = useTranslation();
  const [selectedLang, setLanguage] = useState();
  const [korean, setKor] = useState(false);
  const language = useRef(null);
  const sanitizer = dompurify.sanitize;
  useEffect(()=>{
    language.current = sessionStorage.getItem('language');
    if (language.current===undefined || language.current===null){
      language.current = cookies.get('language');
      if (language.current===undefined || language.current===null){
        language.current ="kr-KR";
      }
    }
    console.log(language.current);
    
    i18next.changeLanguage(language.current);
    setLanguage(language.current);


    if (language.current ==="kr-KR"){
      setKor(true);
    }else {
      setKor(false);
    }

  },[]);

  return (
    <footer class="footer">
      <div class="wrap">
      <div className="footercol1"><a href="/" style={{color:"black", textDecoration:"none"}}>
        { korean && <img src={hotellogo} alt="hotel logo" className="footer_pclogo"></img> }
        { !korean && <img src={logo1} alt="hotel logo" className="footer_pclogo"></img> }

        { korean && <img src={mobilelogo} alt="hotel logo" className="footer_mobilelogo"></img> }
        { !korean && <img src={logo2} alt="hotel logo" className="footer_mobilelogo"></img> }
        </a>
      </div>
      <div className="footercol2">
        <div>
        <ul class="nav">
          <li><a href="/overview">{i18next.t('footerMenu1')}</a></li>
          <li><a href="/location">{i18next.t('footerMenu2')}</a></li>
          <li><a href="/board">{i18next.t('footerMenu3')}</a></li>
        </ul>
        <ul class="info">
          <li dangerouslySetInnerHTML={{ __html: sanitizer(t("footerText1")) }}></li>
          { korean && <li>대표자 : 임명한&nbsp;&nbsp;&nbsp;</li> }
          { korean && <li>사업자 등록번호 : 349-07-02125</li>}
          <li>Copyright ⓒ 2022 IHO MH HOTEL. All rights reserved.</li>
        </ul>
        </div>
      </div>
          {/*<dl class="sns">
            <dt>St.JOHN'S HOTEL SNS</dt>
            <dd><a class="youtube" href="https://www.youtube.com/channel/UCZiDc5i93jMu8vTuAuSgyYQ" target="_blank">Youtube</a></dd>
            <dd><a class="facebook" href="https://www.facebook.com/stjohnskorea" target="_blank">Facebook</a></dd>
            <dd><a class="instagram" href="https://www.instagram.com/stjohnshotel" target="_blank">Instagram</a></dd>
            <dd><a class="rss" href="http://blog.naver.com/stjohnshotelresort" target="_blank">RSS</a></dd>
          </dl>*/}
      <div className="footercol3" style={{fontFamily: 'SpoqaHanSansNeo-Regular'}}>
      <table id="footer_table_2">
				<tbody>
          <tr>
          <td>
            <div className="fr1"><strong className="Montserrat500">CUSTOMER CENTER</strong></div>
            <div className="">
							<strong className="contact_call"> 064-743-6444</strong>
						</div>
            <div className="fr1"><strong className="Montserrat500">{ korean && <span className="frSpan">연중무휴</span> } 09:00 ~ 18:00</strong></div>
          </td>
          </tr>
        <tr>
         
					<td>
						<div className="email_wrap">
							<button className="send_email" onClick={openEmail}><FontAwesomeIcon icon={faEnvelope} />   ihomhhotel@naver.com</button>
						</div>
					</td>
				</tr>
				{/*<tr>
					<td className="socials">
						<a style={{verticalAlign: "middle"}} href="https://www.instagram.com/kanhaus_/" target="_blank" rel="noreferrer"><img id="insta_vec" alt="insta" src="http://www.kanhaus.com/img/footer/insta_vec.svg"/></a>
						<a style={{verticalAlign: "middle"}} href="https://blog.naver.com/infection-control" target="_blank" rel="noreferrer"><img id="blog_vec" alt="blog" src="http://www.kanhaus.com/img/footer/blog_vec.svg"/></a>
					</td>
				</tr>*/}
			</tbody>
      </table>
      </div>
      </div>
    </footer>
  );
};
  
  export default Footer;