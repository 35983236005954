import './css/home.css'
import './css/choice.css'

import { faMinusSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom"
import ImageGallery from 'react-image-gallery';


import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Pagination } from "swiper";


import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import "react-image-gallery/styles/css/image-gallery.css";
import { isDisabled } from '@testing-library/user-event/dist/utils';
const Choice = () => {
  const location = useLocation();
  const data = location.state;
  
  const {room_id, bathroom, bedroom, living_room, private_bar, etc_amenity, room_name, numAdult, numKid, sDate, eDate, price_desc, price, num_days, room_type_id } = data;


  function getImagePaths(directory) {
    let images = [];
    directory.keys().map((item, index) => images.push(item.replace("./", "http://localhost:3000/img/premium/")));
  
    return images;
  }
  const directory = require.context("./img/premium", false, /\.(png|jpe?g|svg)$/);
  let imagePaths = getImagePaths(directory);


  let [count, setCount] =useState(0); 
  let [addPrice, setAddPrice] =useState(1);
  let [barbecuePrice, setBarbecue] =useState(1);
  useEffect(()=>{

  fetch("http://localhost:5000/priceAdd", {
    method : "post", // 통신방법
    headers : {
      "content-type" : "application/json",
      'Accept': 'application/json'
    },
    body : JSON.stringify(),
  })
  .then((res)=>res.json())
  .then((json)=>{
    console.log(json[0][0].RESULT);
    setAddPrice(json[0][0].RESULT);
    setBarbecue(json[1][0].Barbecue);
  });



  
  });    

  function incrementCount(){
    count+=1;
    setCount(count);
    addPerson((count * addPrice));
  }

  function decrementCount(){
    count-=1;
    if (count < 0){
      count=0;
      setCount(0);
      return
    }
    addPerson((count * addPrice));
    setCount(count);
  }
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);

  const [addedPerson, addPerson]= useState(0);
  const [barbecue, addBarbecue]= useState(0);

  const [isDisabled, setDisabled]= useState(true);

  const handleChange = () => {
    // Change state to the opposite (to ture) when checkbox changes
    setChecked(!checked);
    setDisabled(!isDisabled);
    addPerson((count * addPrice));
    if (checked) {addPerson(0); }
    
  };

  const handleChange2 = () => {
    // Change state to the opposite (to ture) when checkbox changes
    setChecked2(!checked2);
    if (checked2) addBarbecue(0);
    else addBarbecue(barbecuePrice);
    
  };

  //if (!checked) {setCount(0)};
  const data2 = {
    room_id: room_id,
    room_name: room_name,
    numAdult: numAdult,
    numKids : numKid,
    sDate : sDate,
    eDate : eDate,
    price_desc : price_desc,
    price : price,
    addedPerson : addedPerson,
    barbecue : barbecue,
    num_days : num_days,
    addPersons : count,
    room_type_id : room_type_id
  
  };
  
  return (
    <div className="wrap" style={{flex:'1', marginBottom:'500px'}}>

      <style>
        {`
          .imgGallery {
            width:60%;
            margin:50px auto;
            textAlign:center;

         
          }
          .Amenities{
            font-size:20px;
            border-collapse: collapse;
          }
          .Amenities tr, .Amenities td{
            border-bottom:1px solid #000;
            margin-top:5px;
            margin-bottom:5px;
          }
          @media (max-width: 960px) {
            .imgGallery{
                width:95%;
            }
            .Amenities{
              font-size:15px;
            }
            .Amenities td{
              height:60px;
            }
        
          }
        `}
      </style>
  <ul className="stages">
    <Link to="/reservation"><li className="complete"><span>1. 객실 선택</span></li></Link>
    <li className="check"><span>2. 추가 옵션 선택</span></li>
    <li className="complete"><span>3. 정보 입력/예약</span></li>
  </ul>
  

  




      <div className="roomArea" style={{ height:'100%', border:'2px solid #ddd', margin:'0 auto',
    marginTop:'100px',position:'relative'}}>
       <div className="imgGallery">
       
       {/*<ImageGallery items={imagePaths} showBullets={true} showThumbnails={false} showPlayButton={false}/>*/}
       <Swiper
        slidesPerView={1}
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {imagePaths.map(path => (
        <SwiperSlide><img src={path} alt=""/></SwiperSlide>
        
        ))}
        

      </Swiper>
      
       </div>

       <div style={{width:"80%",margin:"50px auto", background:"#f5f5f5",fontSize:"20px", padding:"40px"}}>
         <h3>객실 Amenities</h3>
       
       <table className="Amenities">
         <tr>
           <th style={{width:"90px"}}>침실</th>
           <td>{bedroom}</td>
         </tr>

         <tr>
           <th style={{width:"90px"}}>거실</th>
           <td>{living_room}</td>
         </tr>

         <tr>
           <th style={{width:"90px"}}>욕실</th>
           <td>{bathroom}</td>
         </tr>

         <tr>
           <th style={{width:"90px"}}>부엌</th>
           <td>{private_bar}</td>
         </tr>

         

       </table>

       </div>
       <h3 style={{marginLeft:"10%"}}>{room_name} / 예약 옵션</h3>
      <div className="reserve_wrap">

      <div className="addOptions">

        <div className="sel_cnt">
        <label className="sel_container"><p>인원 추가 / 1인당 {addPrice}원</p>
          <input type="checkbox" checked={checked}
        onChange={handleChange}/>
          <span className="checkmark"></span>
        </label>
          
            <div className="count_wrap">
              <button onClick={decrementCount} disabled = {isDisabled}>-</button>
              <span>{count}</span>
              <button onClick={incrementCount} disabled = {isDisabled}>+</button>
            </div>
        </div>
        
        <div className="sel_cnt">
        <label className="sel_container"><p>바베큐</p>
          <input type="checkbox" checked={checked2}
        onChange={handleChange2}/>
          <span className="checkmark"></span>
        </label>
          
          
        </div>

            
        </div>

       
        <div className="priceSum">
              <div className="reserveInfo">
                <div className="col1 col">
                  객실1 <br></br>
                  성인 {numAdult} 명 <br></br>
                  {sDate} ~ {eDate} <br></br>
                </div>
                
                <div className="col2 col">
                <div style={{float:"right"}}><p style={{fontSize:"12px", float:"right"}}>{price_desc}</p><br></br><p style={{float:"right"}}>₩{price.toLocaleString()}</p></div>
                </div>
              </div>

              { checked && (
              <div className="reserveInfo addedOptions" style={{borderTop:'1px solid #000'}}>
              
                <div className="col1 col">
                  인원추가
                </div>
                
                <div className="col2 col">
                  (x{count}명)
                  
                </div>
                <div className="col2 col">
                 <p style={{float:"right"}}>₩{ addedPerson.toLocaleString()}</p>
                </div>
                
              </div>
              )}

            { checked2 && (
              <div className="reserveInfo addedOptions" style={{borderTop:'1px solid #000'}}>
              
                <div className="col1 col">
                  바베큐
                </div>
                
                <div className="col2 col">
                  
                  
                </div>
                <div className="col2 col">
                 <p style={{float:"right"}}>₩30,000</p>
                </div>
                
              </div>
              )}

              <div className="reserveInfo" style={{borderTop:'1px solid #000'}}>
                <div className="col2 col">
                  합계
                </div>
                <div className="col2 col">
                  
                </div>
                <div className="col2 col">
                <p style={{float:"right"}}>
                  ₩{(addedPerson+price+barbecue).toLocaleString()}
                  
                </p>
                </div>
              </div>

        
       

            
        </div>
        
        
      </div>
      <div style={{width:"80%", margin:"50px auto", background:"#f5f5f5", textAlign:"center", padding:"50px"}}>
        
        <h3>요금 정책</h3>
      
      상기 요금에 세금 10%, 봉사료 10% 가 가산됩니다. (합계 21%) <br></br>
      해당 상품에는 조식 2인이 포함되어 있습니다.
      </div>
      <div style={{width:"80%", margin:"50px auto 0px auto", background:"#f5f5f5", textAlign:"center", padding:"50px"}}>
        
        <h3>예약 취소 규정</h3>
      
        상기 홈페이지 요금은 정상가에서 할인된 금액으로 중복 할인은 불가능합니다.<br></br>
예약 취소 및 변경은 체크인 2일 전 18:00(한국시간)까지 가능합니다. 예약하신 일자에 체크인 되지 않거나 또는 위의 지정 시간 이후에 예약을 취소했을 경우에는 <br></br>
예약 사항에 대해 노-쇼 (No Show) 처리 되며, 이에 따른 위약금으로 예약 첫날에 해당하는 1박 객실요금이 청구되오니 유의하시기 바랍니다.
      </div>
      
        <div className="div_button">
        <Link to="/reservation/input" state={ data2 }><button className="button" type="button">예약하기</button></Link>
        </div>
      </div>
    </div>

    

      
  );
};
  
export default Choice;