import jejuisland from './img/hoteloverview.jpg'
import './css/overview.css'


import hotel1 from './img/hotel1.jpg'
import hotel2 from './img/hotel2.jpg'
import hotel3 from './img/hotel3.jpg'
import hotel4 from './img/hotel4.jpg'
import TabNavItem from "../components/TabNavItem";
import TabContent from "../components/TabContent";
import '../components/Tabs2.css';
import React, { useState, useEffect, useRef } from 'react';
import dompurify from "dompurify";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const { kakao } = window;


const Overview = () => {

  const { t, i18n } = useTranslation();
  const sanitizer = dompurify.sanitize;
  const language = useRef(null);
  
  useEffect(()=>{
    language.current = sessionStorage.getItem('language');
  if (language===null){
    language.current = cookies.get('language');
  }
  i18next.changeLanguage(language.current);
  },[]);

  const [activeTab, setActiveTab] = useState("tab1");
  useEffect(()=>{
    var container = document.getElementById('map');
    var options = {
      center: new kakao.maps.LatLng(33.49696129519766, 126.44865210992928),
      level: 3
    };

    var map = new kakao.maps.Map(container, options);
    var markerPosition  = new kakao.maps.LatLng(33.49696129519766, 126.44865210992928);
    var marker = new kakao.maps.Marker({
      position: markerPosition
  });
  marker.setMap(map);

    }, [])

    
    return (
        <div className="wrap">
        <style>
        {`
          .overviewImg{
            width:85%;
            margin:50px auto;
            min-width:900px;
            max-width:1920px;
            height:810px;
          }
          .summary{
            text-align:center;
            color:#555;
            margin:0 auto;
            padding-bottom:0;
            
          }
          .summary table {
            margin-top:50px; 
            width:60%;
            min-width:700px;
            margin:50px auto 0 auto;
          }
          .Tabs2{
            width:60%;
            margin-left:auto;
            margin-right:auto;
            
          }
          .location{
            width:60%;
            margin:50px auto 0 auto;
            
          }
          .sub-list-wrap {
            width:60%;
            margin:50px auto;
          }
     
          @media (max-width: 960px) {
            .overviewImg{
                width:95%;
                height:300px;
                min-width:unset;
            }
            .summary{
              width:100%;
              padding:0 30px;
              margin-top:30px;
            }
            .summary table {
              margin-top:30px; 
              width:95%;
              min-width:unset;
            }
            .sub-list-wrap {
              width:95%;
            }
            .Tabs2{
              width:95%;
            }
            h3{
              margin-top:30px;
            }
          }


          
          #map{
            height:700px;
          }
          
          .summary{
            margin-top:0;
            min-width:800px;
            width:80%;
          }
          .location p{
            font-size:50px;
            line-height:600px;
          }
          .Tabs2{
            margin-bottom:0;
          }
          .Tabs2 ul.nav{
            min-width:unset;
          }
          .Tabs2 ul.nav li{
            width:50%;
          }
          @media (max-width: 1600px){
          .summary {
              width: 40% !important;
              margin: auto;
       
          }
        }
          @media (max-width: 960px) {
            .summary {
              width: 100% !important;
              margin: auto;
       
            }
            .summary:nth-of-type(2){
              margin-top:50px;
            }
            .location{
                width:95%;
                min-width:unset;
            }
            #map{
              height:300px;
            }
            .Tabs2{
              background:#fcfcfc;
              margin-top:30px;
             
            }
            .location p{
                line-height:300px;
            }
            .summary{
              min-width:unset;
              width:100%;
              padding:0 10px;
            }
            .sub-list-wrap {
              width:100%;
              margin:30px auto 0 auto;
            }
          
            
          }

          .summary .greetings {
            line-height:1.5;
            margin-top:50px;
            margin-bottom:50px;
          }
        `}
        </style>
        <h2 className = "headerTxt">ABOUT MH HOTEL</h2>
        <div className="summary">
        <h3 className="greetings_heading">Welcome to [ IHO MH HOTEL ] !</h3>
        <div className="greetings"classname={i18n.language === 'jp' ?  'japanese greetings': 'greetings'}><span dangerouslySetInnerHTML={{ __html: sanitizer(t("greetings")) }}></span></div>
        <h3>{i18next.t('overviewTitle')}</h3>
        <table style={{background:"#f9f9f9"}}>
						<tbody>
					
							<tr>
								<th>{i18next.t('overview1')}</th>
								<td dangerouslySetInnerHTML={{ __html: sanitizer(t("overview2")) }}></td>
							</tr>
              <tr>
								<th className="roomsInfo">{i18next.t('roomsInfo')}</th>
								<td dangerouslySetInnerHTML={{ __html: sanitizer(t("roomsInfo2")) }}></td>
							</tr>
              <tr>
								<th>{i18next.t('facilityInfo')}</th>
								<td dangerouslySetInnerHTML={{ __html: sanitizer(t("facilityInfo2")) }}></td>
							</tr>
							<tr>
								<th>{i18next.t('overview3')}</th>
								<td>064-743-6444</td>
							</tr>
							<tr>
								<th>FAX</th>
								<td>064-743-6445</td>
							</tr>
							<tr>
								<th>E-mail</th>
								<td className="email"><a href="mailto:ihomhhotel@naver.com">ihomhhotel@naver.com</a></td>
							</tr>
						</tbody>
					</table>
        </div>
   

        <div className="picList" style={{width:"100%", margin:"50px auto 0px auto"}}>

        <div class="listWrap">
          <div class="list aos-init aos-animate" data-aos="fade-up">
            <div class="thm_li">
              <img src={hotel3} alt="호텔이미지"/>
            </div>
          </div>
          <div class="list aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div class="thm_li">
              <img src={hotel4} alt="호텔이미지"/>
            </div>    
          </div>

          
        </div>

        <div class="listWrap">
          <div class="list aos-init aos-animate" data-aos="fade-up">
            <div class="thm_li">
              <img src={hotel1} alt="호텔이미지"/>
            </div>
          </div>
          <div class="list aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <div class="thm_li">
              <img src={hotel2} alt="호텔이미지"/>
            </div>    
          </div>

          
        </div>

        </div>
        
        <h3 style={{textAlign:"center", marginTop:"50px"}}>{i18next.t('menu1-2')}</h3>
        <div className="location">
          
        <div id="map"></div>
        
        <div style={{height:"50px", borderTop:"1px solid #5c5c5c", marginTop:"0"}}>
          <button className="kakaoLogo">Kakao maps</button>
          <a href="http://kko.to/zFjy_LlUR" target="_blank" rel="noopener noreferrer">
            <button className="findWay">{i18next.t('location1')}</button>
          </a>
        </div>
        </div>
        
        <div className="hotelContacts" style={{textAlign:'left'}}>

              <div className = "sub-list-wrap">
                <div className="sub-info-list">
                    <div className="sub-info-bx">
                        <div className="sub-info-tit">
                            <h4><img src="http://www.changwonhotel.co.kr/images/icon/ic_facility.png" alt=""/> {i18next.t('location2')} </h4>
                        </div>
                        <div className="sub-info">
                        <p dangerouslySetInnerHTML={{ __html: sanitizer(t("location3")) }}></p>
                            <p>{i18next.t('location4')}</p>
                            <p>{i18next.t('location5')}</p>
                        </div>
                    </div>
                
                </div>
                </div>

        <div className="Tabs2">
            <ul className="nav">
                <TabNavItem title={i18next.t('location6')} id="tab1" activeTab={activeTab} setActiveTab={setActiveTab}/>
                <TabNavItem title={i18next.t('location7')} id="tab3" activeTab={activeTab} setActiveTab={setActiveTab}/>
            </ul>
 
            <div className="outlet">
                <TabContent id="tab1" activeTab={activeTab}>

                <div className="cm-tab-con" id="tabContent01" >
								
								<div className="location-bx">
									<p>
                  {i18next.t('location8')}
                  </p>
									<div className="location-sub-bx">
										<p>- {i18next.t('location9')}</p>
									</div>
								</div>

					
							  </div>

                </TabContent>
                <TabContent id="tab2" activeTab={activeTab}>

                <div className="cm-tab-con" id="tabContent02">
								
								<div className="location-bx">
									<p>
                  {i18next.t('location10')}
                  </p>
									<div className="location-sub-bx">
										<p>- 소요시간 : 약 12분</p>
									</div>
								</div>

							</div>

                </TabContent>

                <TabContent id="tab3" activeTab={activeTab}>

                <div className="cm-tab-con" id="tabContent03" style={{display: 'block'}}>
								<div className="location-bx">
									<p>{i18next.t('location10')}
                  </p>
									<div className="location-sub-bx">
										<p>- {i18next.t('location11')}</p>
									</div>
								</div>

								


							</div>

                </TabContent>
            </div>
        </div>


        </div>

        

        </div>

    

      
  );
};
  
export default Overview;
