import TabNavItem from "../components/TabNavItem";
import TabContent from "../components/TabContent";
import '../components/Tabs.css';
import './css/facility.css';

import barbecue from './img/barbecue.jpg'
import barbecue2 from './img/barbecue2.jpg'
import barbecue3 from './img/barbecue3.jpg'

import banquet from './img/banquet.jpg'
import banquet2 from './img/banquet2.jpg'
import banquet3 from './img/banquet3.jpg'
import { useParams } from "react-router-dom"
import React, { useState, useEffect, useRef } from 'react';
import dompurify from "dompurify";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Facilities = () => {
    const { index } = useParams();
    console.log(index);
    const [korean, setKor] = useState("false");
	const { t, i18n } = useTranslation();
	const sanitizer = dompurify.sanitize;
	const language = useRef(null);
	
	useEffect(()=>{
		language.current = sessionStorage.getItem('language');
	if (language===null){
		language.current = cookies.get('language');
	}
	i18next.changeLanguage(language.current);
	if (language.current ==="kr-KR"){
		setKor(true);
	  }else {
		setKor(false);
	  }
	},[]);
    const [activeTab, setActiveTab] = useState(index);
    if (index === null){
        setActiveTab("barbecue");
    }

    return (

        
        <div className="wrap">
        <style>
        {`
        .Tabs ul.nav li  {
            width: 100%;
            list-style: none;
            text-align: center;
            cursor: pointer;
            transition: all 0.7s;
            
          }
          .Tabs ul.nav a  {
            width: 50%;
            list-style: none;
            text-decoration:none;
            text-align: center;
            cursor: pointer;
            color:black;
            transition: all 0.7s;
          }
        `}
      </style>
      <h2 className = "headerTxt">FACILITIES</h2>


        <div className="Tabs">
            <ul className="nav">
            {/*<a href="/facilities/barbecue"><TabNavItem title={i18next.t('barbecue')} id="barbecue" activeTab={activeTab} setActiveTab={setActiveTab}/></a>*/}
            <a href="/facilities/banquet" style={{width:"100%"}}><TabNavItem title={i18next.t('banquet')} id="banquet" activeTab={activeTab} setActiveTab={setActiveTab}/></a>
            </ul>
 
            <div className="outlet">
                <TabContent id="barbecue" activeTab={activeTab}>
                <div className="facility">
                    <img src={barbecue} alt="barbecue"></img>
                
                </div>
                <div className="fac_imgs">
                    <div className="fac_container">
                        <img src={barbecue2} alt="barbecue"></img>
                        <div class="middle">
                            
                        </div>
                        <div class="fac_text">
                            <div>
                                064-743-6444
                            </div>
                        </div>
                    </div>
                    <div className="fac_container">
                        <img src={barbecue3} alt="barbecue"></img>
                        <div class="middle">
                            
                        </div>
                        <div class="fac_text">
                            <div>
                                064-743-6444
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className = "sub-list-wrap">
                <div className="sub-info-list">
                    <div className="sub-info-bx">
                        <div className="sub-info-tit">
                            <h4><img src="http://www.changwonhotel.co.kr/images/icon/ic_facility.png" alt=""/> {i18next.t('barbecue')}</h4>
                        </div>
                        <div className="sub-info">
                            <p>{i18next.t('barbecue1')}</p>
                            <p>{i18next.t('barbecue2')}</p>
                            <p>{i18next.t('barbecue3')}</p>
                            <p>{i18next.t('barbecue4')}</p>
                        </div>
                    </div>
                    <div className="sub-bx-list clearfix">
                        <dl>
                            <dt><p>{i18next.t('hours')}</p></dt>
                            <dd>12:00~22:00</dd>
                        </dl>
                        <dl>
                            <dt><p>{i18next.t('place')}</p></dt>
                            <dd>1st Floor</dd>
                        </dl>
                        <dl>
                            <dt><p>{i18next.t('contact')}</p></dt>
                            <dd> 064-743-6444</dd>
                        </dl>
                    </div>
                </div>
                </div>
                </TabContent>
                <TabContent id="banquet" activeTab={activeTab}>
                <div className="facility">
                    <img src={banquet} alt="barbecue"></img>
                
                </div>
                <div className="fac_imgs">
                    <div><img src={banquet2} alt="barbecue"></img></div>
                    <div><img src={banquet3} alt="barbecue"></img></div>
                </div>
                    <div className = "sub-list-wrap">
                    <div className="sub-info-list">
						<div className="sub-info-bx">
							<div className="sub-info-tit">
								<h4><img src="http://www.changwonhotel.co.kr/images/icon/ic_facility.png" alt=""/> {i18next.t('banquet')}</h4>
							</div>
							<div className="sub-info">
                            <p>Size : 4.35m x 3.05m / Height 2.4m</p>
                            <p>{i18next.t('banquet1')} - 모델명 : HG75NT690UFXKR</p>

                            </div>

                            <div className="sub-bx-list clearfix">
                                <dl>
                                    <dt><p>{i18next.t('place')}</p></dt>
                                    <dd>{i18next.t('banquet2')}</dd>
                                </dl>
                                <dl>
                                    <dt><p>{i18next.t('fee')}</p></dt>
                                    <dd dangerouslySetInnerHTML={{ __html: sanitizer(t("banquet3")) }}></dd>
                                </dl>
                                <dl>
                                    <dt><p>{i18next.t('occupancy2')}</p></dt>
                                    <dd> {i18next.t('banquet4')}</dd>
                                </dl>
                            </div>
						</div>
						
					</div>
                    </div>
                </TabContent>
            
            </div>
        </div>

        </div>

    

      
  );
};
  
export default Facilities;