import './css/home.css'
import './css/choice.css'


import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation,useNavigate } from "react-router-dom"
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const Check = () => {

  const location = useLocation();
    const data = location.state;

    console.log(data[0].booking_id);

    let [addPrice, setAddPrice] =useState(1);
    let [barbecuePrice, setBarbecue] =useState(1);
    useEffect(()=>{

    fetch("http://localhost:5000/priceAdd", {
      method : "post", // 통신방법
      headers : {
        "content-type" : "application/json",
        'Accept': 'application/json'
      },
      body : JSON.stringify(),
    })
    .then((res)=>res.json())
    .then((json)=>{
      console.log(json[0].RESULT);
      setAddPrice(json[0][0].RESULT);
      setBarbecue(json[1][0].Barbecue);
    });



    
    });    
  <style>
        {`
          .sumTable {
            font-size:16px;

         
          }
         
          
          @media (max-width: 2260px) {
           
          }
          @media (max-width: 860px) {
          
            .roomArea table td{
              font-size:12px;
              
  
           
            }
          }
        `}
      </style>

  

  return (
    <div className="wrap" style={{flex:'1', marginBottom:'500px'}}>

      
   

  
<div style={{height:'70px',  width:'60%',margin:'180px auto 20px auto', textAlign:'center',
fontSize:'30px', lineHeight:'62px'}}>
        예약 내역
    </div>


      <div className="roomArea" style={{ height:'100%', border:'2px solid #ddd', margin:'0 auto',
    marginTop:'100px',position:'relative'}}>
       
       <div style={{height:'auto', display:'flex',marginTop:'50px'  }}>
        <div style={{ border:"2px solid #000", width:'80%', marginLeft:'10%', height:'auto', padding:'20px', 
            marginTop:'0px',display:'block',position:'relative' }}>
              <table className="confirmTable">

                <tr>
                  <th>
                    예약번호:
                  </th>
                  <td>{data[0].booking_id}</td>
                </tr>

                <tr colSpan="3">
                  <th>
                    객실:
                  </th>
                  <td>오션뷰 프리미엄룸
                  <Link to="/rooms"><button style={{ borderRadius:'17px', marginLeft:'10px',backgroundColor:"#998465",
                  border:"none",padding:"5px 10px",color:"white", fontSize:"15px" }}>객실 상세 안내</button></Link>
                  </td>
                </tr>

                <tr>
                  <th>
                    성명:
                  </th>
                  <td>홍길동</td>
                </tr>
                

                <tr>
                  <th>
                    이메일:
                  </th>
                  <td>{data[0].guest_email}</td>

                  <th>
                    연락처:
                  </th>
                  <td>010-2112-8935</td>
                </tr>

                <tr>
                  <th>
                    체크인:
                  </th>
                  <td>{data[0].date_start} 15:00</td>

                  <th>
                    체크아웃:
                  </th>
                  <td>{data[0].date_end} 12:00</td>
                </tr>


                <tr>
                  <th>
                    추가 요청사항:
                  </th>
                  <td></td>
                </tr>

                

                
              </table>
        </div>

      </div>
        
      <div style={{ border:"2px solid #000", width:'80%', marginLeft:'10%', height:'auto', padding:'20px', 
            marginTop:'50px',display:'block',position:'relative' }}>
              <div className="reserveInfo">
                <div className="col1 col">
                  객실1 <br></br>
                  {data[0].num_persons} <br></br>
                  {data[0].date_start} ~ {data[0].date_end}
                </div>
                <div className="col2 col">

                프리미엄룸 (오션뷰)
                <br></br>
             
                  
                </div>
                <div className="col3 col price">
                ₩250,000
                </div>
              </div>


              
              { data[0].add_persons !==0 && (

            <div className="reserveInfo" style={{borderTop:'1px solid #000'}}>
              <div className="reserveInfo addedOptions" style={{borderTop:'1px solid #000'}}>
              
                <div className="col1 col">
                  인원추가
                </div>
                
                <div className="col2 col">
                  (x{data[0].add_persons}명)
                  
                </div>
                <div className="col2 col">
                 <p style={{float:"right"}}>₩{ (data[0].add_persons *addPrice).toLocaleString()}</p>
                </div>
                
              </div>
              </div>
              )}
            
              

                
              { data[0].barbecue !==0  && (

              <div className="reserveInfo addedOptions" style={{borderTop:'1px solid #000'}}>
              
                <div className="col1 col">
                  바베큐
                </div>
                
                <div className="col2 col">
                  
                </div>
                <div className="col2 col">
                 <p style={{float:"right"}}>₩{ barbecuePrice.toLocaleString()}</p>
                </div>
                
              </div>
              )}

              <div className="reserveInfo" style={{borderTop:'1px solid #000'}}>
                <div className="col3 col">
                  합계
                </div>
                <div className="col2 col">
                  
                </div>
                <div className="col3 col price">
                {data[0].total_price}
                </div>
              </div>

        
       

            
        </div>


            
        
        
      

      
      <button style={{ backgroundColor:"#fff",  width:"150px",margin:"20px auto"
      ,padding:"10px 20px", marginTop:'50px', fontSize:'16px', display:'block'}} onClick={() => { 
        let proceed = window.confirm("예약을 취소하시겠습니까?");
        if (proceed) {
          /*부분취소*/
          const today = new Date();

          const sdate = new Date(data[0].date_start);

          const daydiff = Math.floor((sdate - today) / (1000*60*60*24));

          const booking_amount = data[0].total_price;

          var cancel_request_amount = 0;

          if (daydiff > 5){
            cancel_request_amount = booking_amount * 1;
          }else if (daydiff === 5){
            cancel_request_amount = booking_amount * 0.8;
          }else if (daydiff === 4){
            cancel_request_amount = booking_amount * 0.6;
          }else if (daydiff === 3){
            cancel_request_amount = booking_amount * 0.4;
          }else if (daydiff === 2){
            cancel_request_amount = booking_amount * 0.2;
          }else{
            return alert("예약 1일전, 당일 취소는 불가합니다.");
          }

          const bookingid = {
            booking_id: data[0].booking_id,
          }
          fetch("http://localhost:5000/getuid", {
            method : "post", // 통신방법
            headers : {
              "content-type" : "application/json",
              'Accept': 'application/json'
            },
            body : JSON.stringify(bookingid),
          })
          .then((res)=>res.json())
          .then((json)=>{
            console.log(JSON.stringify(json));
            axios({
              url: "http://localhost:5000/cancel", // 예: http://www.myservice.com/payments/cancel
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              data: { 
                booking_id : data[0].booking_id,
                imp_uid: json[0].RESULT, // 주문번호 - 필요없는것같은데??? imp_uid만 쓰잖아.nod
                cancel_request_amount: cancel_request_amount, // 환불금액
                reason: "테스트 결제 환불" // 환불사유
              }
            }).then(response => { // 환불 성공시 로직 
              alert("환불 성공");

            }).catch(error => { // 환불 실패시 로직 
              alert("환불 실패 : ", error);
            });
            
          })



          
        } else {
          //don't proceed
        }
        }}>예약취소</button>
      </div>
    </div>



      
  );
};
  
export default Check;