import './css/board.css';
import { NavLink, Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
//import Pagination from "./Pagination";


import Pagination from "./Pagination";

import { setInlineStyles } from 'rsuite/esm/List/helper/utils';
import qs from 'qs';
import dompurify from "dompurify";
import { useTranslation } from 'react-i18next';
import i18next from "../config/lang/i18next";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Board = () => {

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    const [isEmpty, setEmpty] = useState(false);
    const [isPage, setIsPage] = useState(false);

    const offset = (page - 1) * limit;
    const [data, setData] = useState([]);
    const [filterData, setfilterData] = useState([]);

    const [search, setSearch] = useState(null);
    const [options, setOption] = useState("subject");

    const [korean, setKor] = useState("false");
	const { t, i18n } = useTranslation();
	const sanitizer = dompurify.sanitize;
	const language = useRef(null);
	
	useEffect(()=>{
		language.current = sessionStorage.getItem('language');
	if (language===null){
		language.current = cookies.get('language');
	}
	i18next.changeLanguage(language.current);
	if (language.current ==="kr-KR"){
		setKor(true);
	  }else {
		setKor(false);
	  }
	},[]);

    let isloggedIn = sessionStorage.getItem('isloggedIn');
    if (isloggedIn===null){
      isloggedIn = cookies.get('isloggedIn');
    }
    const onChangeSearch = (e) => {
        e.preventDefault();
        setSearch(e.target.value);

    }

    const selectHandle = (e) => {

        e.preventDefault();
        setOption(e.target.value);

    }
    /*const searchPosts = ({ page, option, content }) => {
        const queryString = qs.stringify({
          page,
          option,
          content
        });
        return axios.get(`/board/search?${queryString}`);
    }*/
    const onSearch = (e) => {
        e.preventDefault();
        
        /*
        axios.get('http://222.101.0.9:5000/boardlist').then((response)=> {
            setData(response.data);
            
        })
        alert(options);
        searchPosts({ page: page, option: options, content: search });
        setSearch('');*/
        console.log(search);
        console.log(data);
        if (options ==="subject"){
            const filtered = data.filter((itemList) => {
                console.log(itemList);
                return itemList.subject.toUpperCase().includes(search.toUpperCase());
            });
            setfilterData(filtered);
            if (filtered.length === 0){
                setEmpty(true);
                
            }else{
                setEmpty(false);
            };
        }else if (options === "content"){
            const filtered = data.filter((itemList) => {
                console.log(itemList);
                return itemList.content.toUpperCase().includes(search.toUpperCase());
            });
            setfilterData(filtered);
            if (filtered.length === 0){
                setEmpty(true);
                
            }else{
                setEmpty(false);
            };
        }
    }
    

    function GetData() {
        
        
        useEffect(() => {
          axios.get('http://222.101.0.9:5000/api/boardlist').then((response)=> {
            setData(response.data);
            setfilterData(response.data);
            if (response.data.length === 0){
                setEmpty(true);
                console.log(response.data);
                
            }else{
                console.log("haha");
                setEmpty(false);
                if (response.data.length <= 10){
                    
                    setIsPage(false);
                }else{
                    
                    setIsPage(true);
                }
            }
            
          })
        }, []);
      
        const item = (Object.values(data)).map((item) => (
        
          <tr>
          <td className="m_dp_n">{item.board_id}</td>
          <td className="m_dp_n"></td>
          <td className="ta_l">
          <a className="bl_link" href="/board/view">{item.subject}</a>
          </td>
          <td>{item.board_date}</td>
        </tr>
        ));
      
        return item;
      }
      
    const item = GetData();

    
    return (
        
       
        <div className="wrapper">
         <style>
        {`
        @media (max-width: 860px) {
         .headerNotice{
            margin:50px 0 0;
         }
        .i-bat2{
            display:none;
        }
        }
        `}
        </style>
        
        <div className="board_wrapper">


        <form name="form1" id="form1" method="post">
        <input name="pageIndex" id="pageIndex" type="hidden" value=""/>

        <input type="hidden" id="ncv_file_seq" name="ncv_file_seq" value=""/>
        <input type="hidden" id="file_path" name="file_path" value=""/>
        <input type="hidden" id="file_name" name="file_name" value=""/>
        <input type="hidden" id="brdId" name="brdId" value=""/>
        <input type="hidden" id="brdGubun" name="brdGubun" value=""/>
        <input type="hidden" id="dataGubun" name="dataGubun" value=""/>
        <input type="hidden" id="ncvContSeq" name="ncvContSeq"/>

	    <div className="container">
		<div>
			<div id="content" className="content">
				<div>
		            <div className="sub_top">
		                <div className="st_navigation">
		                    <ul>
		                    </ul>
		                </div>
		        
		            </div>

                    <h2 className = "headerNotice">{i18next.t('notice')}</h2>


        
        
	                <div className="board_top">
                        
	                            <fieldset>
                                {/*<label>
                                    페이지 당 게시물 수:&nbsp;
                                    <select
                                    type="number"
                                    value={limit}
                                    onChange={({ target: { value } }) => setLimit(Number(value))}
                                    >
                                    <option value="10">10</option>
                                    <option value="12">12</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    </select>
                                </label>*/}
	                                <div className="bt_srch">
	                                    <div className="bts_slct">
	                                        <select id="u_shcate" name="search_item" className="select" title="검색항목 선택" value={options} onChange={selectHandle}>
	                                            <option value="subject">{i18next.t('subject')} </option>
	                                            <option value="content">{i18next.t('content')} </option>
	                                       
	                                        </select>
	                                    </div>
	                                    <div className="bt_input">
	                                        <input type="text" id="search_content" name="search_content" value={search} placeholder={i18next.t('enter')} onChange={onChangeSearch}/>
                                            
                                        </div>
	                                    
	                                        <button type="submit" className="btn btn_sm btn_gray search_btn" value="검색" onClick={onSearch}>{i18next.t('search')}</button>
	                                </div>
	                            </fieldset>
                                
                            
                        
	                </div>
        
					<div className="board_list">
                        <table>
                            <colgroup>
                                <col className="m_dp_n table_no"/>
                                <col className=""/>
                                <col className="ta_l"/>
                                <col className="m_dp_n table_date"/>
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="m_dp_n table_no" scope="col">NO.</th>
                                    <th scope="col"></th>
                                    <th className="ta_l" scope="col">{i18next.t('subject')}</th>
                                    <th className="m_dp_n table_date" scope="col">{i18next.t('dates')}</th>
                                </tr>
                            </thead>
                            <tbody>
                            
                                {isEmpty && <tr style={{textAlign:"center"}}><td colSpan={4}>게시물이 없습니다.</td></tr>}
                                {filterData.slice(offset, offset + limit).map(({ board_id, subject, board_date }) => (
                                

                                <tr key={board_id}>
                                    <td className="m_dp_n">{board_id}</td>
                                    <td className=""><span class="i-bat2 ty1 mr20">NOTICE</span></td>
                                    <td className="ta_l" style={{textAlign:"left"}}>
                                    <Link to={`/postView/${board_id}`}>{ subject }</Link>
                               			
                                    </td>
                                    <td>{board_date.substring(5,10)}</td>
                                </tr>
                                ))}
                            
                           
                            
                            
                            </tbody>
                        </table>
                        
                        {isloggedIn && <Link to="/write">
                            <div style={{padding:"10px 30px", marginTop:"20px", backgroundColor:"#555", color:"white",textAlign:"center", display:"inline-block", float:"right", cursor:"pointer"}}>글쓰기</div>
                        </Link> }
                    </div>
					{isPage && <div className="paging">
                    <Pagination
                        postsPerPage={limit}
                        totalPosts={filterData.length}
                        page={page}
                        paginate={setPage}
                    ></Pagination>
                    {/*<Pagination
                    total={data.length}
                    limit={limit}
                    page={page}
                    setPage={setPage}
                    />*/}
					
                    </div>
                    }
	                
	                
				</div>
			</div>
		</div>
	</div>
</form>


        </div>

    </div>
    );
  };
  
  export default Board;