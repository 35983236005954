export const menuItems2 = [
    {
      title: "About",
      link : "/overview",
      submenu: [
        {
          title: "overview",
          link : "/overview",
          big_title : "Overview",
          text : "'IHO MH HOTEL' Jeju Island, a fantasy island with beautiful days and nights, has built a new landmark along Iho Tewoo Beach, which is well known as a good place to take pictures.",
          top_link : "overview",
          image : "/img/호텔소개.jpg"
        },
        /*{
            title: "Location",
            link : "/location",
            big_title : "Overview",
            text : "'IHO MH HOTEL' Jeju Island, a fantasy island with beautiful days and nights, has built a new landmark along Iho Tewoo Beach, which is well known as a good place to take pictures.",
            top_link : "overview",
            image : "/img/호텔소개.jpg"
        },*/
      ],
    },
    {
      title: "Rooms",
      link : "/rooms/premium",
      submenu: [
        {
          title: "Premium Room",
          link : "/rooms/premium",
          big_title : "Rooms",
          text : "The rooms of IHO MH Hotel offer a panoramic view of the beautiful Iho Tewoo Beach and the wonderful view of Hallasan Mountain, and the luxury of the room through full remodeling, so you can feel the 'best vacation that anyone wants.'",
          top_link : "/rooms/premium",
          image : "/img/객실.jpg"
        
        },
        {
          title: "Family Room",
          link : "/rooms/family",
          big_title : "Rooms",
          text : "The rooms of IHO MH Hotel offer a panoramic view of the beautiful Iho Tewoo Beach and the wonderful view of Hallasan Mountain, and the luxury of the room through full remodeling, so you can feel the 'best vacation that anyone wants.'",
          top_link : "/rooms/premium",
          image : "/img/객실.jpg"
       
        },
      ],
    },
    /*{
      title: "객실예약",
      link : "/reservation",
      submenu: [
        {
          title: "객실예약",
          link : "/reservation",
        
        },
        {
          title: "예약조회",
          link : "/login",
       
        },
      ],
    },*/
    {
        title: "Attractions",
        link : "/attractions/beach",
        submenu: [
          {
            title: "Iho Tewoo beach",
            link : "/attractions/beach",
            big_title : "Tours",
            text : "Jeju Island tour with a variety of attractions and food. Enjoy the tourist attractions and its surroundings, which Jeju boasts.",
            top_link : "/attractions/beach",
            image : "/img/주변관광지.jpg"

          },
          {
            title: "Dodubong peak",
            link : "/attractions/dodubong",
            big_title : "Tours",
            text : "Jeju Island tour with a variety of attractions and food. Enjoy the tourist attractions and its surroundings, which Jeju boasts.",
            top_link : "/attractions/beach",
            image : "/img/주변관광지.jpg"

          },
          {
            title: "Yongduam rock",
            link : "/attractions/rock",
            big_title : "Tours",
            text : "Jeju Island tour with a variety of attractions and food. Enjoy the tourist attractions and its surroundings, which Jeju boasts.",
            top_link : "/attractions/beach",
            image : "/img/주변관광지.jpg"

          },
          {
            title: "Yongyeon valley",
            link : "/attractions/valley",
            big_title : "Tours",
            text : "Jeju Island tour with a variety of attractions and food. Enjoy the tourist attractions and its surroundings, which Jeju boasts.",
            top_link : "/attractions/beach",
            image : "/img/주변관광지.jpg"

          },
          /*{
            title: "한라수목원",
            link : "/attractions/arboretum",

          },*/
        ],
      },
      {
        title: "Facilities",
        link : "/facilities/banquet",
        submenu: [
          {
            title: "MH Lounge (open in August)",
            //link : "/facilities/barbecue",
            big_title : "부대시설",
            text : "open in August",
            //top_link : "/facilities/barbecue",
            //image : "/img/바베큐.jpg"


          },
          {
            title: "Banquet room",
            link : "/facilities/banquet",
            big_title : "Facilities",
            text : "Explore small banquet halls for successful events such as family gatherings and small gatherings.",
            top_link : "/facilities/barbecue",
            image : "/img/banquet3.jpg"

          },
        ],
      },
      {
        title: "Notice",
        link : "/board",
        submenu: [
          {
            title: "notice",
          }
        ],
      }
  ];